import { useMemo } from "react";

import {
  ApolloError,
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  WatchQueryFetchPolicy,
  useLazyQuery,
} from "@apollo/client";
import parseData from "../../components/utils/parseData";

/**
 * This hook is used to easily fetch data from any query.
 * @param documentNode The query document to fetch, e.g: GET_CURRENT_HEATS, from query.js.
 * @param variables An object cointaining the variables that may or may not be nesessary for the query.
 * @param refreshOn Optional - A key of a variable that sets what variable should update the query when changed, ( used in useEffect dependency array ).
 * @param fetchPolicy Optional - A fetch policy that will be added to the query.
 * @param onCompleted Optional - A function handler for when the query is completed.
 * @param onError Optional - A function handler for errors.
 * @returns An object containing data, error and loading.
 */

export default function useFetch<
  T = any,
  TVariables extends OperationVariables = OperationVariables
>(
  documentNode: DocumentNode | TypedDocumentNode<T, TVariables>,
  variables: TVariables,
  refreshOn?: keyof TVariables,
  fetchPolicy?: WatchQueryFetchPolicy,
  skip?: boolean,
  deArray?: boolean
): {
  loading: boolean;
  error: ApolloError | undefined;
  data: T | undefined;
  refresh: (variables?: TVariables) => void;
} {
  const [refetch, { loading, error, data }] = useLazyQuery<
    Record<string, any | undefined>
  >(documentNode, {
    fetchPolicy: fetchPolicy || "cache-and-network",
    variables,
  });

  useMemo(() => {
    if (
      variables &&
      Object.keys(variables).length !== 0 &&
      Object.entries(variables).every((kv) => kv[1] !== undefined) &&
      variables[refreshOn as keyof TVariables] !== undefined &&
      !skip
    ) {
      refetch(variables);
    }
  }, [variables[refreshOn as keyof TVariables]]);

  return {
    loading,
    error,
    data: parseData<T>(data, deArray),
    refresh: refetch,
  };
}
