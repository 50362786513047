// import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {

    const navigate = useNavigate();

    return (
        <Box
            component='header'
            role='banner'
            sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                width: '100%', 
                height: 'auto', 
                py: { xs: 1, md: 2 }, 
                bgcolor: '#FFF' 
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: { xs: 'calc(100vw - 16px)', md: 'calc(100vw - 32px)' }, minWidth: '944px', maxWidth: 1600, bgcolor: '' }}>
                <Box
                    role='link'
                    tabIndex={0}
                    marginRight={2} 
                    onClick={() => navigate('/')} 
                    sx={{ display: 'flex', my: 'auto', cursor: 'pointer', bgcolor: '' }}>
                        {useMediaQuery('(max-width:599px)') && <Box component='img' sx={{ maxHeight: 39 }} src='/logo/Swimify_44x44.png' alt='livetiming-logo' />}
                        {useMediaQuery('(min-width:600px) and (max-width:899px)') && <Box component='img' height={51} src='/logo/Swimify_Livetiming.png' alt='livetiming-logo' />}
                        {useMediaQuery('(min-width:900px)') && <Box component='img' height={51} src='/logo/Swimify_Livetiming.png' alt='livetiming-logo' />}
                </Box>
                
                <Box 
                    role='navigation'
                    marginLeft={2} 
                    sx={{ display: 'flex', alignItems: { xs: 'center', sm: 'center' } }}>
                        {useMediaQuery('(max-width:599px)')
                            ?
                            <MenuIcon aria-label='Menu' />
                            :
                            <Button
                                variant='grey'
                                sx={{ fontSize: '0.75rem' }}
                                onClick={() => navigate('/competitions')}
                            >All Competitions</Button>
                        }
                </Box>
            </Box>
        </Box>
    );
}
