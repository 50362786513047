import React, { useContext, useMemo, useEffect } from "react";

import {
  Typography,
  Box,
  Grid,
  AppBar,
  Autocomplete,
  TextField,
} from "@mui/material";

import ReactGA from "react-ga4";
import { ApolloError } from "@apollo/client";

import { StyledTabs } from "../../../../../components/layout";
import QueryResult from "../../../../../components/containers/QueryResult";
import { BlankTabs } from "../../../../../components/layout/BlankTabs";

import CombinedResultsHeader from "./CombinedResultsHeader";
import CombinedResultsLaneHeader from "./CombinedResultsLaneHeader";
import CombinedResultsRow from "./CombinedResultsRow";

import { ScreenContext } from "../../../../../context/ScreenContext";

export default function CombinedResults({
  unsub,
  restartSub,
  compId,
  competitions,
  lanes,
  eventLevel,
  showAge,
  loading,
  error,
  setAgeGroupTabIndex,
  ageGroupTabIndex,
}: {
  unsub: () => void;
  restartSub: () => void;
  ageGroupTabIndex: number;
  selectedAgeGroup: AgeGroup | undefined;
  setAgeGroupTabIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedAgeGroup: React.Dispatch<
    React.SetStateAction<AgeGroup | undefined>
  >;
  loading: boolean;
  error: ApolloError | undefined;
  eventLevel: number;
  showAge: boolean | undefined;
  compId: string | undefined;
  competitions: CombinedCompetition[] | undefined;
  lanes: LaneWithRank[] | undefined;
}) {
  const { screen } = useContext(ScreenContext);

  useMemo(() => {
    ReactGA.set({ page_title: `${screen} - Combined Results` });
  }, [screen]);

  let ageGroups =
    competitions &&
    competitions[0].competition?.events &&
    competitions[0].competition?.events[0] &&
    competitions[0].competition?.events[0].agegroups;

  const handleTabListChange = (e: React.ChangeEvent, newValue: number) => {
    setAgeGroupTabIndex(newValue);
  };

  useEffect(() => {
    restartSub();
    return () => unsub();
  }, []);

  return (
    <Box mt={1}>
      <CombinedResultsHeader competitions={competitions} />
      {ageGroups && ageGroups?.length > 1 && ageGroups?.length < 6 ? (
        /* TABS */
        <AppBar
          position="static"
          color="transparent"
          sx={{
            boxShadow: 0,
            width: "100%",
            height: "100%",
            borderStyle: "solid none none none",
            borderWidth: 1,
            borderColor: "#D0D5DD",
            bgcolor: "",
          }}
        >
          <StyledTabs
            //@ts-ignore
            value={ageGroupTabIndex}
            onChange={handleTabListChange}
            centered
            sx={{ width: "100%" }}
          >
            {ageGroups?.map((list) => (
              //@ts-ignore
              <BlankTabs key={list?.id} label={list?.name} />
            ))}
          </StyledTabs>
        </AppBar>
      ) : (
        ageGroups &&
        ageGroups?.length > 5 && (
          /* DROPDOWN */
          <Autocomplete
            id="summary_lists"
            disableClearable
            options={ageGroups}
            //@ts-ignore
            getOptionLabel={(option) => option.name != undefined && option.name}
            defaultValue={ageGroups[0]}
            onChange={(e, newSummaryType) => {
              const index = ageGroups?.findIndex(
                (x) => x.id === newSummaryType.id
              );
              setAgeGroupTabIndex(index ? index : 0);
            }}
            sx={{ mb: 1, mr: "8px", height: 28, cursor: "pointer" }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, readOnly: true }}
                variant="outlined"
                //@ts-ignore
                color="neutral"
                size="small"
                placeholder="Select summary..."
                autoComplete="off"
                sx={{
                  height: 28,
                  "& :hover": { cursor: "pointer" },
                  "& #summary_lists": { height: 16, padding: 0 },
                  "& input::placeholder": {
                    fontSize: { xs: "0.75rem", sm: "1rem" },
                  },
                }}
              />
            )}
          />
        )
      )}

      {competitions?.length === 0 ? (
        <Typography
          fontWeight={500}
          fontSize={{ xs: "1rem", sm: "1.2rem" }}
          py={1}
          mt={1}
          mx={0.5}
        >
          No competitors in this list
        </Typography>
      ) : (
        <Grid
          container
          sx={{
            borderRadius: 1,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#D0D5DD",
            bgcolor: "#E4E7EC",
            boxShadow: 1,
          }}
        >
          <CombinedResultsLaneHeader
            showAge={showAge}
            eventLevel={eventLevel}
          />

          <QueryResult
            onlyLoadInitially
            loading={loading}
            data={competitions}
            error={error}
          >
            {lanes
              ?.filter((lane) => lane.competitor)
              ?.map((lane, index) => (
                <CombinedResultsRow
                  compId={compId}
                  key={lane?.id}
                  rank={lane.rank}
                  lane={lane}
                  index={index}
                  eventLevel={eventLevel}
                  showAge={showAge}
                />
              ))}
          </QueryResult>
        </Grid>
      )}
    </Box>
  );
}
