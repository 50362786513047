import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Typography, Box } from "@mui/material";
import { AUClient, EUClient } from "../../../utils/apollo/apollo-client";
import QueryResult from "../../../components/containers/QueryResult";
import {
  GET_COMPETITIONS_THIS_WEEK,
  GET_FILTERED_COMPETITIONS_THIS_WEEK,
} from "../../../utils/graphql/queries";
import LiveCompetitionCard from "../../../components/containers/LiveCompetitionCard";

export default function CompetitionsThisWeek({
  selectedRegion,
  selectedCountry,
}) {
  const navigate = useNavigate();

  const [gradient, setGradient] = useState("linear-gradient(#40576B, #374B5C)");
  const [euCompetitions, setEuCompetitions] = useState([]);
  const [auCompetitions, setAuCompetitions] = useState([]);
  const [combinedCompetitions, setCombinedCompetitions] = useState([]);
  const [filteredCompetitions, setFilteredCompetitions] = useState([]);
  const [earliestDate, setEarliestDate] = useState(null);
  const [latestDate, setLatestDate] = useState(null);

  const addDays = (days) => {
    const higherDate = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000);
    return higherDate;
  };
  const removeDays = (days) => {
    const lowerDate = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000);
    return lowerDate;
  };
  const [
    euCompetitionFetch,
    { loading: euLoading, error: euError, data: euData },
  ] = useLazyQuery(GET_COMPETITIONS_THIS_WEEK, {
    client: EUClient,
    fetchPolicy: "network-only",
    skip: !earliestDate || !latestDate, //skip is not allowed in useLazyQuery, find a workaround
    variables: {
      _start: earliestDate,
      _end: latestDate,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const euComps = data?.competitions.map((competition) => ({
        ...competition,
        database_region: 2,
      }));
      setEuCompetitions(euComps);
    },
  });

  const [
    auCompetitionFetch,
    { loading: auLoading, error: auError, data: auData },
  ] = useLazyQuery(GET_COMPETITIONS_THIS_WEEK, {
    client: AUClient,
    fetchPolicy: "network-only",
    skip: !earliestDate || !latestDate, //skip is not allowed in useLazyQuery, find a workaround
    variables: {
      _start: earliestDate,
      _end: latestDate,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const auComps = data?.competitions.map((competition) => ({
        ...competition,
        database_region: 3,
      }));
      setAuCompetitions(auComps);
    },
  });

  const [filteredFetch, { loading: filteredLoading, data: filteredData }] =
    useLazyQuery(GET_FILTERED_COMPETITIONS_THIS_WEEK, {
      client: selectedRegion === 2 ? EUClient : AUClient,
      fetchPolicy: "network-only",
      skip: !earliestDate || !latestDate, //skip is not allowed in useLazyQuery, find a workaround
      variables: {
        _start: earliestDate,
        _end: latestDate,
        nation: selectedCountry,
      },
      onCompleted: (data) => {
        const filteredComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: selectedRegion,
        }));
        setFilteredCompetitions(filteredComps);
      },
    });

  /* UPDATE GRADIENT COLOR */
  useEffect(() => {
    if (selectedRegion === 1) {
      if (combinedCompetitions?.length < 1) {
        setGradient("linear-gradient(#7E8AA3, #667085)");
      } else setGradient("linear-gradient(#40576B, #374B5C)");
    } else {
      if (filteredCompetitions?.length < 1) {
        setGradient("linear-gradient(#7E8AA3, #667085)");
      } else setGradient("linear-gradient(#40576B, #374B5C)");
    }
  }, [combinedCompetitions, filteredCompetitions]);

  /* HANDLE COMBINED FETCH RESULTS */
  useEffect(() => {
    if (euCompetitions?.length > 0 && auCompetitions?.length > 0) {
      const combinedResults = [...euCompetitions, ...auCompetitions];
      const sortedCombinedResults = combinedResults.sort(
        (a, b) =>
          (new Date(a.startDate).getTime() || -Infinity) -
          (new Date(b.startDate).getTime() || -Infinity)
      );
      sortedCombinedResults?.length > 0 &&
        setCombinedCompetitions(sortedCombinedResults);
    } else if (euCompetitions?.length > 0 && auCompetitions?.length < 1) {
      setCombinedCompetitions(euCompetitions);
    } else if (euCompetitions?.length < 1 && auCompetitions?.length > 0) {
      setCombinedCompetitions(auCompetitions);
    }
  }, [euCompetitions, auCompetitions]);

  useEffect(() => {
    setEarliestDate(removeDays());
    setLatestDate(addDays());
  }, []);

  /* HANDLE REGION CHANGE */
  useEffect(() => {
    if (earliestDate && latestDate) {
      if (selectedRegion === 1) {
        euCompetitionFetch();
        auCompetitionFetch();
      } else filteredFetch();
    }
  }, [selectedRegion, earliestDate, latestDate]);

  const loading = (!combinedCompetitions && euLoading) || auLoading;

  return (
    <>
      <Box
        sx={{ mx: 0, my: 0, py: 1, background: `${gradient}` }}
        role="banner"
        aria-label="Competitions This Week"
      >
        <Typography
          my="auto"
          mx={1}
          fontSize="1.2rem"
          fontWeight={600}
          sx={{ color: "#FFF" }}
        >
          Current Competitions
        </Typography>
      </Box>
      <Box>
        <QueryResult
          loadingLarge={loading}
          data={
            selectedRegion === 1 ? combinedCompetitions : filteredCompetitions
          }
        >
          {selectedRegion === 1 &&
            combinedCompetitions?.length < 1 &&
            euCompetitions?.length > 0 &&
            euCompetitions?.map((competition, index) => (
              <Box
                key={competition.id}
                sx={{
                  background:
                    index % 2
                      ? "#FFF"
                      : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                }}
                role="article"
                aria-labelledby={`competition-title-${competition.id}`}
              >
                <LiveCompetitionCard
                  competition={competition}
                  index={index}
                  aria-labelledby={`competition-title-${competition.id}`}
                />
              </Box>
            ))}
          {selectedRegion === 1 &&
            combinedCompetitions?.length < 1 &&
            auCompetitions?.length > 0 &&
            auCompetitions?.map((competition, index) => (
              <Box
                key={competition.id}
                sx={{
                  background:
                    index % 2
                      ? "#FFF"
                      : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                }}
                role="article"
                aria-labelledby={`competition-title-${competition.id}`}
              >
                <LiveCompetitionCard
                  competition={competition}
                  index={index}
                  aria-labelledby={`competition-title-${competition.id}`}
                />
              </Box>
            ))}
          {selectedRegion === 1 &&
            combinedCompetitions?.length > 0 &&
            combinedCompetitions?.map((competition, index) => (
              <Box
                key={competition.id}
                sx={{
                  background:
                    index % 2
                      ? "#FFF"
                      : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                }}
                role="article"
                aria-labelledby={`competition-title-${competition.id}`}
              >
                <LiveCompetitionCard
                  competition={competition}
                  index={index}
                  aria-labelledby={`competition-title-${competition.id}`}
                />
              </Box>
            ))}
          {selectedRegion === 1 && combinedCompetitions?.length < 1 && (
            <Box
              sx={{ background: "linear-gradient(#F9FAFB, #FCFCFD, #F9FAFB)" }}
              role="alert"
              aria-live="polite"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                my="auto"
                mx={2}
                height={60}
                fontSize="0.75rem"
              >
                <Typography fontSize="0.85rem">
                  No Competitions This Week.
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  fontStyle="italic"
                  color="inherit"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate("/competitions")}
                  role="link"
                  aria-label="Click to view All Competitions"
                >
                  Click to view All Competitions
                </Typography>
              </Box>
            </Box>
          )}
          {selectedRegion !== 1 &&
            filteredCompetitions?.length > 0 &&
            filteredCompetitions?.map((competition, index) => (
              <Box
                key={competition.id}
                sx={{
                  background:
                    index % 2
                      ? "#FFF"
                      : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                }}
                role="article"
                aria-labelledby={`competition-title-${competition.id}`}
              >
                <LiveCompetitionCard
                  competition={competition}
                  index={index}
                  aria-labelledby={`competition-title-${competition.id}`}
                />
              </Box>
            ))}
          {selectedRegion !== 1 && filteredCompetitions?.length < 1 && (
            <Box
              sx={{ background: "linear-gradient(#F9FAFB, #FCFCFD, #F9FAFB)" }}
              role="alert"
              aria-live="polite"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                my="auto"
                mx={2}
                height={60}
                fontSize="0.75rem"
              >
                <Typography fontSize="0.85rem">
                  No Competitions This Week.
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  fontStyle="italic"
                  color="inherit"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate("/competitions")}
                  role="link"
                  aria-label="Click to view All Competitions"
                >
                  Click to view All Competitions
                </Typography>
              </Box>
            </Box>
          )}
        </QueryResult>
      </Box>
    </>
  );
}
