import { gql } from "@apollo/client";

//Trigger refetch of heat and summary list
const ROUND_HEAT_STATUS_SUBSCRIPTION = gql`
    subscription roundHeatStatusSubscription_WL($id: Int!) {
        round_by_pk(id: $id) {
            id
            status
            updated_at
            heats {
                id
                status
                updated_at
            }
        }
    }
`;

//Update time_program_entry status colour
const TPE_ROUND_STATUS_SUBSCRIPTION = gql`
    subscription tpeRoundStatusSubscription_WL($sessionId: Int!) {
        time_program_entry(where: {competition_session: {id: {_eq: $sessionId}}}, order_by: {sort_order: asc}) {
            id
            updated_at
            round {
                id
                updated_at
            }
        }
    }
`;

//Scoreboard - Change heat
const COMPETITION_CURRENT_HEATS_SUBSCRIPTION = gql`
    subscription competitionHeatIdsSubscription_WL($id: uuid!) {
        current_heat(where: {competition_id: {_eq: $id}}) {
          heat_type
          heat{
            id
            status
          }
        } 
    }
`;

//Context
const SMALL_CURRENT_HEAT_SUBSCRIPTION = gql`
    subscription currentHeatSubscription_WL($id: uuid = "") {
        current_heat(where: {competition_id: {_eq: $id}, heat_type: {_eq: 1}}) {
            id
            updated_at
            heat {
                id
                updated_at
                round {
                    id
                    updated_at
                    event {
                        id
                        updated_at
                    }
                }
            }
        }
    }
`;

const LIVE_HEAT_FRAME_SUBSCRIPTION = gql`
    subscription liveHeatFrameSubscription_WL($id: Int!) {
        heat_by_pk(id: $id) {
            id
            status
            updated_at
            lanes(order_by: {number: asc}) {
                id
                updated_at
            }
        }
    }
`;

const COMPETITION_SUMMARY_FRAME_SUBSCRIPTION = gql`
    subscription competitionSummaryFrameSub_WL($id: Int!, $order_by: [rank_order_by!] = {}) {
        summary_type_by_pk(id: $id) {
            id
            round {
                id
                updated_at
                event {
                    id
                    updated_at
                }
            }
            ranks(order_by: $order_by) {
                id
                updated_at
                lane {
                    id
                    updated_at
                    competitor {
                        id
                    }
                    sub_results(order_by: {order: asc}) {
                        id
                        updated_at
                    }
                }
            }
        }
    }
`;

const COMBINED_RESULTS_COMPETITION = gql`
subscription getCombinedResultsSubscription_WL($group_number: Int!, $event_number: Int!, $round_type: Int!) {
  combined_competitions(where: {group_number: {_eq: $group_number}}) {
      competition{
    id
    events(where: {number: {_eq: $event_number}}) {
      id
      rounds(where: {round_type: {_eq: $round_type}}) {
        id
        heats {
          id
          status
        }
        status
      }
    }
  }
 }
}
`;

export {
  ROUND_HEAT_STATUS_SUBSCRIPTION,
  TPE_ROUND_STATUS_SUBSCRIPTION,
  COMPETITION_CURRENT_HEATS_SUBSCRIPTION,
  SMALL_CURRENT_HEAT_SUBSCRIPTION,
  COMPETITION_SUMMARY_FRAME_SUBSCRIPTION,
  LIVE_HEAT_FRAME_SUBSCRIPTION,
  COMBINED_RESULTS_COMPETITION,
};
