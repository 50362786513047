import React from "react";

import { Typography, Box } from "@mui/material";

type Size = { xs?: number; sm?: number; md?: number };

type Status =
  | "Official"
  | "LIVE"
  | "SUPERLIVE"
  | "Unofficial"
  | "All Official"
  | "All Unofficial";

const properties = (status: Status, width?: Size, height?: Size) => {
  switch (status) {
    case "All Official":
    case "Official":
      return {
        textColor: "#FFF",
        color1: "#317A4D",
        color2: "#255C3A",
        width: width || ({ xs: 70, sm: 80 } as Size),
      };
    case "LIVE":
      return {
        textColor: "#FFF",
        color1: "#E3393C",
        color2: "#C53133",
        width: width || ({ xs: 40, sm: 56 } as Size),
      };
    case "SUPERLIVE":
      return {
        textColor: "#FFF",
        color1: "#40566A",
        color2: "#384C5D",
        width: width || ({ xs: 70, sm: 80 } as Size),
      };
    case "All Unofficial":
    case "Unofficial":
      return {
        textColor: "#FFF",
        color1: "#49647A",
        color2: "#374B5C",
        width: width || ({ xs: 70, sm: 80 } as Size),
      };
  }
};

export default function StatusBadge({
  status,
  width,
  height,
  fontSize,
}: {
  status: Status;
  width?: Size;
  height?: Size;
  fontSize?: string;
}): JSX.Element | null {
  const {
    textColor,
    color1,
    color2,
    width: badgeWidth,
  } = properties(status, width, height);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={badgeWidth}
      sx={{
        background: `linear-gradient(${color1}, ${color2})`,
        borderRadius: 1,
      }}
    >
      <Typography
        noWrap
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight={500}
        fontSize={fontSize ?? { xs: "0.5rem", sm: "0.65rem", md: "0.8rem" }}
        color={textColor}
        px={{ xs: 1, md: 2 }}
        height={height ?? { xs: "20px", md: "24px" }}
        width="fit-content"
        sx={{}}
      >
        {status}
      </Typography>
    </Box>
  );
}
