export const getView: {
  AsIndex: (view: View) => 0 | 1 | 2 | 3;
  AsView: (index: 0 | 1 | 2 | 3) => View;
} = {
  AsIndex: (view: View): 0 | 1 | 2 | 3 => {
    switch (view) {
      case "entries":
        return 0;
      case "heats":
        return 1;
      case "summary":
        return 2;
      case "combined":
        return 3;
      default:
        return 0;
    }
  },
  AsView: (index: 0 | 1 | 2 | 3): View => {
    switch (index) {
      case 0:
        return "entries";
      case 1:
        return "heats";
      case 2:
        return "summary";
      case 3:
        return "combined";
      default:
        return "entries";
    }
  },
};
