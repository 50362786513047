import React from 'react';

import {
    Box,
    Divider,
    Typography
} from '@mui/material';

import { FaMedal } from 'react-icons/fa';
import { BsPersonFill } from 'react-icons/bs';
import { BsPeopleFill } from 'react-icons/bs';

export default function PointListItems({ lists, pointListMenuItem, setPointListMenuItem }) {

    return (
        <>
            {lists.map((list, index) => (
                <Box key={list.id}
                    display='flex'
                    my={0}
                    py={0.5}
                    px={0}
                    mr={0}
                    onClick={() => { setPointListMenuItem(list) }}
                    sx={{
                        cursor: 'pointer',
                        borderStyle: index === lists.length - 1 ? 'none' : 'none none dashed none',
                        borderTopStyle: index === 0 && 'solid',
                        borderWidth: 1,
                        borderColor: '#D0D5DD',
                        bgcolor: list.id === pointListMenuItem?.id ? '#FFF' : '#EBEEF2',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box display='flex' width='100%' mx={0} gap={1} sx={{ bgcolor: '' }}>
                        <Box width='48px' display='flex' justifyContent='center' alignItems='center' sx={{ bgcolor: '' }}>
                            <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                height='32px'
                                width='32px'
                                ml={1}
                                sx={{ bgcolor: '#374B5C', borderRadius: 2, }}>
                                {list.list_type === 0 &&
                                    <BsPersonFill color='#FFF' />
                                }
                                {list.list_type === 1 &&
                                    <BsPeopleFill color='#FFF' />
                                }
                                {list.list_type === 2 &&
                                    <FaMedal color='#FFF' />
                                }
                                {list.list_type === 5 &&
                                    <BsPeopleFill color='#FFF' />
                                }
                                {list.list_type === 6 &&
                                    <BsPersonFill color='#FFF' />
                                }
                            </Box>
                        </Box>
                        <Divider orientation='vertical' variant='middle' flexItem />
                        <Box display='flex' flexDirection='column' justifyContent='center' gap={0.25} sx={{ bgcolor: '', width: 'calc(100% - 72px)' }}>
                            <Typography noWrap fontWeight={500} fontSize='0.9rem'>
                                {list.name}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ))}
        </>
    )
}
