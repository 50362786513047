import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { MdOutlineFullscreenExit } from "react-icons/md";
import { useContext } from "react";
import { CurrentHeatContext } from "../../../../context/CurrentHeatContext";
import { ScoreboardContext } from "../../../../context/ScoreboardContext";
import HeatChanger from "../components/HeatChanger";
import EventInfo from "../components/EventInfo";
import LiveHeat from "../components/LiveHeat";
import LiveTable from "../components/LiveTable";

type FullscreenProps = {
  close: () => void;
};
export const ScoreboardFullscreen = ({ close }: FullscreenProps) => {
  const { aboutToUpdate } = useContext(CurrentHeatContext);
  const {
    displayHeat,
    showAge,
    lowestSplits,
    trySplits,
    isActive,
    selectedCurrentHeat,
    selectedCurrentHeatChanged,
    updateCurrentHeatSelection,
    useCurrent_HeatLoading,
  } = useContext(ScoreboardContext);
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 500,
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 501,
          top: 0,
          left: 0,
          paddingTop: "0.9rem",
          height: "95%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            padding: "4px",
            backgroundColor: "white",
            height: "100%",
            width: {
              xs: "calc(100vw - 16px)",
              md: "calc(100vw - 32px)",
              lg: "100%",
            },
            minWidth: "944px",
            maxWidth: 1650,
            boxShadow: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              tabIndex={0}
              marginRight={2}
              //onClick={() => navigate('/')}
              sx={{
                display: "flex",
                my: "auto",
                /* cursor: "pointer" ,*/
                bgcolor: "",
              }}
            >
              {useMediaQuery("(max-width:599px)") && (
                <Box
                  component="img"
                  sx={{ maxHeight: 39 }}
                  src="/logo/Swimify_44x44.png"
                  alt="livetiming-logo"
                />
              )}
              {useMediaQuery("(min-width:600px) and (max-width:899px)") && (
                <Box
                  component="img"
                  height={51}
                  src="/logo/Swimify_Livetiming.png"
                  alt="livetiming-logo"
                />
              )}
              {useMediaQuery("(min-width:900px)") && (
                <Box
                  component="img"
                  height={51}
                  src="/logo/Swimify_Livetiming.png"
                  alt="livetiming-logo"
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                width: "60%",
                margin: "auto",
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HeatChanger
                mode={"fullscreen"}
                selectedHeat={selectedCurrentHeat}
                setSelectedCurrentHeat={updateCurrentHeatSelection}
              />
            </Box>
            <Box
              marginLeft={2}
              sx={{
                display: "flex",
                height: "auto",
                alignItems: { xs: "center", sm: "center" },
              }}
            >
              <Button
                variant="contained"
                //@ts-ignore
                color="logoBlue"
                onClick={() => (close(), console.log("Close fullscreen"))}
                sx={{ fontSize: "1rem", gap: 1 }}
              >
                <MdOutlineFullscreenExit />
                Close fullscreen
              </Button>
            </Box>
          </Box>
          <div>
            {displayHeat && !useCurrent_HeatLoading ? (
              <div
                id="scoreboard"
                style={{
                  height: "50%",
                  marginTop: "-5px",
                  width: "98%",
                  margin: "auto",
                }}
              >
                <EventInfo liveHeat={displayHeat} compact />
                <div style={{ height: "95%" }}>
                  <LiveHeat
                    eventType={
                      displayHeat?.time_program_entry?.round?.event?.event_type
                    }
                    heat={displayHeat}
                    showAge={showAge}
                    isActive={isActive}
                  />
                  <LiveTable
                    selectedCurrentHeatChanged={selectedCurrentHeatChanged}
                    heat={displayHeat}
                    lowestSplits={lowestSplits}
                    trySplits={trySplits}
                    aboutToUpdate={aboutToUpdate}
                  />
                </div>
              </div>
            ) : (
              <Grid
                container
                item
                justifyContent="center"
                alignItems="auto"
                xs={12}
              >
                <Typography p={1} variant="h4">
                  {selectedCurrentHeat === "Current"
                    ? " No ongoing heat"
                    : selectedCurrentHeat === "Next"
                    ? "No upcoming Heat"
                    : "No previous Heat"}
                </Typography>
              </Grid>
            )}
          </div>
        </Box>
      </div>
    </div>
  );
};
