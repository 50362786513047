import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Paper,
    Grid,
    Box,
    Typography,
} from '@mui/material';

import Breadcrumbs from './Breadcrumbs';
import SearchComponent from '../SearchComponent';

export default function MainTableLayout({ children, title, button1, button2, button3, button4, lowerButton1, lowerButton2, lowerButton3, lowerButton4, lowerCenterButton, countryFilter, dropDown }: { children?: React.ReactNode, title: React.ReactNode , button1?: JSX.Element, button2?: JSX.Element, button3?: JSX.Element, button4?: JSX.Element, lowerButton1?: JSX.Element, lowerButton2?: JSX.Element, lowerButton3?: JSX.Element, lowerButton4?: JSX.Element, lowerCenterButton?: JSX.Element, countryFilter?: any, dropDown?: any}) {

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState<string>('');
    const isMounted = useRef<boolean>(false);

    // will not run on first render
    useEffect(() => {
        if (isMounted.current) {
            navigate('/search', { state: { query: searchValue } });
        } else {
            isMounted.current = true;
        }
    }, [searchValue]);

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: { xs: 1, sm: 2 } }}>
            <Paper elevation={3} sx={{ width: { xs: 'calc(100vw - 16px)', md: 'calc(100vw - 32px)' }, maxWidth: 1600, minWidth: '944px', bgcolor: 'white', marginBottom: 2 }}>
                <Grid container sx={{ bgcolor: '' }}>
                    <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', bgcolor: 'white' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: { xs: 1, md: 1 }, my: { xs: 1, md: 1 }, width: '100%', bgcolor: '' }}>
                            {title ? <Typography component='div' my='auto' mr={1} fontSize='0.9rem' fontWeight='500'>{title}</Typography> : <Breadcrumbs />}
                            {button1}
                            {button2}
                            {button3}
                            {button4}
                            <Box display='flex'>
                                {countryFilter}
                                <SearchComponent width={{ xs: 'calc(55vw - 24px)', sm: countryFilter ? 'calc(45vw - 24px)' : 'calc(65vw - 24px)' }} maxWidth={500} placeholder='Search Competition...' setSearchValue={setSearchValue} />
                            </Box>
                        </Box>
                    </Box>
                    {(lowerButton1 || lowerButton2 || lowerCenterButton || lowerButton3 || lowerButton4) &&
                        <Box width='100%' display='flex' justifyContent='space-between' sx={{ bgcolor: 'white' }}>
                            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 1, mb: 1, bgcolor: '' }}> */}
                            <Box sx={{  mx: 1, mb: 1 }}>
                                {/* <Box display='flex' justifyContent='flex-start' alignItems='center'> */}
                                    {lowerButton1}
                                {/* </Box> */}
                                {/* <Box display='flex' justifyContent='flex-end'>
                                    {lowerButton2}
                                </Box> */}
                            </Box>
                            {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 1, mb: 1 }}>
                                {lowerCenterButton}
                            </Box> */}
                            <Box sx={{  mx: 1, mb: 1, marginTop: -1 }}>
                                {dropDown}
                            </Box>

                            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 1, /* mb: 1,  width: { xs: 'calc(55vw - 24px)', sm: 'calc(65vw - 24px)' } /* width: '50%'  }}> */}
                            <Box sx={{  mx: 1, mb: 1 }}>
                                {/* <Box display='flex' justifyContent='flex-start'>
                                    {lowerButton3}
                                </Box> */}
                                {/* <Box display='flex' justifyContent='flex-end' > */}
                                    {lowerButton4}
                                {/* </Box> */}
                            </Box>
                        </Box>
                    }
                    {children}
                </Grid>
            </Paper>
        </Box>
    )
}