export default function getStartTime(startTime) {
    const testDate = new Date(startTime);
    if (testDate instanceof Date && !isNaN(testDate)) {
        if (startTime === '0001-01-01T00:00:00+00:00') {
            return '--:--'
        } else {
            const time = new Date(startTime);
            const ms = 1000 * 60 * 1;
            const roundedTime = new Date(Math.round(time.getTime() / ms) * ms);
            return roundedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }
    } else return '--:--';
}