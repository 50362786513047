import React, { useState, useEffect } from "react";

import { Typography, Box, Grid } from "@mui/material";

import {
  getStartTime,
  HandleHeatStatus,
  liveCompetitorWidth,
  getEventLevel,
} from "../../../../components/utils";
import LiveHeatCard from "./LiveHeatCard";
import splitTimeColumns from "./splitTimeColumns";
import calculateStartEndTimeDiff from "../../../../components/utils/calculateStartEndTimeDiff";
import HeatStopWatch from "../../../../components/HeatStopWatch";

export default function LiveHeat({
  eventType,
  heat,
  showAge,
  isActive,
}: {
  eventType: number | undefined;
  heat: Heat;
  showAge?: boolean;
  isActive?: boolean;
}) {
  const [eventLevel, setEventLevel] = useState(2);
  const tpe = heat?.time_program_entry;
  const event = heat?.time_program_entry?.round?.event;

  useEffect(() => {
    if (event) {
      setEventLevel(getEventLevel(event?.event_competition_level));
    }
  }, [event]);

  const distance = tpe?.round?.event?.distance;

  //INFO: function that generates correct split time headers depending on race distance
  const splitHeaders = () => {
    const splits = distance ? splitTimeColumns(distance) : [];
    if (splits?.length > 0) {
      return splits.map((split) => (
        <Box
          key={split}
          display="flex"
          justifyContent="flex-end"
          width={72}
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          <Typography fontSize="0.7rem" color="#667085">
            {split}m
          </Typography>
        </Box>
      ));
    } else return;
  };

  return (
    <Box
      mt={1}
      id="live-heat-box"
      sx={{
        borderRadius: "6px 6px 0px 0px",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        bgcolor: "#E4E7EC",
        boxShadow: 1,
      }}
    >
      <Box display="flex" mx={0.5} my={0.5} sx={{ bgcolor: "" }}>
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          sx={{ bgcolor: "" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="auto"
            height="2.8rem"
            width="2.8rem"
            sx={{
              bgcolor: "#374B5C",
              borderRadius: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography fontSize="1rem" color="#FFF">
                {heat?.number}
              </Typography>
              <Typography fontSize="0.75rem" lineHeight={1} color="#FFF">
                heat
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography mx={1} fontWeight="bold" fontSize="1.1rem">
              {heat?.name}
            </Typography>
            {heat.status &&
            heat?.status > 0 &&
            heat?.start_time !== "0001-01-01T00:00:00+00:00" ? (
              <Typography mx={1} fontWeight="300" fontSize="0.8rem">
                Started at: {getStartTime(heat?.start_time)}{" "}
                {heat?.estimated_start_time &&
                  heat?.start_time &&
                  calculateStartEndTimeDiff(
                    heat?.estimated_start_time,
                    heat?.start_time
                  )}
              </Typography>
            ) : (
              <Typography mx={1} fontWeight="300" fontSize="0.8rem">
                Estimated start time: {getStartTime(heat?.estimated_start_time)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection={"column"} justifyContent="center">
          <HeatStopWatch
            heat={heat}
            currentHeatId={heat?.id}
            showZero={true}
            distance={event?.distance}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mr="4px"
          sx={{
            bgcolor: "",
          }}
        >
          {
            <HandleHeatStatus
              heat={heat}
              currentHeatId={heat?.id}
              isActive={isActive}
            />
          }
        </Box>
      </Box>
      {/* HEAT LANES START */}
      <Grid container>
        <Grid
          container
          item
          xs={12}
          height={24}
          mx={0}
          alignItems="center"
          sx={{ bgcolor: "" }}
        >
          <Box display="flex" width={40} ml="8px" sx={{ bgcolor: "" }}>
            <Typography fontSize="0.7rem" color="#667085">
              Lane
            </Typography>
          </Box>
          <Box
            display="flex"
            ml="18px"
            sx={{
              width: liveCompetitorWidth(distance, eventLevel),
              bgcolor: "",
            }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              Competitor
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={40}
            ml="10px"
            sx={{ bgcolor: "" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              {showAge ? "Age" : "Year"}
            </Typography>
          </Box>
          <Box display="flex" width={218} ml="8px" sx={{ bgcolor: "" }}>
            <Typography fontSize="0.7rem" color="#667085">
              Club
            </Typography>
          </Box>
          <Box display="flex" ml="8px" sx={{ bgcolor: "" }}>
            {splitHeaders()}
          </Box>
          <Box
            display="flex"
            width={32}
            justifyContent="flex-end"
            ml="8px"
            sx={{ bgcolor: "" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              RT
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={40}
            ml="8px"
            sx={{ bgcolor: "", display: eventLevel > 1 ? "none" : "inherit" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              Rank
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={eventLevel > 1 ? 72 : 70}
            ml="8px"
            mr="4px"
            sx={{ bgcolor: "" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              {heat?.status === 0 ? "Entry Time" : "Result"}
            </Typography>
          </Box>
        </Grid>
        {heat?.lanes?.map(
          (lane, index) =>
            lane && (
              <LiveHeatCard
                eventType={eventType}
                lane={lane}
                key={lane?.id}
                index={index}
                heatStatus={heat?.status}
                distance={distance}
                eventLevel={eventLevel}
                showAge={showAge}
              />
            )
        )}
      </Grid>
    </Box>
  );
}
