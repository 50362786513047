import styled from 'styled-components';
import buildData from '../build-data.json';

export default function Footer() {

    return (
        <FooterComp>
            <div className='wrapper'>
                <div aria-label='Copyright'>
                    Copyright &copy; 2022 Swimify &trade;
                </div>
                <div className='second'>
                    {buildData && 
                        <span aria-label={`v.${buildData.buildMajor}.${buildData.buildMinor}.${buildData.buildRevision}`}>
                            v.{buildData.buildMajor}.{buildData.buildMinor}.{buildData.buildRevision}
                        </span>
                    }
                    <a href='https://www.swimify.com' target='_blank' rel='noopener noreferrer' aria-label='Learn more about Swimify'>
                        Learn more about Swimify &trade;
                    </a>
                    <a href="https://cdn-eu.swimify.com/misc/Swimify_Terms_and_Conditions.pdf" target='_blank' rel='noopener noreferrer' aria-label='Terms of use'>
                        Terms of use
                    </a>
                    <a href="https://support.swimify.com/" target='_blank' rel='noopener noreferrer' aria-label='Support'>
                        Support
                    </a>
                </div>
            </div>
        </FooterComp>
    );
}

const FooterComp = styled.footer`
    display:flex;
    justify-content: center;
    width: 100%;
    background-color: #FFF;
    position: absolute;
    bottom: -5.1em;
    .wrapper{
        display: flex;
        justify-content: space-between;
        width: 1440px;
        padding: 1em;
        font-size: 12px;
        color: black;
        a{
            color: black;
            text-decoration: none;
        }

        .second{
            display:flex;
            gap: 4em;
            
            span{
                color: #9f9f9f;
            }
        }
    }
`;