import React, { useState } from "react";

import { Splits } from "../views/competition/scoreboard/ScoreboardView";

export default function useCalculateSplits({
  lowestSplits,
  setLowestSplits,
}: {
  lowestSplits: Splits;
  setLowestSplits: React.Dispatch<React.SetStateAction<Splits>>;
}) {
  const maxSplit: Splits = {
    "50": Number.MAX_SAFE_INTEGER,
    "100": Number.MAX_SAFE_INTEGER,
    "200": Number.MAX_SAFE_INTEGER,
  };
  const [lastSplits, setLastSplits] = useState<Splits>(maxSplit);

  const resetSplits = () => {
    setLowestSplits(maxSplit);
  };

  const trySplits = (splits: Splits): void => {
    const newLowestSplit = lowestSplits;
    Object.keys(splits).forEach((split) => {
      if (splits[split as keyof Splits] < lowestSplits[split as keyof Splits]) {
        newLowestSplit[split as keyof Splits] = splits[split as keyof Splits];
      }
    });
    setLowestSplits(JSON.parse(JSON.stringify(newLowestSplit)));
    setLastSplits(JSON.parse(JSON.stringify(newLowestSplit)));
  };

  const retryLastSplits = () => {
    trySplits(lastSplits);
  };

  return { lowestSplits, trySplits, resetSplits, retryLastSplits };
}
