import React from 'react';

import {
    Box,
    Typography,
    Grid,
} from '@mui/material';

export default function PointsHeadersClubs() {

    return (
        <Grid container item xs={12} height={24} mx={0} alignItems='center' sx={{ bgcolor: '' }}>
            <Box display='flex' width={32} ml='8px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Rank</Typography>
            </Box>
            <Box display='flex' width='calc(100% - 134px)' ml='8px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Club</Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' width={68} ml='8px' mr='10px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Points</Typography>
            </Box>
        </Grid>
    )
}
