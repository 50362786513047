import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";

import { Typography } from "@mui/material";

import QueryResult from "../../../../../components/containers/QueryResult";
import HeatCard from "./HeatCard";
import useFetch from "../../../../../hooks/graphql/useFetch";
import { GET_HEAT_LIST } from "../../../../../utils/graphql/queries";
import { ScreenContext } from "../../../../../context/ScreenContext";
import ReactGA from "react-ga4";
import { ApolloError, OperationVariables } from "@apollo/client";

export default function HeatList({
  data,
  error,
  loading,
  refresh,
  eventView,
  eventType,
  isCompetitionActive,
  roundHeatSubData,
  timeProgramEntry,
  time_programId,
  eventLevel,
  showAge,
  superliveSeo,
  /* selectedTpe */ // for the potential fix below
}: {
  data: TimeProgramEntry | undefined,
  error: ApolloError | undefined,
  loading: boolean,
  refresh: (variables?: OperationVariables | undefined) => void,
  eventView: View;
  eventType: number | undefined;
  isCompetitionActive: boolean;
  roundHeatSubData: Round | undefined;
  timeProgramEntry: TimeProgramEntry | undefined;
  round: Round | undefined;
  time_programId: number | undefined;
  eventLevel: number;
  showAge: boolean | undefined;
  superliveSeo: string | undefined;
  /* selectedTpe: TimeProgramEntry | undefined; */ // for the potential fix below
}) {
  const params = useParams();

  const { screen } = useContext(ScreenContext);
  useMemo(() => {
    ReactGA.set({ page_title: `${screen} - Heats` });
  }, [screen]);


  // potential fix for varibles related to selectedTpe from rendering/changing/updating before data
  /* const prevData = useRef<TimeProgramEntry | undefined>(undefined)

  const [eventType, setEventType] = useState<number | undefined>()
  const [timeProgramEntry, setTimeProgramEntry] = useState<TimeProgramEntry | undefined>()
  const [timeProgramId, setTimeProgramId] = useState<number | undefined>()
  const [round, setRound] = useState<Round | undefined>()

  useEffect(() => {
    console.log('new data')
    if (JSON.stringify(data) !== JSON.stringify(prevData.current)) {
      setEventType(selectedTpe?.round?.event?.event_type)
      setTimeProgramEntry(selectedTpe)
      setTimeProgramId(selectedTpe?.id)
      setRound(selectedTpe?.round)
      prevData.current = data
    }
  }, [data]) */

  /* const prevTpe = useRef(timeProgramEntry) */

  const isRelay = timeProgramEntry?.round?.event?.is_relay;
  const isPara = timeProgramEntry?.round?.event?.is_para_event;

  const handleLiveTpeClick = () => {
    const liveElement = document.getElementById("live-heat");

    if (liveElement) {
      const yOffset = -170;
      const y =
        (liveElement?.getBoundingClientRect().top || 0) +
        window.scrollY +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    } else return;
  };

  /* const { loading, error, data, refresh } = useFetch<TimeProgramEntry>(
    GET_HEAT_LIST,
    { id: time_programId },
    "id",
    "cache-and-network"
  ); */

  useEffect(() => {
    refresh();
  }, [roundHeatSubData]);

  useEffect(() => {
    (params.liveTpe && data) && handleLiveTpeClick();
  }, [params, data])


  /* useMemo(() => {
    console.log(JSON.stringify(timeProgramEntry) === JSON.stringify(prevTpe.current))
    if (JSON.stringify(timeProgramEntry) !== JSON.stringify(prevTpe.current)) {
      prevTpe.current = timeProgramEntry
    }
  }, [timeProgramEntry]) */

  return (
    <QueryResult onlyLoadInitially error={error} loading={loading} data={data}>
      {data?.heats?.length === 0 ? (
        <Typography
          fontWeight={500}
          fontSize={{ xs: "1rem", sm: "1.2rem" }}
          py={1}
          mt={1}
          mx={0.5}
        >
          No Heats
        </Typography>
      ) : (
        data?.heats?.map((heat) => (
          <HeatCard
            roundHeatSubData={roundHeatSubData}
            eventView={eventView}
            eventType={eventType}
            key={heat?.id}
            passedHeat={heat}
            isCompetitionActive={isCompetitionActive}
            event={timeProgramEntry?.round?.event}
            isRelay={isRelay}
            isPara={isPara}
            eventLevel={eventLevel}
            showAge={showAge}
            superliveSeo={superliveSeo}
          />
        ))
      )}
    </QueryResult>
  );
}
