import { useEffect } from "react";

import { ApolloError } from "@apollo/client";
import { GET_COMPETITION_TIMES } from "../utils/graphql/queries";
import useFetch from "./graphql/useFetch";

/**
 * 
 * @param competitionId The id of a competition
 * @returns The start and end times of a competition
 */

export default function useCompetitionTimes(competitionId: string | undefined): { data: Competition | undefined, error: ApolloError | undefined, loading: boolean; } {

    const { data, error, loading, refresh } = useFetch<Competition>(GET_COMPETITION_TIMES, { id: competitionId });

    useEffect(() => {
        if (competitionId) {
            refresh();
        }
    }, [competitionId]);

    return { data, error, loading };
}