import React, { useState } from "react";
export const CurrentHeatContext = React.createContext<{
  currentHeats: CurrentHeat[] | undefined;
  disableBtns: boolean;
  aboutToUpdate: boolean;
  setAboutToUpdate: (boolean: boolean) => void;
  setCurrentHeats: (heats: CurrentHeat[] | undefined) => void;
  setDisableBtns: (bool: boolean) => void;
}>({
  currentHeats: undefined,
  disableBtns: false,
  aboutToUpdate: false,
  setAboutToUpdate: () => {},
  setCurrentHeats: () => {},
  setDisableBtns: () => {},
});

export default function CurrentHeatContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [heats, setHeats] = useState<CurrentHeat[] | undefined>(undefined);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

  const updateButtonDisable = (val: boolean) => {
    setButtonDisable(val);
  };
  const updateCurrentHeats = (heatsval: CurrentHeat[] | undefined) => {
    setHeats(heatsval);
  };
  const setUpdateBool = (val: boolean) => {
    setShouldUpdate(val);
  };
  return (
    <CurrentHeatContext.Provider
      value={{
        disableBtns: buttonDisable,
        currentHeats: heats,
        aboutToUpdate: shouldUpdate,
        setAboutToUpdate: setUpdateBool,
        setCurrentHeats: updateCurrentHeats,
        setDisableBtns: updateButtonDisable,
      }}
    >
      {children}
    </CurrentHeatContext.Provider>
  );
}
