import { Box } from "@mui/material";
import StatusBadge from "../layout/StatusBadge";

export default function HandleEventStatus(
  status: number,
  isActive?: boolean,
  isCurrentRound?: boolean
): JSX.Element | null {
  if (status === 3 && isCurrentRound)
    return (
      <Box
        sx={{
          animation: isActive ? "pulse-animation 2s infinite" : "",
          borderRadius: 1,
        }}
      >
        <StatusBadge status={"LIVE"} />
      </Box>
    );
  if (status === 5)
    return (
      <Box
        sx={{
          animation: isActive ? "pulse-animation-green 2s infinite" : "",
          borderRadius: 1,
        }}
      >
        <StatusBadge status={"Official"} />
      </Box>
    );
  return null;
}
