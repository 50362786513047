import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

export default function CombinedResultsLaneHeader({ showAge, eventLevel }: { showAge?: boolean, eventLevel: number; }) {
    return (
        <Grid container item xs={12} height={24} mt={0.25} alignItems='center' marginLeft={0.5} >
            <Box display='flex' width={40} ml='4px' sx={{ display: eventLevel > 1 ? 'none' : 'inherit' }}>
                <Typography fontSize='0.7rem' color='#667085'>Rank</Typography>
            </Box>
            <Box display='flex' width={eventLevel > 1 ? 'calc(100% - 438px)' : 'calc(100% - 518px)'} maxWidth='180px' ml='28px'>
                <Typography fontSize='0.7rem' color='#667085'>Competitor</Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' width={50} ml='8px'>
                <Typography fontSize='0.7rem' color='#667085'>{showAge ? 'Age' : 'Year'}</Typography>
            </Box>
            <Box display='flex' alignItems='center' minWidth='132px' width={eventLevel > 1 ? 'calc(100% - 646px)' : 'calc(100% - 676px)'} ml='8px' marginLeft={1.5}>
                <Typography fontSize='0.7rem' color='#667085'>Club</Typography>
            </Box>
            <Box display='flex' alignItems='center' minWidth='132px' pl={2} marginLeft={1}>
                <Typography fontSize='0.7rem' color='#667085'>Location</Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' width={34} ml='8px' sx={{ display: eventLevel > 1 ? 'none' : 'inherit' }}>
                <Typography fontSize='0.7rem' color='#667085'></Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' width={40} ml='34px' mr='4px'>
                <Typography fontSize='0.7rem' color='#667085'>Result</Typography>
            </Box>
        </Grid>
    );
}
