import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import "./competitionCard.css";
import { DateElement, FlagComponent } from "../layout";
import { storeCompetitionId } from "../utils";
import SuperliveLogo from "../svg/SuperliveLogo";

export default function CompetitionCard({ competition }) {
  const navigate = useNavigate();

  const {
    id,
    startDate,
    endDate,
    name,
    seo_text,
    organizer,
    city,
    pool_name,
    superlive,
  } = competition;

  const compImg =
    competition?.small_image || "/img/competition-img-small-placeholder.png";

  const handleNavigation = () => {
    if (window.location.hostname === "test.swimify.com")
      return (window.location.href = `https://test.swimify.com/competitions/${seo_text}`);
    if (competition?.database_region === 2)
      return (window.location.href = `https://live.swimify.com/competitions/${seo_text}`);
    if (competition?.database_region === 3)
      return (window.location.href = `https://au.swimify.com/competitions/${seo_text}`);
    else navigate(`/competitions/${seo_text}`);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      height={{ xs: 36, md: 44 }}
      sx={{
        width: { xs: "100%" /* md: 'calc(50vw - 16px)' */ },
        maxWidth: { md: 800 },
        borderStyle: "none solid solid none",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        cursor: "pointer",
      }}
      onClick={() => {
        storeCompetitionId(seo_text, id);
        handleNavigation();
      }}
      role="button"
      aria-label={`View details for ${name}`}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          storeCompetitionId(seo_text, id);
          handleNavigation();
        }
      }}
    >
      <Grid container item xs={6} width="100%" sx={{ bgcolor: "" }}>
        {compImg && (
          <Grid
            item
            width={56}
            height={28}
            my="auto"
            ml={{ xs: 0.5, sm: 1 }}
            sx={{ bgcolor: "" }}
          >
            <Box
              component="img"
              src={compImg}
              width={56}
              height={28}
              sx={{ borderRadius: 1, boxShadow: 3, objectFit: "cover" }}
              alt={`${name} logo`}
            />
          </Grid>
        )}
        <Grid
          item
          gap={{ xs: 0.25, md: 0.5 }}
          ml={1}
          width={compImg ? "calc(100% - 80px)" : "calc(100% - 16px)"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            bgcolor: "",
          }}
        >
          <Typography
            noWrap
            sx={{
              fontWeight: 700,
              fontSize: { xs: "0.75rem", sm: "0.9rem", md: "1rem" },
              lineHeight: 1,
            }}
          >
            {name}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap={{ xs: 0.35, sm: 0.6 }}
            sx={{ bgcolor: "" }}
          >
            {DateElement(startDate, endDate)}
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={6} width="100%" sx={{ bgcolor: "" }}>
        <Grid
          container
          item
          display="flex"
          xs={6}
          gap={{ xs: 0.5, sm: 0.5 }}
          my="auto"
          sx={{ bgcolor: "" }}
        >
          <Box height={28} width={28} sx={{ boxShadow: 3, borderRadius: 2 }}>
            <Box
              component="img"
              height={28}
              width={28}
              src={
                competition?.organizer_logo
                  ? competition?.organizer_logo
                  : "/img/img-placeholder-square_32.png"
              }
              sx={{ borderRadius: 2 }}
              alt={`${organizer} logo`}
            />
          </Box>
          <Box
            width={{ xs: "calc(100% - 36px)", md: "calc(100% - 40px)" }}
            maxWidth={340}
            my="auto"
            sx={{ bgcolor: "" }}
          >
            <Typography
              noWrap
              lineHeight={1}
              sx={{
                fontWeight: 300,
                fontSize: { xs: "0.5rem", sm: "0.65rem", md: "0.75rem" },
              }}
            >
              {organizer}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} my="auto" sx={{ bgcolor: "" }}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={{ xs: 0.5, sm: 1 }}
            sx={{ bgcolor: "" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={0.25}
              width={{
                xs: "calc(100% - 24px)",
                sm: "calc(100% - 28px)",
                md: "calc(100% - 8px)",
              }}
              maxWidth={352}
              sx={{ bgcolor: "" }}
            >
              <Typography
                noWrap
                lineHeight={1}
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "0.5rem", sm: "0.65rem", md: "0.8rem" },
                  maxWidth: "160px",
                }}
              >
                {pool_name}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                gap={0.5}
                sx={{ bgcolor: "" }}
              >
                <Box
                  width={16}
                  height={12}
                  display="flex"
                  alignItems="center"
                  sx={{ boxShadow: 2, borderRadius: 1, bgcolor: "" }}
                  aria-label="Nation flag"
                >
                  <FlagComponent
                    countryCode={competition?.nation_code}
                    size="s"
                  />
                </Box>
                <Typography
                  noWrap
                  lineHeight={1}
                  sx={{
                    fontWeight: 300,
                    fontSize: { xs: "0.5rem", sm: "0.65rem", md: "0.75rem" },
                  }}
                >
                  {city}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginRight: "0.3rem", marginTop: "0.2rem" }}>
              {superlive && (
                <SuperliveLogo
                  width={30}
                  height={30}
                  fill="#374B5C"
                  aria-label="Superlive available"
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
