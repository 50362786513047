export interface Visited{
    seoText: string,
    competitionId: string;
}

export const storeCompetitionId = (seo_text: string | undefined, competition_id: string) => {
    if (seo_text) {
        const ls = getLocalStorageSEO()
        if (ls) {
            ls.find(competition => competition.seoText === seo_text) === undefined && ls.push({seoText: seo_text, competitionId: competition_id})
            localStorage.setItem("visited", JSON.stringify(ls));
        }else{
            const newList: Visited[] = []
            newList.push({seoText: seo_text, competitionId: competition_id});
            localStorage.setItem("visited", JSON.stringify(newList));
        }
    }
}

export const getLocalStorageSEO = (): Visited[] => {
    const competitions = localStorage.getItem("visited");
    if (competitions) {
        const parsed = JSON.parse(competitions);
        if (parsed) {
            return JSON.parse(competitions) as Visited[]
        }else{
            return [];
        }
    }
    return [];
}