import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import useSubscribedFetch from "./graphql/useSubscribedFetch";
import useCompetitionTimes from "./useCompetitionTimes";

import {
  COMPETITION_CURRENT_HEATS,
  GET_HEAT_BY_ID,
} from "../utils/graphql/queries";
import { COMPETITION_CURRENT_HEATS_SUBSCRIPTION } from "../utils/graphql/subscriptions";

import { isCompetitionActive } from "../components/utils";

import useFetch from "./graphql/useFetch";
import useHeat from "./useHeat";
import { CurrentHeatContext } from "../context/CurrentHeatContext";

export type CurrentHeatSelection = "Current" | "Next" | "Prev";

/**
 * This hook gets a live current heat with delay and some util functions for the scoreboard screen.
 * There is also possible to view one of the current heats as a heat object and change between the
 * heat objects in the current heat object
 * @param competitionId The id of a competition ( string )
 * @param resetSplits A function used for reseting the best split times
 * @returns an object containing many variables used to read heat data.
 */

export default function useScoreboardCurrentHeat(
  competitionId: string | undefined,
  resetSplits: () => void,
  retryLastSplits: () => void,
  eventView: View
) {
  const { setAboutToUpdate, setCurrentHeats, setDisableBtns } =
    useContext(CurrentHeatContext);
  const params = useParams();

  const [initialLoadTime] = useState<number>(Date.now());
  const [currentHeat, setCurrentHeat] = useState<CurrentHeat | undefined>();

  const [viewCurrentHeats, setViewCurrentHeats] = useState<
    CurrentHeat[] | undefined
  >();
  const [selectedCurrentHeat, setSelectedCurrentHeat] =
    useState<CurrentHeatSelection>("Current");
  const [hasIgnoredFirst, setHasIgnoredFirst] = useState<boolean>(false);

  const [selectedCurrentHeatChanged, setSelectedCurrentHeatChanged] =
    useState<boolean>(false);

  /* Get times of a competition to see if a subscription is nessesary */
  const {
    data: timesData,
    loading: timesLoading,
    error: err,
  } = useCompetitionTimes(competitionId);

  /* Establish subscription for current_heats if there are times and current time is within the range */
  const {
    data: currentHeats,
    loading: currentHeatLoading,
  }: { data: CurrentHeat[] | undefined; loading: boolean } = useSubscribedFetch(
    {
      initialDocument: COMPETITION_CURRENT_HEATS,
      subscriptionDocument: COMPETITION_CURRENT_HEATS_SUBSCRIPTION,
    },
    { id: competitionId },
    !timesLoading && isCompetitionActive(timesData),
    "id"
  );

  const {
    data: initialHeat,
    loading: loadingInitialHeat,
    refresh: refreshInitial,
    error: initialError,
  } = useFetch<Heat>(
    GET_HEAT_BY_ID,
    { id: currentHeat?.heat?.id },
    "id",
    "cache-first",
    !currentHeat?.heat?.id
  );

  /* Fetch the heat object from the selected currentHeat */
  const {
    heat,
    loading: heatLoading,
    subscribe: isActive,
  } = useHeat(initialHeat, eventView, undefined);

  useEffect(() => {
    const currentheat = viewCurrentHeats?.find(
      (heat) =>
        heat.heat_type ===
        returnCurrentHeatSelectionAsNumber(selectedCurrentHeat)
    );
    setCurrentHeat(currentheat);
    if (!selectedCurrentHeatChanged) {
      setSelectedCurrentHeatChanged(true);
    }
  }, [selectedCurrentHeat]);

  useEffect(() => {
    if (selectedCurrentHeatChanged) {
      setSelectedCurrentHeatChanged(false);
    }
  }, [selectedCurrentHeatChanged]);

  useEffect(() => {
    if (heat?.id === currentHeat?.heat?.id) retryLastSplits();
    else resetSplits();
  }, [heat]);

  useEffect(() => {
    if (currentHeats && !currentHeatLoading) {
      setCurrentHeats(
        currentHeats && currentHeats?.length > 1 ? currentHeats : undefined
      );
      const currentheat =
        currentHeats && currentHeats?.length > 1
          ? currentHeats?.find(
              (heat) =>
                heat.heat_type ===
                returnCurrentHeatSelectionAsNumber(selectedCurrentHeat)
            )
          : undefined;
      if (hasIgnoredFirst) {
        setDisableBtns(true);
        initialLoadTime + 2000 < Date.now() && setAboutToUpdate(true);
        initialLoadTime + 2000 < Date.now() &&
          setTimeout(() => {
            retryLastSplits();
            setViewCurrentHeats(currentHeats);
            setCurrentHeat(currentheat);
            setAboutToUpdate(false);
            setDisableBtns(false);
          }, 8000);
      } else {
        setAboutToUpdate(false);
        setCurrentHeats(currentHeats);
        setViewCurrentHeats(currentHeats);
        setCurrentHeat(currentheat);
        retryLastSplits();
      }
      setHasIgnoredFirst(true);
    }
  }, [currentHeats, currentHeatLoading]);

  return {
    selectedCurrentHeatChanged,
    heat,
    CurrentHeats: viewCurrentHeats,
    loading: heatLoading || currentHeatLoading,
    selectedCurrentHeat,
    setSelectedCurrentHeat,
    setSelectedCurrentHeatChanged,
    isActive,
  };
}

const returnCurrentHeatSelectionAsNumber = (
  heatSelection: CurrentHeatSelection | undefined
): number | undefined => {
  switch (heatSelection) {
    case "Current":
      return 1;
    case "Next":
      return 3;
    case "Prev":
      return 2;
    default:
      return undefined;
  }
};
