import React, { useState } from 'react';

import {
    Box,
    Grid,
    Paper,
    Typography,
    TextField,
    Autocomplete,
    useMediaQuery
} from '@mui/material';

import { useQuery } from '@apollo/client';
import { GET_COMPETITION_POINTS_LISTS } from '../../../../utils/graphql/queries';

import QueryResult from '../../../../components/containers/QueryResult';
import PointListItems from './PointListItems';

export const PointListMenu = ({ competitionId, pointListMenuItem, setPointListMenuItem, setNoPointsLists }) => {

    const [pointsLists, setPointsLists] = useState([]);

    const { loading, error, data } = useQuery(GET_COMPETITION_POINTS_LISTS, {
        variables: { _id: competitionId },
        onCompleted: data => {
            setPointListMenuItem(data?.point_list[0]);
            setPointsLists(data?.point_list);
            if (data?.point_list.length === 0) {
                setNoPointsLists(true);
            }
        }
    });

    const desktop = useMediaQuery('(min-width:900px)');

    if (desktop) {
        return (
            <Paper elevation={3} sx={{}}>
                <Box py={0} sx={{ bgcolor: '' }}>
                    <Box mx={0} my={0} sx={{ bgcolor: '' }}>
                        <Grid container my={0} display='flex'>
                            <Box display='flex' justifyContent='flex-start' alignItems='center' my='auto' width='100%' gap={1} py={1} sx={{ color: '#FFF', bgcolor: '#374B5C', borderRadius: '6px 6px 0px 0px', boxShadow: 2 }}>
                                <Typography ml={1} fontWeight={400} fontSize={{ xs: '1rem', sm: '1.1rem', md: '1.25rem' }}>Points Lists</Typography>
                            </Box>
                        </Grid>
                        <QueryResult loading={loading} error={error} data={data}>
                            <PointListItems lists={pointsLists} pointListMenuItem={pointListMenuItem} setPointListMenuItem={setPointListMenuItem} />
                        </QueryResult>
                    </Box>
                </Box>
            </Paper>
        );
    }
    else {
        return (
            <Box mt={1}>
                <Paper elevation={1} sx={{ mx: 1, my: 1 }}>
                    <Autocomplete
                        id='point_lists'
                        options={pointsLists}
                        getOptionLabel={option => option.name}
                        defaultValue={pointsLists[0] ?? null}
                        onChange={(e, newPointList) => {
                            setPointListMenuItem(newPointList);
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant='outlined'
                                color='logoBlue'
                                size='small'
                                placeholder='Choose points list...'
                                autoComplete='off'
                            />
                        )}
                    />
                </Paper>
            </Box>
        );
    }
};