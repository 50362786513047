import React, { useState, useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";

import ClubViewEventListEntries from "./ClubViewEventListEntries";
import EntryLaneHeaders from "../../../../../../components/layout/EntryLaneHeaders";
import { getDateTime, getEventLevel } from "../../../../../../components/utils";

export default function ClubViewEventList({
  event,
  setSelectedCompetitor,
  showAge,
  setView,
}) {
  const [eventLevel, setEventLevel] = useState(0);

  useEffect(() => {
    if (event) {
      setEventLevel(getEventLevel(event?.event_competition_level));
    }
  }, [event]);

  return (
    <Grid
      container
      mt={1}
      sx={{
        borderRadius: 1,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        bgcolor: "#E4E7EC",
        boxShadow: 1,
      }}
    >
      <Grid
        container
        item
        justifyContent="flex-start"
        m="4px"
        //TODO: navigation to event
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="auto"
          height="2.8rem"
          width="2.8rem"
          sx={{
            bgcolor: "#374B5C",
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize="1rem" color="#FFF">
              {event?.number}
            </Typography>
            <Typography fontSize="0.75rem" lineHeight={1} color="#FFF">
              event
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography mx={1} fontWeight={500} fontSize="1.1rem">
            {event?.name}
          </Typography>
          {/* <Typography mx={1} fontWeight='300' fontSize='0.8rem'>Estimated session start time: {getDateTime(event?.rounds[0]?.time_program_entries[0]?.competition_session?.start_time)}</Typography> */}
          <Typography mx={1} fontWeight="300" fontSize="0.8rem">
            Session{" "}
            {
              event?.rounds[0]?.time_program_entries[0]?.competition_session
                ?.number
            }
          </Typography>
        </Box>
      </Grid>
      <Grid container item xs={12}>
        <EntryLaneHeaders
          eventLevel={event?.event_competition_level}
          showAge={showAge}
        />
        {event?.entries.map((entry, index) => (
          <ClubViewEventListEntries
            key={entry?.id}
            entry={entry}
            index={index}
            eventType={event?.event_type}
            setSelectedCompetitor={setSelectedCompetitor}
            showAge={showAge}
            eventLevel={event?.event_competition_level}
            setView={setView}
          />
        ))}
      </Grid>
    </Grid>
  );
}
