/* 
    competition_level === 0: show rank
    competition_level > 0: don't show rank

    event_competition_level === 0: show rank
    event_competition_level === 1: show rank
    event_competition_level > 1: don't show rank

    therefore, if returning competition_level, add 1 to the value to able to use if (eventLevel > 1) don't show rank
*/

//@ts-ignore

export default function getEventLevel(eventLevel: number | undefined){
    const localStorageCompetitionLevel: string = JSON.parse(localStorage.getItem('competition-level') || "0")
    if(!eventLevel) return 0;
    if(eventLevel === 0 && !isNaN(Number(localStorageCompetitionLevel))) return Number(localStorageCompetitionLevel) + 1;
    return eventLevel;
}