import React, { useContext, useEffect, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";

import { BsFillPlayBtnFill } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ScreenContext } from "../../../context/ScreenContext";

import SuperliveLogo from "../../../components/svg/SuperliveLogo";

export default function SuperliveView({ selectedSession }) {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(undefined);
  const params = useParams();
  const { setScreen } = useContext(ScreenContext);
  const location = useLocation();
  useEffect(() => {
    setOffset(location?.state?.heatOffset);
  }, []);

  return (
    <Grid container display="flex" sx={{ bgcolor: "" }}>
      <Grid
        container
        item
        alignItems="center"
        my="auto"
        width="100%"
        gap={1}
        py={1}
        sx={{
          color: "#FFF",
          bgcolor: "#374B5C",
          borderRadius: "6px 6px 0px 0px",
          boxShadow: 2,
        }}
      >
        <Box
          ml={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ bgcolor: "" }}
        >
          {/* <BsFillPlayBtnFill size='1rem' className='react-icons' /> */}
          <SuperliveLogo height={25} width={25} fill="white" />
        </Box>
        <Typography
          fontWeight={400}
          fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.25rem" }}
        >
          Superlive
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        height={24}
        marginBottom={2}
        alignItems="center"
        sx={{ bgcolor: "#E4E7EC" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          ml="32px"
          sx={{ bgcolor: "" }}
        >
          <Typography fontSize="0.7rem" color="#667085">
            Superlive from Session {selectedSession?.number}
          </Typography>
        </Box>
      </Grid>
      <Grid container item justifyContent="center" sx={{ bgcolor: "" }}>
        <Box marginBottom={2} sx={{ bgcolor: "" }}>
          {selectedSession?.superlive_seo_link ? (
            <iframe
              src={
                offset
                  ? selectedSession?.superlive_seo_link + `?offset=${offset}`
                  : selectedSession?.superlive_seo_link
              }
              allowFullScreen
              scrolling="no"
              width={800}
              height={450}
              title="Swimify Live Video Stream"
              style={{ border: "none", maxWidth: "100%", overflow: "hidden" }}
            ></iframe>
          ) : (
            <Box>
              <Typography>
                No livestream available from selected session
              </Typography>
              <Typography
                fontSize="0.8rem"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/competitions/${params?.competitionName}/events`);
                  setScreen("events");
                }}
              >
                Go to Event View
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
