import React, { useContext, useEffect, useMemo, useState } from "react";
import useScoreboardCurrentHeat, {
  CurrentHeatSelection,
} from "../hooks/useScoreboardCurrentHeat";
import useCompetitionId from "../hooks/useCompetitionId";
import useCalculateSplits from "../hooks/useCalculateSplits";
import { SeoContext } from "./SeoContext";
import { Splits } from "../views/competition/scoreboard/ScoreboardView";
import { ScreenContext } from "./ScreenContext";
import { CurrentHeatContext } from "./CurrentHeatContext";
import ReactGA from "react-ga4";

type ScoreboardContextProps = {
  children: React.ReactNode;
  selectedCurrentHeat: CurrentHeatSelection;
  showAge: boolean | undefined;
  updateCurrentHeatSelection: (
    selectedCurrentHeat: CurrentHeatSelection
  ) => void;
};

type ScoreboardContextValue = {
  displayHeat: Heat | undefined;
  showAge: boolean | undefined;
  lowestSplits: Splits;
  trySplits: (splits: Splits) => void;
  isActive: boolean;
  selectedCurrentHeat: CurrentHeatSelection;
  selectedCurrentHeatChanged: boolean;
  useCurrent_HeatLoading: boolean;
  isFirstRender: boolean;
  aboutToUpdate: boolean;
  updateCurrentHeatSelection: React.Dispatch<
    React.SetStateAction<CurrentHeatSelection>
  >;
};
export const ScoreboardContext = React.createContext<ScoreboardContextValue>(
  {} as ScoreboardContextValue
);

const ScoreboardContextWrapper = ({
  children,
  selectedCurrentHeat,
  showAge,
  updateCurrentHeatSelection,
}: ScoreboardContextProps) => {
  const { competitionSEO } = useContext(SeoContext);
  const { aboutToUpdate } = useContext(CurrentHeatContext);
  const { screen } = useContext(ScreenContext);

  const maxSplit: Splits = {
    "50": Number.MAX_SAFE_INTEGER,
    "100": Number.MAX_SAFE_INTEGER,
    "200": Number.MAX_SAFE_INTEGER,
  };

  const [lowestSplits, setLowestSplits] = useState<Splits>(maxSplit);
  const { trySplits, resetSplits, retryLastSplits } = useCalculateSplits({
    lowestSplits: lowestSplits,
    setLowestSplits: setLowestSplits,
  });
  const { competitionId } = useCompetitionId(competitionSEO);

  const {
    loading: useCurrent_HeatLoading,
    heat,
    selectedCurrentHeatChanged,
    setSelectedCurrentHeat,
    isActive,
  } = useScoreboardCurrentHeat(
    competitionId,
    resetSplits,
    retryLastSplits,
    "scoreboard" as View
  );

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [displayHeat, setDisplayHeat] = useState<Heat | undefined>(undefined);

  useEffect(() => {
    ReactGA.set({ page_title: `${screen} - ${selectedCurrentHeat}` });
    if (!isFirstRender) {
      ReactGA.send("pageview");
    }
    setIsFirstRender(false);
  }, [screen, selectedCurrentHeat, selectedCurrentHeatChanged]);

  useMemo(() => {
    setSelectedCurrentHeat(selectedCurrentHeat);
  }, [selectedCurrentHeat]);

  useEffect(() => {
    if (aboutToUpdate === false) {
      setTimeout(() => {
        document
          .getElementById("live-heat-box")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [screen, aboutToUpdate]);

  useEffect(() => {
    if (!useCurrent_HeatLoading && !aboutToUpdate) {
      setDisplayHeat(heat);
    }
  }, [heat, aboutToUpdate, useCurrent_HeatLoading]);
  return (
    <ScoreboardContext.Provider
      value={
        {
          displayHeat,
          showAge,
          lowestSplits,
          trySplits,
          isActive,
          selectedCurrentHeat,
          selectedCurrentHeatChanged,
          useCurrent_HeatLoading,
          isFirstRender,
          aboutToUpdate,
          updateCurrentHeatSelection,
        } as ScoreboardContextValue
      }
    >
      {children}
    </ScoreboardContext.Provider>
  );
};

export default ScoreboardContextWrapper;
