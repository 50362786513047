import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import {
  heatTimeDisplay,
  heatRankDisplay,
  getEventLevel,
} from "../../../../../components/utils";
import { ScreenContext } from "../../../../../context/ScreenContext";
import { StatusBadge } from "../../../../../components/layout";
import { determineParaCode } from "../../../../../components/utils/determineParaCodes";
import { LiveContext } from "../../../../../context/LiveContext";

export default function SwimmerHeatCard({
  lane,
  index,
  entry,
  competitor,
}: {
  lane: Lane;
  index: number;
  entry?: any;
  competitor?: any;
}) {
  const { setScreen } = useContext(ScreenContext);
  const navigate = useNavigate();
  const params = useParams();
  const { currentHeatObj } = useContext(LiveContext);
  const [isCurrentHeat, setIsCurrentHeat] = useState<boolean>(false);

  const [eventLevel, setEventLevel] = useState<number>(0);

  /* CHECK AND SET COMPETITION-/EVENT LEVEL (SHOW/DON'T SHOW RANK) */
  useEffect(() => {
    setEventLevel(
      getEventLevel(lane?.heat?.round?.event?.event_competition_level)
    );
    setIsCurrentHeat(currentHeatObj?.heat?.id === lane?.heat?.id);
  }, [lane, currentHeatObj]);

  const eventType = entry?.event?.event_type;

  return (
    <Grid
      container
      onClick={() => {
        navigate(
          `../competitions/${params.competitionName}/events/heats/${lane?.heat?.time_program_entry?.competition_session?.oid}/${lane?.heat?.time_program_entry?.oid}`
        );
        setScreen("events");
      }}
      sx={{
        borderRadius: 1,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        bgcolor: "#E4E7EC",
        boxShadow: 1,
        marginBottom: 1,
        cursor: "pointer",
      }}
    >
      <Grid container item xs={12}>
        <Grid container item xs={10} alignItems="center" py={0.5} gap={1}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="40px"
            width="40px"
            ml={0.5}
            sx={{
              bgcolor: "#374B5C",
              borderRadius: 2,
            }}
          >
            <Typography mb={-0.125} color="#FFF">
              {lane?.heat?.round?.event?.number}
            </Typography>
            <Typography
              mt={-0.25}
              lineHeight={1}
              fontSize="0.6rem"
              color="#FFF"
            >
              event
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography fontWeight={500}>
              {lane?.heat?.time_program_entry?.name}
            </Typography>
            {lane.heat && startTime(lane?.heat)}
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box mr="4px">
            {lane?.heat?.status === 2 && isCurrentHeat ? (
              <StatusBadge status={"LIVE"} />
            ) : (
              lane?.heat?.status === 3 && <StatusBadge status={"Official"} />
            )}
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Box display="flex" width={32} ml="6px" sx={{ bgcolor: "" }}>
            <Typography fontSize="0.7rem" color="#667085">
              Heat
            </Typography>
          </Box>
          <Box display="flex" width={32} ml="8px" sx={{ bgcolor: "" }}>
            <Typography fontSize="0.7rem" color="#667085">
              Lane
            </Typography>
          </Box>
          <Box
            display="flex"
            width="calc(100% - 328px)"
            ml="6px"
            sx={{ bgcolor: "" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              Heat Name
            </Typography>
          </Box>
          <Box display="flex" width={64} ml="8px" sx={{ bgcolor: "" }}>
            <Typography
              fontSize="0.7rem"
              color="#667085"
              sx={{ display: eventLevel > 1 ? "none" : "inherit" }}
            >
              Heat Rank
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={64}
            ml="8px"
            sx={{ bgcolor: "" }}
          >
            <Typography
              fontSize="0.7rem"
              color="#667085"
              sx={{ display: eventLevel > 1 ? "none" : "inherit" }}
            >
              Total Rank
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={84}
            ml="8px"
            mr="8px"
            sx={{ bgcolor: "" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              {lane?.heat?.status !== 0 && lane?.result_text != ""
                ? "Result"
                : "Entry Time"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={{ bgcolor: "#FFF" }}>
        <Grid container item alignItems="center" py={0.5}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="32px"
            width="32px"
            ml={0.5}
            sx={{
              bgcolor: "#F4C647",
              borderRadius: 2,
            }}
          >
            <Typography lineHeight={1}>{lane?.heat?.number}</Typography>
          </Box>
          <Box
            display="flex"
            ml="8px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="32px"
            width="32px"
            sx={{
              bgcolor: "#E4E7EC",
              borderRadius: 2,
            }}
          >
            <Typography lineHeight={1}>{lane?.number}</Typography>
          </Box>
          <Box
            display="flex"
            ml="8px"
            width="calc(100% - 328px)"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ bgcolor: "" }}
          >
            <Typography mb={-0.25} fontSize="0.9rem" lineHeight={1}>
              {lane?.heat?.name}
            </Typography>
          </Box>
          <Box
            display="flex"
            ml="8px"
            width={52}
            justifyContent="flex-end"
            alignItems="center"
            sx={{ bgcolor: "" }}
          >
            {lane?.heat?.status &&
              lane?.heat_rank &&
              heatRankDisplay(lane?.heat?.status, lane, lane?.heat_rank) && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="24px"
                  width="24px"
                  sx={{
                    bgcolor: "#374B5C",
                    borderRadius: 1,
                    display: eventLevel > 1 ? "none" : "inherit",
                  }}
                >
                  <Typography color="#FFF">
                    {heatRankDisplay(lane?.heat?.status, lane, lane?.heat_rank)}
                  </Typography>
                </Box>
              )}
          </Box>
          <Box
            display="flex"
            ml="8px"
            width={76}
            justifyContent="flex-end"
            alignItems="center"
          >
            {lane?.heat?.status &&
              lane?.total_rank &&
              heatRankDisplay(lane?.heat?.status, lane, lane?.total_rank) && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="24px"
                  width="24px"
                  sx={{
                    bgcolor: "#374B5C",
                    borderRadius: 1,
                    display: eventLevel > 1 ? "none" : "inherit",
                  }}
                >
                  <Typography color="#FFF">
                    {heatRankDisplay(
                      lane?.heat?.status,
                      lane,
                      lane?.total_rank
                    )}
                  </Typography>
                </Box>
              )}
          </Box>
          <Box
            display="flex"
            ml="8px"
            mr="4px"
            width={88}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box display="flex" justifyContent="flex-start">
              {eventType &&
                competitor &&
                determineParaCode(eventType, competitor) && (
                  <Typography
                    lineHeight={1}
                    fontSize={"0.7em"}
                    mt={"2px"}
                    mr={"8px"}
                  >
                    {determineParaCode(eventType, competitor)}
                  </Typography>
                )}
            </Box>
            <Typography fontWeight={500}>
              {lane?.heat?.status === 0
                ? lane?.entry_time_text
                : lane?.heat?.status &&
                  heatTimeDisplay(lane?.heat?.status, lane)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

const startTime = (heat: Heat) => {
  if (heat.start_time && heat.start_time !== "0001-01-01T00:00:00+00:00") {
    const time = new Date(heat?.start_time);
    return (
      <Typography fontSize="0.8rem">
        {`Started at: ${time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`}
      </Typography>
    );
  } else if (heat.estimated_start_time) {
    const time = new Date(heat?.estimated_start_time);
    return (
      <Typography fontSize="0.8rem">
        {`Estimated start time: ${time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`}
      </Typography>
    );
  }
};
