import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Typography,
    Box
} from '@mui/material';

export default function NoCompetitionsLink({ title }) {

    const navigate = useNavigate();

    return (
        <Box sx={{ background: 'linear-gradient(#F9FAFB, #FCFCFD, #F9FAFB)' }}>
            <Box display='flex' flexDirection='column' justifyContent='center' my='auto' mx={2} height={{ xs: 36, md: 44 }} fontSize='0.75rem' >
                <Typography fontSize='0.85rem'>{title}</Typography>
                <Typography fontSize='0.75rem' fontStyle='italic' color='inherit'
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => navigate('/competitions')}
                >Click to view All Competitions</Typography>
            </Box>
        </Box>
    );
}
