export const determineParaCode = (eventType: number, competitor: Competitor): string | undefined => {
    switch (eventType){
        case 1001:
            return competitor?.is_para ? competitor?.para_class_free : undefined;
        case 1002:
            return competitor?.is_para ? competitor.para_class_breast : undefined;
        case 1003:
            return competitor?.is_para ? competitor.para_class_free : undefined;
        case 1004:
            return competitor?.is_para ? competitor.para_class_free : undefined;
        case 1005:
            return competitor?.is_para ? competitor.para_class_medley : undefined;
        default:
            break;
    }
}