import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";

import { BsPeopleFill } from "react-icons/bs";
import { FlagComponent } from "../../../../components/layout";
import { competitorNavigationString } from "../../../../components/utils";
import { CountryCode } from "../../../../components/layout/FlagComponent";

export default function FinaResultsClubCard({
  club,
  rank,
  finaPoints,
  index,
}: {
  club: Club;
  rank: number;
  finaPoints: number;
  index: number;
}) {
  const navigate = useNavigate();
  const params = useParams();

  const [imgLoaded, setImgLoaded] = useState(true);

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        mt={0}
        py={0.5}
        onClick={() => {
          navigate(
            competitorNavigationString(params.competitionName, club.oid)
          );
        }}
        //@ts-ignore
        sx={{
          bgcolor: "#FFF",
          cursor: "pointer",
          borderTopStyle: index !== 0 && "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
        }}
      >
        <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="auto"
            height="32px"
            width="32px"
            sx={{
              bgcolor: "#F4C647",
              borderRadius: 2,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography lineHeight={1} fontSize="1rem">
                {rank}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
          {club && (
            <Box
              component="img"
              height="32px"
              width="32px"
              src={`${
                process.env.REACT_APP_CDN_URL
              }club_logos/${club?.country_code?.toLowerCase()}/32/${club?.short_name?.toLowerCase()}.png`}
              onError={() => setImgLoaded(false)}
              sx={{ display: imgLoaded ? "inline" : "none" }}
              alt={`${club?.name} logo`}
            />
          )}
          {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
          {!imgLoaded && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="32px"
              width="32px"
              sx={{ bgcolor: "#374B5C", borderRadius: 2 }}
            >
              <BsPeopleFill color="#FFF" size="28px" />
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          width={16}
          height={12}
          ml="6px"
          mb="2px"
          sx={{ boxShadow: 2, borderRadius: 1, bgcolor: "" }}
        >
          <FlagComponent
            countryCode={club?.country_code as CountryCode}
            size="s"
          />
        </Box>
        <Box display="flex" width="calc(100% - 198px)" ml="8px">
          <Typography lineHeight={1} fontWeight={500}>
            {club?.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={72}
          ml="8px"
          mr="8px"
          sx={{ bgcolor: "" }}
        >
          <Typography fontWeight={500}>{finaPoints}</Typography>
        </Box>
      </Grid>
    </>
  );
}
