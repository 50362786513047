import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, Typography, Divider } from "@mui/material";

import { BsCalendar2EventFill, BsFillPlayBtnFill } from "react-icons/bs";

import TimeProgramEntries from "./TimeProgramEntries";
import { getStartTime } from "../../../../components/utils";

import SuperliveLogo from "../../../../components/svg/SuperliveLogo";

export default function SessionView({
  competitionId,
  session,
  time_program_entries,
  setEventId,
  setSummaryTypes,
  selectedTpe,
  /* setTimeProgramEntry, */ setSelectedTpe,
  time_programId,
  setNewTpe,
  setRefreshTpeView
}) {
  const { number } = session;
  const sessionSuperlive = session?.superlive_seo_link;

  const navigate = useNavigate();
  const params = useParams();

  const getWeekday = (startTime, locale) => {
    const testDate = new Date(startTime);
    if (testDate instanceof Date && !isNaN(testDate)) {
      const date = new Date(startTime);
      return date.toLocaleDateString(locale, { weekday: "long" });
    }
  };

  return (
    <Box sx={{ bgcolor: "" }}>
      <Grid
        container
        display="flex"
        py={1}
        sx={{
          color: "#FFF",
          bgcolor: "#374B5C",
          borderRadius: "6px 6px 0px 0px",
          boxShadow: 2,
        }}
      >
        <Grid
          container
          item
          alignItems="center"
          my="auto"
          width="100%"
          gap={1}
          xs={sessionSuperlive ? 10 : 12}
        >
          <Box
            ml={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ bgcolor: "" }}
          >
            <BsCalendar2EventFill size="1rem" className="react-icons" />
          </Box>
          <Typography
            fontWeight={400}
            fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.25rem" }}
          >
            Session {session?.number}
          </Typography>
          <Divider orientation="vertical" color="#FFF" flexItem />
          <Typography
            fontWeight={400}
            mt={0.25}
            fontSize={{ xs: "0.8rem", sm: "0.9rem", md: "1rem" }}
          >
            {getWeekday(session?.start_time, "en-EN")}
          </Typography>
          <Typography
            fontWeight={400}
            mt={0.25}
            fontSize={{ xs: "0.8rem", sm: "0.9rem", md: "1rem" }}
          >
            {getStartTime(session?.start_time)}
          </Typography>
        </Grid>
        {sessionSuperlive && (
          <Grid
            container
            item
            xs={2}
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                `/competitions/${params?.competitionName}/superlive/${session?.oid}`
              );
            }}
          >
            {/* <BsFillPlayBtnFill size='1rem' className='react-icons' /> */}
            <SuperliveLogo height={25} width={25} fill="white" />
          </Grid>
        )}
      </Grid>
      <TimeProgramEntries
        setNewTpe={setNewTpe}
        competitionId={competitionId}
        session={session}
        entries={time_program_entries}
        selectedTpe={selectedTpe}
        setSelectedTpe={setSelectedTpe}
        getStartTime={getStartTime}
        number={number}
        setEventId={setEventId}
        time_programId={time_programId}
        setSummaryTypes={setSummaryTypes}
        setRefreshTpeView={setRefreshTpeView}
      />
    </Box>
  );
}
