import { useState, useRef, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClubList from './components/ClubList';
import CompetitorCard from './components/CompetitorCard';
import ClubGrid from './components/ClubGrid';
import CompetitorHeaders from './components/CompetitorHeaders';
import QueryResult from '../../../components/containers/QueryResult';
import ClubView from './club/Club';
import CompetitionSwimmerDetails from './swimmer_details/SwimmerDetails';
import { GET_COMPETITION_CLUBS } from '../../../utils/graphql/queries';
import { GET_CLUBVIEW_CLUB } from '../../../utils/graphql/queries';
import { GET_SEARCHED_COMPETITORS } from '../../../utils/graphql/queries';
import { GET_COMPETITOR } from '../../../utils/graphql/queries';
import { GET_COMPETITOR_COUNT } from '../../../utils/graphql/queries';

export default function CompetitorView({ competitionId, showAge }: { competitionId: string, showAge: boolean | undefined }) {

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const paths = location.pathname.split('/').filter(x => x);
    const decodedPaths = paths.map((path) => decodeURI(path));
    const [view, setView] = useState<number>(0);
    const [club, setClub] = useState<Club | null>(null);
    const [clubOid, setClubOid] = useState<string | null>(null);
    const [clubList, setClubList] = useState<Club[]>([]);
    const [gridProps, setGridProps] = useState({});
    const [competitors, setCompetitors] = useState<Competitor[]>([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState<Competitor | null>(null);
    const [competitorOid, setCompetitorOid] = useState<string | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');
    const [countVisible, setCountVisible] = useState<boolean>(false)

    const [shouldGetClubViewClubQuery, setShouldGetClubViewClubQuery] = useState<boolean>(false);

    const textInput = useRef<string>('');
    
    const clearSearchInput = () => {
        textInput.current = '';
    };

    const [getCompetitionClubs, { loading: clubsLoading, error: clubsError, data: clubsData }] = useLazyQuery<Record<'competitions_by_pk', Competition>>(GET_COMPETITION_CLUBS, {
        variables: { competitionId },
        onCompleted: data => {
            setClubList(data?.competitions_by_pk?.clubs || []);
        }
    });

    const [getClubViewClub, { loading: clubLoading, error: clubError, data: clubData }] = useLazyQuery<{ competitions_by_pk: Competition, point_list_row: PointListRow }>(GET_CLUBVIEW_CLUB, {
        variables: {
            skip: !clubOid,
            competitionId: competitionId,
            clubOid: clubOid,
        },
        onCompleted: data => {
            const clubs = data?.competitions_by_pk?.clubs;
            if (clubs && clubs.length < 1) {
                navigate(`../competitions/${params.competitionName}/swimmers/clubs`);
                setView(0);
            } else {
                setClub(clubs ? clubs[0] : null);
                setCompetitors(clubs ? clubs[0]?.competitors ? clubs[0]?.competitors : [] : []);
            }
        },
        onError: error => {
            console.log('ERROR: ', error)
        },
    });

    const { data: competitorCountData } = useQuery(GET_COMPETITOR_COUNT, {
        variables: {
            competitionId: competitionId,
        }
    })

    const [getSearchedCompetitor, { loading: searchLoading, error: searchError, data: searchData }] = useLazyQuery<Record<'competitor', Competitor[]>>(GET_SEARCHED_COMPETITORS, {
        variables: {
            competitionId: competitionId,
            search: `%${searchValue}%`
        },
    });

    //TODO: possible to slim down further?
    const [getCompetitor, { loading: competitorLoading, error: competitorError, data: competitorData }] = useLazyQuery<Record<'clubs', Club[]>>(GET_COMPETITOR, {
        variables: {
            competitionId: competitionId,
            clubOid: clubOid,
            competitorOid: competitorOid
        },
        onCompleted: data => {
            const club = data?.clubs[0];
            if (data?.clubs?.length < 1) {
                navigate(`../competitions/${params.competitionName}/swimmers/clubs`);
                setView(0);
            } else {
                setClub(club);
                setCompetitors(club?.all_competitors || []);
                setSelectedCompetitor(club && club?.competitors ? club.competitors[0] : null);
            }
        }
    });

    const numberOfCompetitors = competitorCountData?.competitor_aggregate?.aggregate?.count

    //TODO: separate update functions depending on first render or view change by user
    useEffect(() => {
        if (decodedPaths.length >= 5 && !isNaN(Number(decodedPaths[4])) && decodedPaths[5] === 'competitor' && !isNaN(Number(decodedPaths[6]))) {
            //console.log('INDIVIDUAL COMPETITOR');
            setClubOid(decodedPaths[4]);
            setCompetitorOid(decodedPaths[6]);
            setView(3);
            if (params.clubId && params.competitorId) {
                getCompetitor();
            }
        } else if (decodedPaths.length >= 5 && !isNaN(Number(decodedPaths[4]))) {
            //console.log('INDIVIDUAL CLUB');
            setView(1);
            setClubOid(decodedPaths[4]);

            // Workaround for getClubViewClub query since skip doesn't seem to work there
            setShouldGetClubViewClubQuery(true)
        } else if (decodedPaths.length === 4 && decodedPaths[3] === 'search') {
            //console.log('SEARCH VIEW');
            setView(2);
            getCompetitionClubs();
            getSearchedCompetitor();
        } else {
            //console.log('ALL CLUBS');
            navigate(`../competitions/${params.competitionName}/swimmers/clubs`);
            getCompetitionClubs();
            setView(0);
        }
    }, [view, selectedCompetitor]);

    useMemo(()=> {
        if(clubOid && shouldGetClubViewClubQuery) {
            getClubViewClub()
            setShouldGetClubViewClubQuery(false)
        }
    }, [clubOid])

    useEffect(() => {
        if (view === 2) {
            setGridProps({
                borderRadius: 1,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: '#D0D5DD',
                boxShadow: 1
            });
        } else {
            setGridProps({});
        }
    }, [club, view]);

    const title = () => {
        if (club?.name) return club?.name;
        if (view === 2) return `Search: ${searchValue}`;
        else return (
            <Box display="flex" gap="2" >
                <Box onClick={e => {
                    countVisible ? setCountVisible(false) : setCountVisible(true)
                    }}>
                    All Clubs
                </Box>
                {countVisible && (<Box sx={{paddingTop: 1.5, fontSize: "medium", fontWeight: 400, display: "flex", alignSelf: "center", marginLeft: "8px"}}>
                    Competitor count: {numberOfCompetitors} 
                </Box>)}
                
            </Box>
        );
    };

    const searchBox = () => {
        const searchSlug = `../competitions/${params.competitionName}/swimmers/search`;
        // handle search input change
        const handleOnChange = (e: any) => {
            setInputValue(e.target.value);
        };
        // handle click search button
        const handleClick = () => {
            if (inputValue !== '') {
                navigate(searchSlug);
                setSearchValue(inputValue);
                setClub(null);
                setSelectedCompetitor(null);
                setView(2);
            }
        };
        // handle press enter to search
        const onKeyPress = (e: any) => {
            if (e.key === 'Enter') {
                if (inputValue !== '') {
                    navigate(searchSlug);
                    setSearchValue(e.target.value);
                    setClub(null);
                    setSelectedCompetitor(null);
                    setView(2);
                    e.preventDefault();
                }
            }
        };

        return (
            <Box sx={{
                maxWidth: '500px',
                display: 'flex',
                justifyContent: 'flex-end',
                my: 'auto',
            }}>
                <TextField
                    id='search-input'
                    inputRef={textInput}
                    variant='outlined'
                    autoComplete='off'
                    placeholder='Search Swimmer...'
                    size='small'
                    //@ts-ignore
                    color='neutral'
                    onChange={handleOnChange}
                    onKeyPress={onKeyPress}
                    sx={{
                        mr: 1,
                        height: { xs: 28, sm: 32 },
                        '& input::placeholder': {
                            fontSize: { xs: '0.75rem', sm: '1rem' }
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon sx={{ maxHeight: { xs: 18, sm: 24 } }} />
                            </InputAdornment>
                        ),
                    }}
                >
                </TextField>
                <Button
                    variant='contained'
                    //@ts-ignore
                    color='logoBlue'
                    sx={{
                        my: 'auto',
                        height: { xs: 20, sm: 24, md: 28 },
                        width: { xs: 48, sm: 58, md: 88 },
                        fontSize: { xs: '0.5rem', sm: '0.7rem', md: '0.88rem' }
                    }}
                    onClick={() => handleClick()}
                >
                    SEARCH
                </Button>
            </Box>
        );
    };

    return (
        <>
            <Grid container sx={{ bgcolor: '' }}>
                <Grid item xs={12} md={4} sx={{ bgcolor: '' }}>
                    <Box width='100%' sx={{ bgcolor: '' }}>
                        <ClubList
                            view={view}
                            club={club}
                            setClub={setClub}
                            clubList={clubList}
                            competitors={competitors}
                            setCompetitors={setCompetitors}
                            selectedCompetitor={selectedCompetitor}
                            setSelectedCompetitor={setSelectedCompetitor}
                            setView={setView}
                            clearSearchInput={clearSearchInput}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} sx={{ bgcolor: '' }}>
                    <Box mx={1} mb={2} sx={{ bgcolor: '' }}>
                        <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ bgcolor: '' }}>
                            <Typography noWrap mx={1} py={{ xs: 1, md: 0 }} mt={0} fontWeight={700} fontSize={{ xs: '1rem', sm: '2rem' }}>{title()}</Typography>
                            {searchBox()}
                        </Box>
                        <Grid container sx={{
                            ...gridProps
                        }}>
                            {view === 0 && clubList?.length > 0 &&
                                <QueryResult error={clubsError} loading={clubsLoading} data={clubsData}>
                                    <ClubGrid clubList={clubList} setClub={setClub} setView={setView} setCompetitors={setCompetitors} />
                                </QueryResult>
                            }
                            {view === 1 && clubOid &&
                                <QueryResult error={clubError} loading={clubLoading} data={clubData}>
                                    <ClubView
                                        competitionId={competitionId}
                                        club={club}
                                        setClub={setClub}
                                        clubOid={clubOid}
                                        setCompetitors={setCompetitors}
                                        setSelectedCompetitor={setSelectedCompetitor}
                                        pointListRows={clubData?.point_list_row}
                                        showAge={showAge}
                                        setView={setView}
                                    />
                                </QueryResult>
                            }
                            {view === 2 &&
                                <>
                                    {searchData?.competitor.length !== 0 ? <CompetitorHeaders showAge={showAge} /> : null}
                                    <QueryResult loading={searchLoading} error={searchError} data={searchData}>
                                        {searchData?.competitor.map((competitor, index) => (
                                            <CompetitorCard
                                                key={competitor?.id}
                                                competitor={competitor}
                                                setCompetitors={setCompetitors}
                                                setSelectedCompetitor={setSelectedCompetitor}
                                                setClub={setClub}
                                                setView={setView}
                                                index={index}
                                                showAge={showAge}
                                            />
                                        ))}
                                    </QueryResult>
                                </>
                            }
                            {view === 2 && searchData?.competitor.length === 0 &&
                                <Box display='flex' justifyContent='center' mx={2} my={2} pt={4} pb={2} width='100%' sx={{ bgcolor: '' }}>
                                    <Box display='flex' flexDirection='column' alignItems='center' py={2} px={6} sx={{ boxShadow: 4 }}>
                                        <Typography fontSize='1.2rem' fontWeight={500}>No Search Results</Typography>
                                        <Typography>Try another searchterm</Typography>
                                    </Box>
                                </Box>
                            }
                            {view === 3 &&
                                <CompetitionSwimmerDetails
                                    competitionId={competitionId}
                                    swimmerId={competitorOid}
                                    showAge={showAge}
                                />
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}