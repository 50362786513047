import React from 'react';

import { Grid } from '@mui/material';

import ClubGridItem from './ClubGridItem';

export default function ClubGrid({ clubList, setClub, setView, setCompetitors }) {

    return (
        <Grid container item sx={{ bgcolor: '' }}>
            {clubList.map(club => (
                <ClubGridItem key={club.id} club={club} setClub={setClub} setView={setView} setCompetitors={setCompetitors} />
            ))}
        </Grid>
    )
}
