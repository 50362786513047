import React from 'react';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { BsHourglassSplit } from 'react-icons/bs';

export default function TimeProgramEntryIcon({ entry }) {
    if (entry?.type === 1) return entry?.round?.event?.number;
    if (entry?.type === 2) return <BsHourglassSplit size='1.2rem' />;
    if (entry?.type === 3) return <EmojiEventsIcon />;
    else return null;
}
