import { useContext } from "react";
import { useNavigate, Params } from "react-router-dom";

import { Box } from "@mui/material";

import { LiveContext } from "../../context/LiveContext";
import StatusBadge from "./StatusBadge";

export default function LiveBadge({
  entry,
  params,
  session,
}: {
  entry: Entry;
  params: Readonly<Params<string>>;
  session: CompetitionSession;
  currentHeatUpdateThreshold: number;
}): JSX.Element {
  const navigate = useNavigate();
  const currentTime = Date.now();

  const { currentHeatUpdateThreshold } = useContext(LiveContext);

  return (
    <>
      {currentHeatUpdateThreshold &&
      currentTime < currentHeatUpdateThreshold ? (
        <Box
          width="64px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          onClick={() =>
            navigate(
              `../competitions/${params?.competitionName}/events/${params?.eventView}/${session?.oid}/${entry?.oid}/live`,
              { state: { tpeCard: true } }
            )
          }
          role="button"
          tabIndex={0}
          aria-label={`Go to live view for ${params?.competitionName}`}
        >
          <StatusBadge status={"LIVE"} />
        </Box>
      ) : null}
    </>
  );
}
