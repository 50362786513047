import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";

import { BsPeopleFill } from "react-icons/bs";
import { FlagComponent } from "../../../../components/layout";
import { competitorNavigationString } from "../../../../components/utils";
import { CountryCode } from "../../../../components/layout/FlagComponent";

export default function InduvidualRankPoints({
  competitor,
  club,
  rank,
  clubPoints,
  index,
  showAge,
}: {
  competitor: Competitor;
  club: Club;
  rank: number;
  clubPoints: number;
  index: number;
  showAge: boolean;
}) {
  const navigate = useNavigate();
  const params = useParams();

  const [imgLoaded, setImgLoaded] = useState(true);

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        mt={0}
        py={0.5}
        onClick={() => {
          navigate(
            competitorNavigationString(
              params.competitionName,
              club?.oid,
              competitor?.oid
            )
          );
        }}
        //@ts-ignore
        sx={{
          bgcolor: "#FFF",
          cursor: "pointer",
          borderTopStyle: index !== 0 && "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
        }}
      >
        <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="auto"
            height="32px"
            width="32px"
            sx={{
              bgcolor: "#F4C647",
              borderRadius: 2,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography lineHeight={1} fontSize="1rem">
                {rank}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          width={16}
          height={12}
          ml="6px"
          mb="0.25px"
          sx={{ boxShadow: 2, borderRadius: 1, bgcolor: "" }}
        >
          <FlagComponent
            countryCode={competitor?.country_code as CountryCode}
            size="s"
          />
        </Box>
        <Box
          display="flex"
          ml="8px"
          width="calc(100% - 464px)"
          maxWidth="200px"
        >
          <Typography noWrap lineHeight={1} fontWeight={500}>
            {competitor?.full_name}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={40}
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          <Typography fontSize="0.9rem" lineHeight={1}>
            {showAge ? competitor?.age : competitor?.birthday?.slice(0, 4)}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          minWidth="248px"
          width="calc(100% - 416px)"
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          <Box
            component="img"
            height="32px"
            width="32px"
            src={`${
              process.env.REACT_APP_CDN_URL
            }club_logos/${club?.country_code?.toLowerCase()}/32/${club?.short_name?.toLowerCase()}.png`}
            onError={() => setImgLoaded(false)}
            sx={{ display: imgLoaded ? "inline" : "none" }}
            alt={`${club?.name} logo`}
          />
          {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
          {!imgLoaded && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="32px"
              width="32px"
              sx={{ bgcolor: "#374B5C", borderRadius: 2 }}
            >
              <BsPeopleFill color="#FFF" size="28px" />
            </Box>
          )}
          <Typography noWrap fontSize="0.9rem" ml="8px" lineHeight={1}>
            {club?.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={72}
          ml="8px"
          mr="8px"
          sx={{ bgcolor: "" }}
        >
          <Typography fontWeight={500}>{clubPoints}</Typography>
        </Box>
      </Grid>
    </>
  );
}
