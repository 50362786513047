import React from "react";
import ContentLoader from "react-content-loader";

import { Box, useMediaQuery } from "@mui/material";

export default function Loader() {
  const offset = useMediaQuery("(max-width:899px)") ? 10 : 16;
  const foregroundColor = "#F2F4F7";
  const backgroundColor = "#E4E7EC";

  return (
    <Box width={"100%"}>
      <Box height={{ xs: 36, md: 44 }} width={"100%"}>
        <ContentLoader
          speed={1}
          width={"100%"}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
        >
          <rect x="8" y={offset} rx="4" ry="4" width="56" height="16" />
          <rect x="72" y={offset} rx="4" ry="4" width="75%" height="16" />
        </ContentLoader>
      </Box>
      <Box height={{ xs: 36, md: 44 }}>
        <ContentLoader
          speed={1}
          width={"100%"}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
        >
          <rect x="8" y={offset} rx="4" ry="4" width="56" height="16" />
          <rect x="72" y={offset} rx="4" ry="4" width="75%" height="16" />
        </ContentLoader>
      </Box>
      <Box height={{ xs: 36, md: 44 }}>
        <ContentLoader
          speed={1}
          width={"100%"}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
        >
          <rect x="8" y={offset} rx="4" ry="4" width="56" height="16" />
          <rect x="72" y={offset} rx="4" ry="4" width="75%" height="16" />
        </ContentLoader>
      </Box>
    </Box>
  );
}
