import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Box, Typography, AppBar } from "@mui/material";
import {
  FlagComponent,
  Medals,
  StyledTabs,
  StyledSmallTab,
} from "../../../../components/layout";
import ClubLogo from "./components/ClubLogo";
import ClubViewEntries from "./components/entries/ClubViewEntries";
import ClubViewHeats from "./components/heats/ClubViewHeats";
import ClubViewSummary from "./components/summary/ClubViewSummary";
import { ScreenContext } from "../../../../context/ScreenContext";
import ReactGA from "react-ga4";

export default function ClubView({
  competitionId,
  clubOid,
  club,
  setClub,
  setCompetitors,
  setSelectedCompetitor,
  pointListRows,
  showAge,
  setView,
}) {
  const params = useParams();
  const navigate = useNavigate();

  const clubViews = ["entries", "heats", "summary"];
  const [clubView, setClubView] = useState(
    clubViews.indexOf(params?.clubView) !== (-1 || 2)
      ? clubViews.indexOf(params?.clubView)
      : 0
  );
  const clubViewSortOrders = ["competitors", "events"];
  const [clubViewSortOrder, setClubViewSortOrder] = useState(
    clubViewSortOrders.indexOf(params?.clubViewSortOrder) !== -1
      ? clubViewSortOrders.indexOf(params?.clubViewSortOrder)
      : 1
  );

  const { screen } = useContext(ScreenContext);
  useMemo(() => {
    ReactGA.set({ page_title: `${screen} - Clubs` });
  }, [screen]);

  const handleClubViewChange = (e, newValue) => {
    if (newValue === 2) {
      //INFO: "sort by" set to 1 for summary to prevent automatic "competitors" sort, until summary by competitor works
      setClubViewSortOrder(1);
    }
    setClubView(newValue);
    navigate(
      `../competitions/${params.competitionName}/swimmers/clubs/${club?.oid}/${clubViews[newValue]}/${clubViewSortOrders[clubViewSortOrder]}`
    );
  };

  const handleSortOrderChange = (e, newValue) => {
    setClubViewSortOrder(newValue);
    navigate(
      `../competitions/${params.competitionName}/swimmers/clubs/${club?.oid}/${clubViews[clubView]}/${clubViewSortOrders[newValue]}`
    );
  };

  const medalCounter = (pointLists) => {
    const totalMedals = [];
    const bronzeMedals = [];
    const silverMedals = [];
    const goldMedals = [];
    pointLists?.map((list) => {
      bronzeMedals.push(list.medal_bronze);
      silverMedals.push(list.medal_silver);
      goldMedals.push(list.medal_gold);
      totalMedals.push(list.medal_total);
    });
    const numberOfBronze = bronzeMedals.reduce((a, b) => a + b, 0);
    const numberOfSilver = silverMedals.reduce((a, b) => a + b, 0);
    const numberOfGold = goldMedals.reduce((a, b) => a + b, 0);
    const numberOfMedals = totalMedals.reduce((a, b) => a + b, 0);

    if (numberOfMedals === 0) return null;

    return (
      <Medals
        bronze={numberOfBronze}
        silver={numberOfSilver}
        gold={numberOfGold}
        total={numberOfMedals}
      />
    );
  };

  return (
    <Grid container>
      <Grid
        container
        item
        display="flex"
        justifyContent="flex-start"
        py={1}
        mb={1}
        sx={{
          borderRadius: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
          marginBottom: 1,
          boxShadow: 1,
          maxHeight: "135px",
        }}
      >
        {/* <QueryResult loading={loading} error={error} data={data}> */}
        {/* CLUB INFO (COMPETITORS, MEDALS, NATIONALITY) */}
        <Grid container item mx={1} sx={{ bgcolor: "" }}>
          <Box width="25%" maxWidth="116px">
            <ClubLogo club={club} />
          </Box>
          <Grid
            container
            item
            width="75%"
            ml="8px"
            flexDirection="column"
            justifyContent="center"
            sx={{ bgcolor: "" }}
          >
            <Box display="flex">
              <Typography>Competitors:</Typography>
              <Typography ml="4px" fontWeight={500}>
                {club?.competitors?.length}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                {medalCounter(pointListRows)}
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <FlagComponent
                display="flex"
                justifyContent="center"
                alignItems="center"
                countryCode={club?.country_code}
                size={"l"}
              />
              <Typography ml="4px" fontWeight={500}>
                {club?.country_name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* </QueryResult> */}
      </Grid>
      <Grid
        container
        item
        xs={12}
        py={0.5}
        gap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
          mb={0}
          display="flex"
          justifyContent="space-between"
          sx={{ bgcolor: "" }}
        >
          <AppBar
            position="static"
            color="logoBlue"
            sx={{
              borderRadius: "0px 0px 4px 4px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              bgcolor: "",
            }}
          >
            <Box display="flex" justifyContent="space-between" width="50%">
              <StyledTabs
                value={clubView}
                onChange={handleClubViewChange}
                sx={{ bgcolor: "" }}
              >
                <StyledSmallTab
                  label={tabLabel("Entries")}
                  onClick={() => setClubView(0)}
                  sx={{ height: "2.4rem", color: "#CCC" }}
                />
                <StyledSmallTab
                  label={tabLabel("Heats")}
                  onClick={() => setClubView(1)}
                  sx={{ height: "2.4rem", color: "#CCC" }}
                />
                {/* <StyledSmallTab
                  label={tabLabel("Summary")}
                  onClick={() => setClubView(2)}
                  sx={{ height: "2.4rem", color: "#CCC" }}
                /> */}
              </StyledTabs>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width="50%"
            >
              <Typography ml="4px">Sort by:</Typography>
              <StyledTabs
                value={clubViewSortOrder}
                onChange={handleSortOrderChange}
                sx={{ bgcolor: "", justifyContent: "flex-end" }}
              >
                <StyledSmallTab
                  disabled={clubView === 2 ? true : false}
                  label={tabLabel("Competitors")}
                  onClick={() => setClubViewSortOrder(0)}
                  sx={{ height: "2.4rem", color: "#CCC" }}
                />
                <StyledSmallTab
                  label={tabLabel("Events")}
                  onClick={() => setClubViewSortOrder(1)}
                  sx={{ height: "2.4rem", color: "#CCC" }}
                />
              </StyledTabs>
            </Box>
          </AppBar>
        </Grid>
      </Grid>
      {clubView === 0 && (
        <ClubViewEntries
          competitionId={competitionId}
          setView={setView}
          club={club}
          setSelectedCompetitor={setSelectedCompetitor}
          showAge={showAge}
          clubViewSortOrder={clubViewSortOrder}
        />
      )}
      {clubView === 1 && (
        <ClubViewHeats
          competitionId={competitionId}
          setView={setView}
          club={club}
          setSelectedCompetitor={setSelectedCompetitor}
          showAge={showAge}
          clubViewSortOrder={clubViewSortOrder}
        />
      )}
      {clubView === 2 && (
        <ClubViewSummary
          competitionId={competitionId}
          setView={setView}
          club={club}
          setSelectedCompetitor={setSelectedCompetitor}
          showAge={showAge}
          clubViewSortOrder={clubViewSortOrder}
        />
      )}
    </Grid>
  );
}

const tabLabel = (string) => {
  return (
    <Typography sx={{ fontSize: { xs: "0.6rem", sm: "1rem" } }}>
      {string}
    </Typography>
  );
};
