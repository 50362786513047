import React from "react";

import { Typography, Box, Grid } from "@mui/material";

export default function EntryLaneHeaders({ eventLevel, showAge }) {
  return (
    <Grid
      container
      item
      xs={12}
      height={24}
      alignItems="center"
      sx={{ backgroundColor: "#E4E7EC" }}
      role="rowheader"
      aria-label="Competition results header"
    >
      <Box
        display="flex"
        width={32}
        ml="8px"
        sx={{ display: eventLevel > 1 ? "none" : "inherit" }}
        aria-hidden={eventLevel > 1}
      >
        <Typography fontSize="0.7rem" color="#667085">
          Rank
        </Typography>
      </Box>
      <Box
        display="flex"
        width={eventLevel > 1 ? "calc(100% - 404px)" : "calc(100% - 444px)"}
        maxWidth="240px"
        ml="28px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          Competitor
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" width={40} ml="8px">
        <Typography
          fontSize="0.7rem"
          color="#667085"
          aria-label={showAge ? "Competitor age" : "Competitor birth year"}
        >
          {showAge ? "Age" : "Year"}
        </Typography>
      </Box>
      <Box
        display="flex"
        minWidth="174px"
        width={eventLevel > 1 ? "calc(100% - 470px)" : "calc(100% - 510px)"}
        ml="10px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          Club
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={42}
        ml="8px"
        mr="6px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          Time
        </Typography>
      </Box>
    </Grid>
  );
}
