import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Box,
    Divider,
    Grid,
} from '@mui/material';
import ButtonComponent from '../components/layout/ButtonComponent';
import MainTableLayout from '../components/layout/MainTableLayout';
import MetaDecorator from '../components/utils/MetaDecorator';
import Header from '../components/Header';

export default function Error() {

    const navigate = useNavigate();

    return (
        <>
            <MetaDecorator title='Swimify - 404' description='Swimify Error Page' />
            <Header />
            <MainTableLayout
                title={'Page Not Found'}
            >
                <Grid item xs={12}>
                    <Divider />
                    <Box mx={2} my={2}>
                        <Box mb={2} display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                            <Typography>That page can not be found</Typography>
                        </Box>
                        <Box display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                            <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap={2} maxWidth={'50%'}>
                                <ButtonComponent
                                    title='Home'
                                    variant='contained'
                                    onClick={() => navigate('/')}
                                    color='logoBlue'
                                />
                                <ButtonComponent
                                    title='All Competitions'
                                    variant='grey'
                                    onClick={() => navigate('/competitions')}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </MainTableLayout>
        </>
    );
}
