import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { Image } from '../../../../components/layout';

export default function ClubGridItem({ club, setClub, setView, setCompetitors }) {

    const params = useParams();
    const navigate = useNavigate();

    return (
        <Grid container item height='auto' my={1} width='calc(100% / 5)' flexDirection='column' alignItems='center' sx={{}}>
            <Grid container item justifyContent='center' alignItems='center' width='calc(100% - 16px)'
                sx={{ cursor: 'pointer', bgcolor: '#FFF', borderRadius: 1, boxShadow: 2 }}
                onClick={() => {
                    navigate(`../competitions/${params.competitionName}/swimmers/clubs/${club?.oid}`);
                    setClub(club);
                    setCompetitors(club?.competitors);
                    setView(1);
                }}>
                <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
                    <Box sx={{ width: 'calc(100% - 32px)', aspectRatio: '1 / 1', mt: '16px', display: 'grid' }}>
                        <Image
                            src={`${process.env.REACT_APP_CDN_URL}club_logos/${club?.country_code.toLowerCase()}/${club?.short_name.toLowerCase()}.png`}
                            alternateSrc='/icons/people/generic_club_icon.png'
                            placeholder='/icons/people/generic_club_icon_small.png'
                            height={100}
                            width={100}
                        />
                    </Box>
                </Box>
                <Box display='flex' width='90%' maxWidth='90%' justifyContent='center' alignItems='center'>
                    <Typography noWrap fontSize='0.7rem'>{club?.name}</Typography>
                </Box>
            </Grid>
        </Grid>
    );
}
