import React from 'react';

import {
    Box
} from '@mui/material';
import { Image } from '../../../../../components/layout';

export default function ClubLogo({ club }) {
    return (
        <Box width='100%' height='auto' sx={{ bgcolor: '', aspectRatio: '1 / 1', display: 'grid' }}>
            <Image
                src={`${process.env.REACT_APP_CDN_URL}club_logos/${club?.country_code.toLowerCase()}/${club?.short_name.toLowerCase()}.png`}
                alternateSrc='/icons/people/generic_club_icon.png'
                placeholder='/icons/people/generic_club_icon_small.png'
                height={100}
                width={100}
            />
        </Box>
    );
}
