import { useState } from 'react';
import {
    Box,
    TextField,
    Button,
    InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchComponent({ setSearchValue, placeholder, maxWidth, width }) {

    const [inputValue, setInputValue] = useState('');

    // handle search input change
    const handleOnChange = (e) => {
        setInputValue(e.target.value);
    }

    // handle click search button
    const handleClick = () => {
        setSearchValue(inputValue);
    }

    // handle press enter to search
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(e.target.value);
            e.preventDefault();
        }
    }

    return (
        <Box sx={{
            maxWidth: maxWidth,
            width: width,
            display: 'flex',
            my: 'auto',
            ml: '12px'
        }}>
            <TextField
                id='search-input'
                variant='outlined'
                autoComplete='off'
                placeholder={placeholder}
                size='small'
                fullWidth
                //error
                color='neutral'
                onChange={handleOnChange}
                onKeyPress={onKeyPress}
                sx={{
                    mr: 1,
                    height: { xs: 28, sm: 32 },
                    '& input::placeholder': {
                        fontSize: { xs: '0.75rem', sm: '1rem' }
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ maxHeight: { xs: 18, sm: 24 } }} />
                        </InputAdornment>
                    ),
                }}
            >
            </TextField>
            <Button
                variant='contained'
                color='logoBlue'
                sx={{
                    my: 'auto',
                    height: { xs: 20, sm: 24, md: 32 },
                    width: { xs: 48, sm: 58, md: 88 },
                    fontSize: { xs: '0.5rem', sm: '0.7rem', md: '0.88rem' }
                }}
                onClick={() => handleClick()}
            >
                SEARCH
            </Button>
        </Box>
    )
}