import React from 'react';

import {
    Box,
    Grid,
    Paper,
    Typography,
    TextField,
    Autocomplete,
    useMediaQuery
} from '@mui/material';

import ClubListItem from './ClubListItem';
import AllClubsButton from './AllClubsButton';
import ClubListCompetitorCard from './ClubListCompetitorCard';

export default function ClubList({ view, club, setClub, clubList, competitors, setCompetitors, selectedCompetitor, setSelectedCompetitor, setView, clearSearchInput }) {

    const desktop = useMediaQuery('(min-width:900px)');

    if (desktop) {
        return (
            <Paper elevation={3} sx={{}}>
                <Box mb={1} sx={{ bgcolor: '' }}>
                    <Box sx={{ bgcolor: '' }}>
                        <Grid container my={0} display='flex'>
                            <Box display='flex' justifyContent='flex-start' alignItems='center' my='auto' width='100%' gap={1} py={1} sx={{ color: '#FFF', bgcolor: '#374B5C', borderRadius: '6px 6px 0px 0px', boxShadow: 2 }}>
                                <Typography noWrap ml={1} fontWeight={400} fontSize={{ xs: '1rem', sm: '1.1rem', md: '1.25rem' }}>{club ? `Clubs / ${club?.name}` : 'Clubs'}</Typography>
                            </Box>
                        </Grid>
                        <Box>
                            {clubList && <AllClubsButton club={club} setClub={setClub} setCompetitors={setCompetitors} setSelectedCompetitor={setSelectedCompetitor} setView={setView} clearSearchInput={clearSearchInput} />}
                            {club && <ClubListItem clubListItem={club} competitors={competitors} setCompetitors={setCompetitors} setSelectedCompetitor={setSelectedCompetitor} setClub={setClub} view={view} setView={setView} clearSearchInput={clearSearchInput} />}
                            {!club && clubList?.length > 0 && clubList?.map((clubListItem, index) => (
                                <ClubListItem
                                    key={clubListItem?.id}
                                    index={index}
                                    clubListItem={clubListItem}
                                    setClub={setClub}
                                    competitors={clubListItem?.competitors}
                                    setCompetitors={setCompetitors}
                                    setSelectedCompetitor={setSelectedCompetitor}
                                    view={view}
                                    setView={setView}
                                    clearSearchInput={clearSearchInput}
                                    displayProps={{
                                        borderStyle: index === clubList?.length - 1 ? 'none' : 'none none solid none',
                                        borderWidth: 1,
                                        borderColor: '#D0D5DD',
                                        bgcolor: clubListItem?.id === club?.id ? '#FFF' : '#EBEEF2',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                />
                            ))}
                            {view !== 0 && view !== 2 &&
                                competitors?.map((competitor, index) => (
                                    <ClubListCompetitorCard
                                        key={competitor?.id}
                                        competitor={competitor}
                                        club={club}
                                        selectedCompetitor={selectedCompetitor}
                                        setSelectedCompetitor={setSelectedCompetitor}
                                        setView={setView}
                                        index={index}
                                    />
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
            </Paper>
        );
    }
    else {
        return (
            <Box mt={1}>
                <Paper elevation={1} sx={{ mx: 1, my: 1 }}>
                    <Autocomplete
                        id='club_list'
                        options={clubList}
                        getOptionLabel={option => option.name}
                        onChange={(e, newValue) => {
                            setClub(newValue);
                            setCompetitors(newValue.competitors);
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant='outlined'
                                color='logoBlue'
                                size='small'
                                placeholder='Choose club...'
                                autoComplete='off'
                            />
                        )}
                    />
                </Paper>
            </Box>
        );
    }
}