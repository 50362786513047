import { useState, useEffect, useMemo, useContext } from "react";
import useConditionalSub from "./graphql/useConditionalSub";
import { LiveContext } from "../context/LiveContext";
import { LIVE_HEAT_FRAME_SUBSCRIPTION } from "../utils/graphql/subscriptions";
import { GET_HEAT_BY_ID } from "../utils/graphql/queries";
import { ScreenContext } from "../context/ScreenContext";
import { useLazyQuery } from "@apollo/client";

/**
 * This hook takes in a heat and determines whether it should start a subscription or not.
 * If the heat passed is live, then the heat values will be automatically updated until the heat becomes official or (not live).
 * @param thisHeat Heat object
 * @param eventView the current eventView
 * @returns
 */
export default function useHeat(
  thisHeat: Heat | undefined,
  eventView: View,
  roundHeatSubData: Round | undefined
) {
  const { screen } = useContext(ScreenContext);

  const [heat, setHeat] = useState<Heat | undefined>(thisHeat);
  const [subscribeHeat, setSubscribeHeat] = useState<Heat | undefined>(
    undefined
  );

  const [subscribe, setSubscribe] = useState<boolean>(false);
  const { currentHeatObj } = useContext(LiveContext);

  const {
    data: subHeatFrame,
    isActive,
    endSub,
  } = useConditionalSub<Heat>(
    LIVE_HEAT_FRAME_SUBSCRIPTION,
    { id: subscribeHeat?.id },
    !(!!subscribeHeat?.id && subscribe)
  );

  const [fetchHeatQuery, { data: heatQuery, error: err, loading, refetch }] =
    useLazyQuery(GET_HEAT_BY_ID, {
      variables: { id: subHeatFrame?.id },
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    subHeatFrame?.id && fetchHeatQuery();
  }, [subHeatFrame]);

  /* const {
    data: heatQuery,
    error: err,
    loading,
    refresh,
  } = useFetch<Heat>(
    GET_HEAT_BY_ID,

    { id: subHeatFrame?.id }, // since subHeatFrame is skipped most of the time, the id sends in as undefined
    "id",
    "cache-and-network"
  ); */

  useMemo(() => {
    if (
      currentHeatObj?.heat?.id !== undefined &&
      thisHeat?.id !== undefined &&
      currentHeatObj?.heat?.id === thisHeat?.id &&
      ((eventView === "heats" && screen === "events") ||
        screen === "scoreboard")
    ) {
      setSubscribeHeat(thisHeat);
      setSubscribe(true);
    } else {
      // endSub();
      setSubscribe(false);
    }
  }, [thisHeat, currentHeatObj]);

  useEffect(() => {
    subHeatFrame?.id &&
      setTimeout(() => {
        refetch();
      }, 300);
  }, [subHeatFrame]);

  useMemo(() => {
    if (heatQuery) setHeat(heatQuery.heat_by_pk);
  }, [heatQuery]);

  useMemo(() => {
    if (roundHeatSubData && subHeatFrame?.id) refetch();
  }, [roundHeatSubData]);

  useEffect(() => {
    return () => {
      endSub();
      setSubscribe(false);
    };
  }, []);

  useMemo(() => {
    setHeat(thisHeat);
  }, [thisHeat]);

  return { heat, loading, subscribe: isActive };
}
