import React from "react";

import { Box, Typography } from "@mui/material";

export default function Medals({ bronze, silver, gold, total }) {
  return (
    <>
      <Box
        aria-label="Gold medal count"
        display="flex"
        alignItems="center"
        mr="4px"
        width={40}
        sx={{ bgcolor: "" }}
      >
        {/* {row.medal_gold !== 0 && */}
        <>
          <Box
            height={24}
            mb={0.65}
            component="img"
            src="/icons/medals/goldmedal.png"
            alt="Gold medal"
            aria-hidden="true"
          />
          <Typography
            lineHeight={1}
            mt="2px"
            ml="2px"
            fontWeight={400}
            fontSize="0.9rem"
          >
            {gold}
          </Typography>
        </>
        {/* } */}
      </Box>
      <Box
        aria-label="Silver medal count"
        display="flex"
        alignItems="center"
        mr="4px"
        width={40}
        sx={{ bgcolor: "" }}
      >
        {/* {row.medal_silver !== 0 && */}
        <>
          <Box
            height={24}
            mb={0.65}
            component="img"
            src="/icons/medals/silvermedal.png"
            alt="Silver medal"
            aria-hidden="true"
          />
          <Typography
            lineHeight={1}
            mt="2px"
            ml="2px"
            fontWeight={400}
            fontSize="0.9rem"
          >
            {silver}
          </Typography>
        </>
        {/* } */}
      </Box>
      <Box
        aria-label="Silver medal count"
        display="flex"
        alignItems="center"
        width={40}
        sx={{ bgcolor: "" }}
      >
        {/* {row.medal_bronze !== 0 && */}
        <>
          <Box
            height={24}
            mb={0.65}
            component="img"
            src="/icons/medals/bronzemedal.png"
            alt="Bronze medal"
            aria-hidden="true"
          />
          <Typography
            lineHeight={1}
            mt="2px"
            ml="2px"
            fontWeight={400}
            fontSize="0.9rem"
          >
            {bronze}
          </Typography>
        </>
        {/* } */}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width={14}
        sx={{ bgcolor: "" }}
      >
        <Typography
          display="flex"
          lineHeight={1}
          mt="2px"
          fontWeight={500}
          fontSize="0.9rem"
        >
          =
        </Typography>
      </Box>
      <Box
        aria-label="Total medal count"
        display="flex"
        justifyContent="flex-start"
        mr="8px"
        width={16}
        sx={{ bgcolor: "" }}
      >
        <Typography lineHeight={1} mt="2px" fontWeight={500} fontSize="0.9rem">
          {total}
        </Typography>
      </Box>
    </>
  );
}
