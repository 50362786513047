import { useMemo, useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Typography, Box, Grid, keyframes, Fade, Slide } from "@mui/material";

import { BsPeopleFill } from "react-icons/bs";
import splitTimeColumns from "./splitTimeColumns";
import {
  competitorNavigationString,
  getBirthYear,
  heatRankDisplay,
  liveCompetitorWidth,
} from "../../../../components/utils";
import { FlagComponent } from "../../../../components/layout";
import { CountryCode } from "../../../../components/layout/FlagComponent";
import { determineParaCode } from "../../../../components/utils/determineParaCodes";
import { theme } from "../../../../utils/constants/mui-theme";

type Status = "DNF" | "DNS" | "DSQ" | "";

export default function LiveHeatCard({
  eventType,
  lane,
  index,
  heatStatus,
  showAge,
  eventLevel,
  distance,
}: {
  eventType: number | undefined;
  lane: Lane;
  index: number;
  heatStatus?: number;
  showAge?: boolean;
  eventLevel: number;
  distance?: number;
}) {
  const navigate = useNavigate();
  const params = useParams();
  const [imgLoaded, setImgLoaded] = useState<boolean>(true);
  const [showName, setShowName] = useState<boolean>(true);
  const [currentName, setCurrentName] = useState<string | undefined>("");
  const containerRef = useRef<HTMLElement>(null);

  const initialRender = useRef(true);

  const [result, setResult] = useState<string | undefined>();
  const [animateResult, setAnimateResult] = useState<number>(0);

  const [animateSubResult, setAnimateSubResult] = useState<number>(0);
  const [subs, setSubs] = useState<SubResult[]>([]);
  const [prevSubs, setPrevSubs] = useState<SubResult[]>([]);
  const animateSubsI = useRef<number[]>([]);

  const splits = distance ? splitTimeColumns(distance) : [];

  useMemo(() => {
    if (initialRender.current) {
      setPrevSubs(subs);
    } else if (
      subs.length !== 0 &&
      subs.find(
        (result, i) =>
          result?.result_value_text !== prevSubs[i].result_value_text
      )
    ) {
      const newResults =
        subs.filter((result) => !result.result_value_text).length !==
        subs.length
          ? subs.map(
              (result, i) =>
                result.result_value_text !== prevSubs[i].result_value_text && i
            )
          : [];

      const indexes = newResults.filter(
        (result) => result === 0 || result
      ) as number[];
      animateSubsI.current = indexes;

      setPrevSubs(subs);
      setAnimateSubResult(1);
    }
  }, [subs]);

  useMemo(() => {
    if (result && initialRender.current === false) {
      setAnimateResult(1);
    }
  }, [result]);

  useMemo(() => {
    if (lane.result_text != result) {
      setResult(lane.result_text);
    }
  }, [lane]);

  useMemo(() => {
    if (lane.sub_results) {
      setSubs(
        lane.sub_results.filter(
          (result) => result.done_at && splits.includes(result?.done_at)
        )
      );
    }
  }, [lane.sub_results]);

  const [animateRank, setAnimateRank] = useState<number>(0);
  const oldRank = useRef<number | undefined>();

  useMemo(() => {
    if (lane.heat_rank !== oldRank.current) {
      if (initialRender.current === false) {
        setAnimateRank(1);
      }

      oldRank.current = lane.heat_rank;
    }
  }, [lane.heat_rank]);

  const timeDisplay = (): Status | string | undefined => {
    if (heatStatus === 0) return "";
    if (lane?.dsq && heatStatus === 3) return "DSQ";
    if (lane?.dnf && heatStatus === 3) return "DNF";
    if (lane?.dns && heatStatus === 3) return "DNS";
    return lane?.result_text;
  };

  const handlePodiumColor = (rank: number): string[] => {
    switch (rank) {
      case 1:
        return ["rgba(245, 209, 51, 1)", "rgba(235, 195, 22, 1)"]; //return ['#F5D133', '#E69F12'];
      case 2:
        return ["rgba(227, 227, 227, 1)", "rgba(211, 211, 211, 1)"]; //return ['#E3E3E3', '#C9C9C9'];
      case 3:
        return ["rgba(245, 169, 108, 1)", "#BF6F3D"]; //return ['#F5A96C', '#BF6F3D'];
      default:
        return ["#F4C647", "#F4C647"];
    }
  };

  const filteredSubResults = () => {
    if (subs && subs?.length > 0) {
      return subs.map((result, i) => (
        <Box
          key={result?.id}
          display="flex"
          justifyContent="flex-end"
          ml="8px"
          width={72}
          sx={{ bgcolor: "" }}
        >
          <Typography
            fontSize="0.8rem"
            lineHeight={1}
            sx={{
              bgcolor: "",
              borderRadius: "4px",
              paddingLeft: "4px",
              paddingRight: "4px",
              paddingTop: "1px",
              paddingBottom: "1px",
            }}
            onAnimationEnd={() => (
              setAnimateSubResult(0), (animateSubsI.current = [])
            )}
            data-animationon={
              animateSubsI.current.includes(i) ? animateSubResult : 0
            }
            className="heat-sub-result"
          >
            {result?.result_value_text}
          </Typography>
        </Box>
      ));
    }
  };

  const animation = (rank: number) => {
    const colorAnimation = keyframes`
        0% {
                background: ${handlePodiumColor(rank)?.[0]};
        }
        100% {
                background: ${handlePodiumColor(rank)?.[1]};
        }
        `;
    return `${colorAnimation} 4s ease-in-out infinite alternate both`;
  };

  useMemo(() => {
    if (lane?.competitor && lane.competitor.full_name !== currentName) {
      setShowName(false);
      setCurrentName(lane?.competitor?.full_name);
      setTimeout(() => {
        setShowName(true);
      }, 100);
    }
  }, [lane]);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  return (
    <>
      {/* Competitor data and rank */}
      <Grid
        container
        item
        xs={12}
        py={0.5}
        mx={0}
        alignItems="center"
        onClick={() => {
          navigate(
            competitorNavigationString(
              params.competitionName,
              lane?.competitor?.club?.oid,
              lane?.competitor?.oid
            )
          );
        }}
        //@ts-ignore
        sx={{
          bgcolor: "#FFF",
          cursor: "pointer",
          borderTopStyle: index !== 0 && "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
          animation:
            lane.heat_rank &&
            lane?.heat_rank !== 0 &&
            lane?.heat_rank < 4 &&
            eventLevel < 2 &&
            animation(lane?.heat_rank),
        }}
      >
        <Box display="flex" width={40} ml="4px" sx={{ bgcolor: "" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="32px"
            width="32px"
            //@ts-ignore
            sx={{
              bgcolor: "#F4C647",
              background:
                eventLevel >= 2 || !lane.heat_rank
                  ? "#F4C647"
                  : lane.heat_rank &&
                    lane.heat_rank !== 0 &&
                    `linear-gradient(${handlePodiumColor(lane.heat_rank)})`,
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <Typography>{lane?.number}</Typography>
          </Box>
        </Box>
        {lane.competitor && (
          <Box
            width={16}
            height={12}
            display="flex"
            alignItems="center"
            sx={{
              boxShadow: 2,
              borderRadius: 1,
              bgcolor: "",
            }}
          >
            <FlagComponent
              countryCode={lane?.competitor?.country_code as CountryCode}
              size="s"
            />
          </Box>
        )}
        <Box
          display="flex"
          ml="6px"
          // ref={containerRef}
          sx={{
            width: liveCompetitorWidth(distance, eventLevel),
            bgcolor: "",
          }}
        >
          <Slide
            in={showName}
            container={containerRef.current}
            direction="left"
            appear={false}
            easing={{
              enter: theme.transitions.easing.easeOut,
              exit: theme.transitions.easing.easeIn,
            }}
          >
            <Typography fontWeight={500} noWrap>
              {currentName}
            </Typography>
          </Slide>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={40}
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          {eventType &&
            lane?.competitor &&
            determineParaCode(eventType, lane.competitor) && (
              <Typography
                pb={"2px"}
                lineHeight={1}
                fontSize={"0.7em"}
                mt={"2px"}
                mr={"8px"}
              >
                {determineParaCode(eventType, lane.competitor)}
              </Typography>
            )}
          <Typography fontSize="0.9rem" lineHeight={1}>
            {showAge
              ? lane?.competitor?.age
              : getBirthYear(lane?.competitor?.birthday)}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width={224}
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          {
            <Box
              component="img"
              height="32px"
              width="32px"
              src={`${
                process.env.REACT_APP_CDN_URL
              }club_logos/${lane?.competitor?.club?.country_code?.toLowerCase()}/32/${lane?.competitor?.club?.short_name?.toLowerCase()}.png`}
              onError={() => setImgLoaded(false)}
              sx={{ display: imgLoaded ? "inline" : "none" }}
              alt={`${lane?.competitor?.club?.name} logo`}
            />
          }
          {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
          {!imgLoaded && lane.competitor && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="32px"
              minWidth="32px"
              sx={{ bgcolor: "#374B5C", borderRadius: 2 }}
            >
              <BsPeopleFill color="#FFF" size="24px" />
            </Box>
          )}
          <Typography noWrap fontSize="0.9rem" ml="8px" lineHeight={"normal"}>
            {lane?.competitor?.club?.name}
          </Typography>
        </Box>
        <Box display="flex" ml="8px" sx={{ bgcolor: "" }}>
          {filteredSubResults()}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width={32}
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          <Typography fontSize="0.75rem">{lane?.reaction_time}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width={36}
          ml="8px"
          sx={{ bgcolor: "", display: eventLevel > 1 ? "none" : "inherit" }}
        >
          {lane.heat_rank !== 0 &&
            heatStatus &&
            heatRankDisplay(heatStatus, lane, lane?.heat_rank) && (
              <Box
                className="heat-rank"
                onAnimationEnd={() => setAnimateRank(0)}
                data-animationon={animateRank}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="24px"
                width="24px"
                color="#ffffff"
                sx={{
                  bgcolor: "#374B5C",
                  borderRadius: 1,
                  border: "transparent 2px solid",
                  boxSizing: "border-box",
                }}
              >
                <Typography>
                  {heatRankDisplay(heatStatus, lane, lane?.heat_rank)}
                </Typography>
              </Box>
            )}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={72}
          ml="8px"
          mr="4px"
          sx={{ bgcolor: "" }}
        >
          <Typography
            className="heat-result"
            onAnimationEnd={() => setAnimateResult(0)}
            data-animationon={animateResult}
            display="flex"
            justifyContent="flex-end"
            lineHeight={1}
            width={72}
            fontSize="1rem"
            fontWeight={500}
            sx={{
              bgcolor: "",
              width: "fit-content",
              borderRadius: "4px",
              paddingLeft: "4px",
              paddingRight: "4px",
              paddingTop: "1px",
              paddingBottom: "1px",
            }}
          >
            {timeDisplay()}
          </Typography>
        </Box>
      </Grid>
    </>
  );
}
