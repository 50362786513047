import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 0,
          lineHeight: 1,
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "grey" },
          style: {
            border: "1px solid",
            borderColor: "#D0D5DD",
            color: "#344054",
            backgroundColor: "#FCFCFD",
            "&:hover": {
              backgroundColor: "#F2F4F7",
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { minHeight: 0 },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          width: "100%",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          textTransform: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#444CE7",
      light: "#D1E0FF",
      dark: "#00359E",
    },
    secondary: {
      main: "#000",
    },
    error: {
      main: "#C53133",
    },
    neutral: {
      main: "#98A2B3",
      contrastText: "#000",
    },
    logoBlue: {
      main: "#374B5C",
      contrastText: "#fff",
    },
    laneYellow: {
      main: "#F4C647",
      contrastText: "#000",
    },
    grey25: {
      main: "#FCFCFD",
      contrastText: "#000",
    },
    grey50: {
      main: "#F9FAFB",
      contrastText: "#000",
    },
    grey100: {
      main: "#F2F4F7",
      contrastText: "#000",
    },
    grey150: {
      main: "#EBEEF2",
      contrastText: "#000",
    },
    grey200: {
      main: "#E4E7EC",
      contrastText: "#000",
    },
    grey300: {
      main: "#D0D5DD",
      contrastText: "#000",
    },
    grey400: {
      main: "#98A2B3",
      contrastText: "#000",
    },
    grey500: {
      main: "#667085",
      contrastText: "#000",
    },
    grey600: {
      main: "#475467",
      contrastText: "#FFF",
    },
    grey700: {
      main: "#344054",
      contrastText: "#FFF",
    },
    grey800: {
      main: "#1D2939",
      contrastText: "#FFF",
    },
    grey900: {
      main: "#101828",
      contrastText: "#FFF",
    },
    lightBlue: {
      main: "#93C7F5",
      contrastText: "#000",
    },
    blue: {
      main: "#6589A8",
      contrastText: "#000",
    },
    darkBlue: {
      main: "#283642",
      contrastText: "#FFF",
    },
    logo: {
      main: "#374B5C",
      contrastText: "#000",
    },
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
});
