import { useEffect, useState } from 'react';

import {
    Box,
    Typography,
    Grid,
} from '@mui/material';

import { GET_COMPETITOR_POINTS_LIST, GET_CLUB_POINTS_LIST, GET_CLUB_POINTS_MEDAL_LIST } from '../../../utils/graphql/queries';

import QueryResult from '../../../components/containers/QueryResult';
import { getDateTime } from '../../../components/utils';
import EmptyCompetitionPointList from './components/EmptyCompetitionPointList';
import { PointListMenu } from './components/PointListMenu';
import PointsHeadersClubs from './components/PointsHeadersClubs';
import PointsHeadersMedals from './components/PointsHeadersMedals';
import PointsHeadersSwimmers from './components/PointsHeadersSwimmers';
import ResultsSwimmerCard from './components/ResultsSwimmerCard';
import ResultsClubCard from './components/ResultsClubCard';
import ResultsMedalCard from './components/ResultsMedalCard';
import useFetch from '../../../hooks/graphql/useFetch';
import WaPointsHeadersClubs from './components/WaPointsHeadersClubs';
import WaResultsClubCard from './components/WaResultsClubCard';
import InduvidualRankPoints from './components/InduvidualRankPoints';

export default function PointsView({ competitionId, showAge }) {

    const [pointListMenuItem, setPointListMenuItem] = useState(null);
    const [noPointsLists, setNoPointsLists] = useState(false);
    const [selectedPointsList, setSelectedPointsList] = useState(null);
    const [graphQLDocument, setGraphQLDocument] = useState(GET_COMPETITOR_POINTS_LIST);

    const { loading, error, data, refresh } = useFetch(graphQLDocument, { _id: pointListMenuItem?.id }, '_id', 'cache-and-network');

    useEffect(() => {

        switch (pointListMenuItem?.list_type) {
            case 0:
            case 6:
                setGraphQLDocument(GET_COMPETITOR_POINTS_LIST);
                break;
            case 1:
            case 5:
                setGraphQLDocument(GET_CLUB_POINTS_LIST);
                break;
            case 2:
                setGraphQLDocument(GET_CLUB_POINTS_MEDAL_LIST);
                break;
        }

    }, [pointListMenuItem]);

    useEffect(() => {
        refresh();
    }, [graphQLDocument]);

    useEffect(() => {
        setSelectedPointsList(data?.point_list_rows);
    }, [data]);

    return (
        <>
            {noPointsLists ?
                <EmptyCompetitionPointList />
                :
                <Grid container sx={{ bgcolor: '' }}>
                    <Grid item xs={12} md={4} sx={{ bgcolor: '' }}>
                        <Box mx={0} width='100%' sx={{ bgcolor: '' }}>
                            <PointListMenu
                                competitionId={competitionId}
                                pointListMenuItem={pointListMenuItem}
                                setPointListMenuItem={setPointListMenuItem}
                                setNoPointsLists={setNoPointsLists}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ bgcolor: '' }}>
                        <Box ml={1} mb={2}>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Typography mr={1} mt={0} fontWeight={700} fontSize={{ xs: '0.9rem', sm: '2rem' }}>{pointListMenuItem?.name}</Typography>
                                <Typography mx={1} mt={{ xs: 0, sm: 1.5 }} fontSize={{ xs: '0.7rem', sm: '0.8rem' }}>Updated: {getDateTime(pointListMenuItem?.updated_at)}</Typography>
                            </Box>
                            <QueryResult loading={loading} error={error} data={data}>
                                {selectedPointsList &&
                                    <>
                                        <Grid container sx={{
                                            borderRadius: 1,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            borderColor: '#D0D5DD',
                                            bgcolor: '#E4E7EC',
                                            boxShadow: 1
                                        }}>
                                            {data?.list_type === 0 &&
                                                <>
                                                    <PointsHeadersSwimmers showAge={showAge} />
                                                    {selectedPointsList.map((row, index) => (
                                                        <ResultsSwimmerCard
                                                            key={row.id}
                                                            competitor={row?.competitor}
                                                            club={row?.competitor?.club}
                                                            rank={row.rank}
                                                            points={row.fina_points}
                                                            index={index}
                                                            showAge={showAge}
                                                        />
                                                    ))}
                                                </>
                                            }
                                            {data?.list_type === 1 &&
                                                <>
                                                    <PointsHeadersClubs />
                                                    {selectedPointsList.map((row, index) => (
                                                        <ResultsClubCard
                                                            key={row.id}
                                                            club={row.club}
                                                            rank={row.rank}
                                                            clubPoints={row.club_points}
                                                            index={index}
                                                        />
                                                    ))}
                                                </>
                                            }
                                            {data?.list_type === 2 &&
                                                <>
                                                    <PointsHeadersMedals />
                                                    {selectedPointsList.map((row, index) => (
                                                        <ResultsMedalCard
                                                            key={row.id}
                                                            row={row}
                                                            rank={row.rank}
                                                            club={row.club}
                                                            index={index}
                                                        />
                                                    ))}
                                                </>
                                            }
                        
                                            {data?.list_type === 5 &&
                                                <>
                                                    <WaPointsHeadersClubs />
                                                    {selectedPointsList.map((row, index) => (
                                                        <WaResultsClubCard
                                                            key={row.id}
                                                            finaPoints={row.fina_points}
                                                            rank={row.rank}
                                                            club={row.club}
                                                            index={index}
                                                        />
                                                    ))}
                                                </>
                                            }
                                            {data?.list_type === 6 &&
                                                <>
                                                    <PointsHeadersSwimmers showAge={showAge} />
                                                    {selectedPointsList.map((row, index) => (
                                                        <InduvidualRankPoints
                                                            key={row.id}
                                                            competitor={row?.competitor}
                                                            clubPoints={row.club_points}
                                                            rank={row.rank}
                                                            club={row.competitor.club}
                                                            index={index}
                                                            showAge={row.showAge}
                                                        />
                                                    ))}
                                                </>
                                            }
                                        </Grid>
                                    </>
                                }
                            </QueryResult>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
}