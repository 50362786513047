import { useState, useEffect, useContext } from "react";
import { Typography, Box, Grid, Grow, keyframes } from "@mui/material";
import {
  HandleHeatStatus,
  getStartTime,
} from "../../../../../components/utils";
import {
  ButtonComponent,
  HeatLaneHeaders,
} from "../../../../../components/layout";
import calculateStartEndTimeDiff from "../../../../../components/utils/calculateStartEndTimeDiff";
import HeatLane from "./HeatLane";
import { LiveContext } from "../../../../../context/LiveContext";
import useHeat from "../../../../../hooks/useHeat";
import { FaPlayCircle } from "react-icons/fa";
import HeatSuperLive from "./HeatSuperLive";
import HeatStopWatch from "../../../../../components/HeatStopWatch";
import { CompetitionDetailsContext } from "../../../../../context/CompetitionDetailsContext";
import SuperliveLogo from "../../../../../components/svg/SuperliveLogo";

export default function HeatCard({
  roundHeatSubData,
  eventView,
  eventType,
  isCompetitionActive,
  event,
  passedHeat,
  isRelay,
  isPara,
  showAge,
  eventLevel,
  superliveSeo,
}: {
  roundHeatSubData: Round | undefined;
  eventView: View;
  eventType: number | undefined;
  isCompetitionActive: boolean;
  event: Event | undefined;
  passedHeat: Heat | undefined;
  isRelay: boolean | undefined;
  isPara: boolean | undefined;
  showAge: boolean | undefined;
  eventLevel: number;
  superliveSeo: string | undefined;
}) {
  const [offset, setOffset] = useState<string | undefined>(undefined);
  const [superliveId, setSuperliveId] = useState(undefined);
  const { currentHeatObj } = useContext(LiveContext);
  const { heat, subscribe } = useHeat(passedHeat, eventView, roundHeatSubData);
  const [grow, setGrow] = useState(false);
  const [elementId, setElementId] = useState<string>("");
  const [showSuperLive, setShowSuperLive] = useState<boolean>(false);
  const { isBeta } = useContext(CompetitionDetailsContext);

  const getSuperLiveId = () => {
    let superlivestring;
    let temp;
    const regex = new RegExp(/([^\/]+$)/);
    if (superliveSeo && (temp = regex.exec(superliveSeo)) !== null) {
      // The result can be accessed through the `m`-variable.
      temp.forEach((match, groupIndex) => {
        superlivestring = match;
      });
    }

    if (superlivestring) {
      var myHeaders = new Headers();

      var requestOptions: any = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        superliveSeo?.includes("embed/livestreams")
          ? `https://api.staylive.tv/videos/${superlivestring}`
          : `https://api.staylive.tv/livestreams/${superlivestring}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message.replay) setSuperliveId(result.message.replay.id);
          else setSuperliveId(result.message.id);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const getOffset = () => {
    const startTime = heat?.start_time as string;

    var dateTime = new Date(startTime);
    dateTime.setSeconds(dateTime.getSeconds() - 20);

    const local = dateTime.toISOString();
    var myHeaders = new Headers();
    var requestOptions: any = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    if (superliveId) {
      fetch(
        `https://api.staylive.tv/videos/${superliveId}/find-chapter?datetime=${
          local as string
        }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setOffset(result.message.time);
        })
        .catch((error) => console.log("error", error));
    }
  };
  useEffect(() => {
    getOffset();
  }, [superliveId]);

  useEffect(() => {
    if (superliveSeo) {
      getSuperLiveId();
    }
  }, [superliveSeo]);

  useEffect(() => {
    if (
      heat?.id &&
      currentHeatObj?.heat?.id &&
      heat?.id === currentHeatObj.heat.id
    ) {
      setElementId("live-heat");
    } else setElementId(`${heat?.id}`);
  }, [currentHeatObj, heat]);

  const heatStatus = heat?.status || 0;
  const openSuperLive = () => {
    setShowSuperLive(!showSuperLive);
  };

  return (
    <>
      <Box
        mt={1}
        id={elementId}
        sx={{
          borderRadius: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
          bgcolor: "#E4E7EC",
          boxShadow: 1,
        }}
      >
        <Box display="flex" mx={0.5} my={0.5} sx={{ bgcolor: "" }}>
          <Box
            display="flex"
            justifyContent="flex-start"
            width="100%"
            sx={{ bgcolor: "" }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my="auto"
              height="2.8rem"
              width="2.8rem"
              sx={{
                bgcolor: "#374B5C",
                borderRadius: 2,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography fontSize="1rem" color="#FFF">
                  {heat?.number}
                </Typography>
                <Typography fontSize="0.75rem" lineHeight={1} color="#FFF">
                  heat
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography mx={1} fontWeight="bold" fontSize="1.1rem">
                {heat?.name}
              </Typography>
              {heat?.status ? (
                heat.status > 1 &&
                heat?.start_time !== "0001-01-01T00:00:00+00:00" ? (
                  <Typography mx={1} fontWeight="300" fontSize="0.8rem">
                    Started at: {getStartTime(heat?.start_time)}&nbsp;&nbsp;
                    {heat?.estimated_start_time &&
                      heat?.start_time &&
                      calculateStartEndTimeDiff(
                        heat?.estimated_start_time,
                        heat?.start_time
                      )}
                    <></>
                  </Typography>
                ) : (
                  <Typography mx={1} fontWeight="300" fontSize="0.8rem">
                    Estimated start time:{" "}
                    {getStartTime(heat?.estimated_start_time)}
                  </Typography>
                )
              ) : (
                <Typography mx={1} fontWeight="300" fontSize="0.8rem">
                  Estimated start time:{" "}
                  {getStartTime(heat?.estimated_start_time)}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection={"column"} justifyContent="center">
            <HeatStopWatch
              heat={heat}
              currentHeatId={currentHeatObj?.heat?.id}
              showZero={false}
              distance={event?.distance}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mr="4px"
            sx={{ bgcolor: "" }}
          >
            {isBeta && offset && (
              <ButtonComponent
                variant="contained"
                color={"logoBlue"}
                sx={{
                  alignItems: "center",
                  fontSize: { xs: "0.5rem", sm: "0.65rem", md: "0.8rem" },
                  height: { xs: "20px", md: "24px" },
                  paddingHorizontal: "7px",
                  whiteSpace: "nowrap",
                }}
                onClick={openSuperLive}
                title={`${showSuperLive ? "Close" : "Watch"} replay`}
                endIcon={undefined}
                icon={undefined}
                disabled={false}
                startIcon={
                  /* <FaPlayCircle size="0.8rem" className="react-icons" /> */
                  <SuperliveLogo height={20} width={20} fill="white" />
                }
              />
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            gap={1}
            flexDirection={"column"}
            alignItems="center"
            mr="4px"
            my={"auto"}
            borderRadius={1}
          >
            <HandleHeatStatus
              heat={heat}
              currentHeatId={currentHeatObj?.heat?.id}
              isActive={subscribe}
            />
          </Box>
        </Box>
        {/* HEAT LANES START */}
        <Grid container>
          <Box
            sx={{
              height: superliveSeo && offset && "600px",
              width: "98.8%",
              maxHeight: "600px",
              animation: showSuperLive
                ? "superlive-heat-open 0.5s forwards"
                : "superlive-heat-close 0.5s forwards",
            }}
          >
            {isBeta && offset && superliveSeo && (
              <HeatSuperLive
                offset={offset}
                superliveSeo={
                  superliveSeo?.includes("embed/livestreams")
                    ? `${
                        superliveSeo.split("livestream")[0]
                      }videos/${superliveId}`
                    : superliveSeo
                }
                showSuperLive={showSuperLive}
              />
            )}
          </Box>

          <HeatLaneHeaders
            isRelay={isRelay}
            isPara={isPara}
            showAge={showAge}
            eventLevel={eventLevel}
          />
          {heat?.lanes?.map(
            (lane, index) =>
              lane?.competitor && (
                <HeatLane
                  eventType={eventType}
                  lane={lane}
                  key={lane.id}
                  index={index}
                  heatStatus={heatStatus}
                  event={event}
                  eventLevel={eventLevel}
                  showAge={showAge}
                />
              )
          )}
        </Grid>
      </Box>
    </>
  );
}
