import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { BsPersonFill } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { getBirthYear } from "../../../../components/utils";

export default function CompetitorCard({
  competitor,
  setView,
  index,
  showAge,
  setCompetitors,
  setSelectedCompetitor,
  setClub,
}) {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Grid
      container
      item
      alignItems="center"
      mt={0}
      py={0.5}
      onClick={() => {
        navigate(
          `../competitions/${params.competitionName}/swimmers/clubs/${competitor?.club?.oid}/competitor/${competitor?.oid}`
        );
        setView(3);
      }}
      sx={{
        bgcolor: "#FFF",
        cursor: "pointer",
        borderTopStyle: index !== 0 && "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
      }}
    >
      <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="auto"
          height="32px"
          width="32px"
          sx={{
            bgcolor: "#F4C647",
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {competitor.is_relay ? <BsPeopleFill /> : <BsPersonFill />}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        width="calc(100% - 362px)"
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Typography fontWeight={500} noWrap>
          {competitor.full_name}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={40}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Typography fontSize="0.9rem" lineHeight={1}>
          {showAge ? competitor?.age : getBirthYear(competitor?.birthday)}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width={254}
        ml="8px"
        mr="4px"
        sx={{ bgcolor: "" }}
      >
        <Box
          component="img"
          height="32px"
          width="32px"
          src={`${
            process.env.REACT_APP_CDN_URL
          }club_logos/${competitor?.club?.country_code.toLowerCase()}/32/${competitor?.club?.short_name.toLowerCase()}.png`}
          onError={(e) =>
            (e.target.src = "/icons/people/generic_club_icon_small.png")
          }
          alt={`${competitor?.club?.name} logo`}
        />
        <Typography noWrap fontSize="0.9rem" ml="8px" lineHeight={1}>
          {competitor?.club?.name}
        </Typography>
      </Box>
    </Grid>
  );
}
