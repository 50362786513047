import { useContext } from "react";
import StatusBadge from "../layout/StatusBadge";
import { LiveContext } from "../../context/LiveContext";
import { Box } from "@mui/material";

//if heat is ongoing (status 2) and equal to current_heat (live) and current time is less than 1 hour after heat start time, display live badge
//if heat is ongoing (status 2) but not equal to current_heat, display unofficial badge
//if heat is confirmed (status 3), display official badge
//else, return null

export default function HandleHeatStatus({
  heat,
  currentHeatId,
  isActive,
}: {
  heat: Heat | undefined;
  currentHeatId: number | undefined;
  isActive?: boolean;
}): JSX.Element {
  const { currentHeatUpdateThreshold } = useContext(LiveContext);
  const currentTime = Date.now();

  return (
    <Box>
      {heat?.status === 2 &&
      heat?.id === currentHeatId &&
      currentTime &&
      currentHeatUpdateThreshold &&
      currentTime < currentHeatUpdateThreshold ? (
        <Box
          sx={{
            animation: isActive ? "pulse-animation 2s infinite" : "",
            borderRadius: 1,
          }}
        >
          <StatusBadge status={"LIVE"} />
        </Box>
      ) : heat?.status === 3 ? (
        <Box
          sx={{
            animation: isActive ? "pulse-animation-green 2s infinite" : "",
            borderRadius: 1,
          }}
        >
          <StatusBadge status={"Official"} />
        </Box>
      ) : heat?.status === 2 && heat?.id !== currentHeatId ? (
        <Box
          sx={{
            animation: isActive ? "pulse-animation-grey 2s infinite" : "",
            borderRadius: 1,
          }}
        >
          <StatusBadge status={"Unofficial"} />
        </Box>
      ) : null}
    </Box>
  );
}
