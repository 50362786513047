import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import UpcomingCompetitions from "./components/upcomingCompetitions/UpcomingCompetitions";
import FinishedCompetitions from "./components/finishedCompetitions/FinishedCompetitions";
import { MainTableLayout, Breadcrumbs } from "../components/layout";
import MetaDecorator from "../components/utils/MetaDecorator";
import CompetitionsThisWeek from "./components/competitionsThisWeek/CompetitionsThisWeek";
import Header from "../components/Header";
import RegionSelection from "../components/RegionSelection";

export type CountryName =
  | "ALL"
  | "SWE"
  | "FIN"
  | "DEN"
  | "NOR"
  | "FRO"
  | "SLO"
  | "AUS"
  | "NZL"
  | "ROU"
  | "CAN"
  | "ISL"
  | "SUI";

export default function Home() {
  const navigate = useNavigate();
  const [selectedRegion, setSelectedRegion] = useState<number>(() => {
    const regionInStorage = localStorage.getItem("region");
    if (regionInStorage === null) return 1;
    try {
      JSON.parse(regionInStorage);
      //region previously named 0
      if (JSON.parse(regionInStorage) === 0) return 1;
      return JSON.parse(regionInStorage);
    } catch (e) {
      localStorage.removeItem("region");
      return 1;
    }
  });

  const [selectedCountry, setSelectedCountry] = useState<CountryName>(() => {
    const countryInStorage = localStorage.getItem("country");
    if (countryInStorage === null) return "ALL";
    try {
      return JSON.parse(countryInStorage) as CountryName;
    } catch (e) {
      localStorage.removeItem("country");
      return "ALL";
    }
  });

  /* HANDLE REGION CHANGE */
  useEffect(() => {
    if (selectedRegion)
      localStorage.setItem("region", JSON.stringify(selectedRegion));
  }, [selectedRegion]);

  /* HANDLE COUNTRY CHANGE */
  useEffect(() => {
    if (selectedCountry)
      localStorage.setItem("country", JSON.stringify(selectedCountry));
  }, [selectedCountry]);

  return (
    <>
      <MetaDecorator title="Swimify - Home" description="Swimify Homepage" />
      <Header />
      <MainTableLayout
        title={<Breadcrumbs />}
        countryFilter={
          <RegionSelection
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        }
      >
        <Grid item xs={12}>
          <CompetitionsThisWeek
            selectedRegion={selectedRegion}
            selectedCountry={selectedCountry}
          />
        </Grid>
        <UpcomingCompetitions
          selectedRegion={selectedRegion}
          selectedCountry={selectedCountry}
        />
        <FinishedCompetitions
          selectedRegion={selectedRegion}
          selectedCountry={selectedCountry}
        />
      </MainTableLayout>
      <Box
        width="100%"
        mx="auto"
        display="flex"
        justifyContent="center"
        sx={{ bgcolor: "" }}
        role="contentinfo"
      >
        <Box
          /* maxWidth='1600px' */ display="flex"
          justifyContent="end"
          sx={{
            minWidth: "944px",
            width: { xs: "calc(100vw - 16px)", md: "calc(100vw - 32px)" },
            bgcolor: "",
          }}
        >
          {/* @ts-ignore */}
          <Button
            /* @ts-ignore */
            variant="grey"
            onClick={() => navigate("/competitions")}
            aria-label="View all competitions"
          >
            All Competitions
          </Button>
        </Box>
      </Box>
    </>
  );
}
