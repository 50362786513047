import { useContext, useState } from "react";

import { OperationVariables, useSubscription } from "@apollo/client";
import { DocumentNode } from "graphql";
import { CompetitionDetailsContext } from "../../context/CompetitionDetailsContext";

export default function useStrictSubscription<
  T,
  TVariables extends OperationVariables = OperationVariables
>(
  documentNode: DocumentNode,
  variables: TVariables,
  skip?: boolean,
  onData?: (data: any) => void
) {
  const [data, setData] = useState<T | undefined>();
  const { shouldSubscribe } = useContext(CompetitionDetailsContext);
  const { error, loading } = useSubscription<Record<string, any>>(
    documentNode,
    {
      variables,
      skip: skip || !variables || !shouldSubscribe,
      onData: (data) => {
        const parsedData =
          data.data.data && data.data.data[Object.keys(data.data.data)[0]];
        if (
          parsedData &&
          parsedData instanceof Array &&
          parsedData.length <= 1
        ) {
          const deArrayed = parsedData[0];
          setData(deArrayed);
        } else {
          setData(parsedData);
        }
      },
    }
  );

  return { data, error, loading };
}
