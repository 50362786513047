import React, { useState, useEffect } from 'react';

import { Typography } from '@mui/material';

import Loader from '../layout/Loader';
import LargeLoader from '../layout/LargeLoader';

const QueryResult = ({ onlyLoadInitially, loading, loadingLarge, error, data, children }: { onlyLoadInitially?: boolean, loading: boolean, loadingLarge?: boolean, error?: any, data?: any, children?: React.ReactNode; }) => {

  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    if (loading || loadingLarge) setInitialLoad(false);
  }, [loading, loadingLarge]);

  return (
    <>
      {
        onlyLoadInitially ?
          initialLoad ?
            error ? <Typography my='auto' mx={1}>ERROR: {error.message}</Typography> :
              loading ? <Loader /> :
                loadingLarge ? <LargeLoader /> :
                  !data ? <Typography my='auto' mx={1}>Nothing to show...</Typography> :
                    children
            : children
          :
          error ? <Typography my='auto' mx={1}>ERROR: {error.message}</Typography> :
            loading ? <Loader /> :
              loadingLarge ? <LargeLoader /> :
                !data ? <Typography my='auto' mx={1}>Nothing to show...</Typography> :
                  children
      }
    </>
  );
};

export default QueryResult;



