import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Link,
    Breadcrumbs as MUIBreadcrumbs
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { visuallyHidden } from '@mui/utils';

export default function Breadcrumbs({ competitionName }: {competitionName?: string}) {

    const location = useLocation().pathname;
    const navigate = useNavigate();

    //TODO: only accepts three objects in breadcrumbs path
    const paths = location.split('/').filter(x => x).slice(0, 2);

    const decodedPaths = paths.map((path) => decodeURI(path));

    function titleCase(string: string) {
        try {
            return string.split(' ').map((word) => word[0].toUpperCase() + word.slice(1)).join(' ');
        } catch {
            return string;
        }
    }

    return (
        <Box component='nav' width='100%' sx={{ bgcolor: '' }} aria-label='breadcrumb'>
            <MUIBreadcrumbs aria-label='breadcrumb' color='text.primary' sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem' } }}>
                {decodedPaths.length > 0 ?
                    <Link component='button' underline='hover' color='text.primary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
                        <HomeIcon sx={{ marginTop: 0.5, fontSize: { xs: '1.2rem', sm: '1.4rem' } }} />
                        <Typography sx={visuallyHidden}>Home</Typography>
                    </Link>
                    :
                    <HomeIcon sx={{ marginTop: 0.5, fontSize: { xs: '1.2rem', sm: '1.4rem' } }} />
                }
                {decodedPaths.map((path, index) => {
                    const routeTo = `/${decodedPaths.slice(0, index + 1).join('/')}`;
                    const isLast = index === decodedPaths.length - 1;
                    return isLast ? (
                        <Typography component='span' key={index} fontWeight={500} sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                            {titleCase(competitionName ?? path)}
                        </Typography>
                    )
                        :
                        (
                            <Link
                                component='button'
                                key={index} 
                                underline="hover" 
                                color="inherit"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigate(routeTo)}>
                                    {titleCase(path)}
                            </Link>
                        );
                })}
            </MUIBreadcrumbs>
        </Box>
    );
}