import { Box } from "@mui/material";

export default function HeatSuperLive({
  offset,
  superliveSeo,
  showSuperLive,
}: {
  offset: string;
  superliveSeo: string;
  showSuperLive: boolean;
}) {
  return (
    <Box
      id={`${superliveSeo + offset}`}
      sx={{
        borderRadius: 1,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        boxShadow: 1,
        width: "98%",
        aspectRatio: 16 / 9,
        padding: 2,
        height: "94%",
      }}
    >
      {
        <Box
          sx={{
            borderRadius: 1,
            width: "100%",
            overflow: "hidden",
            boxShadow: 1,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#D0D5DD",
            backgroundColor: "black",
            height: "100%",
            // animation: "superlive-heat-box 2s",
          }}
        >
          {showSuperLive && (
            <iframe
              src={`${superliveSeo}?offset=${offset}`}
              allowFullScreen
              width={"100%"}
              height={"100%"}
              title="Swimify Live Video Stream"
              style={{ border: "none", maxWidth: "100%" }}
              allow="autoplay"
            ></iframe>
          )}
        </Box>
      }
    </Box>
  );
}
