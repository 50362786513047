import { useEffect, useState } from 'react';

import { GET_COMPETITION_ID } from '../utils/graphql/queries';
import { getLocalStorageSEO, storeCompetitionId } from '../components/utils';
import query from '../components/utils/query';

export default function useCompetitionId(competitionSEO: string | undefined) {
    const [id, setId] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const visited = getLocalStorageSEO();

    useEffect(()=>{
        const applyCompId = async () => {
           if(competitionSEO) {
                const id = visited?.find(comp => comp.seoText === competitionSEO)?.competitionId;
                if (id) {
                    setId(id);
                    setLoading(false);
                }else{
                    const {data, loading} = await query<Competition>(GET_COMPETITION_ID, {competitionSEO});
                    setLoading(loading);
                    if (data && !loading && data.id) {
                        const id = data.id;
                        storeCompetitionId(competitionSEO, id);
                        setId(id);
                    }
                }
            }
        } 
        applyCompId();
    },[competitionSEO])

    
    
    return {competitionId: id, loading}
}
