import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useQuery } from "@apollo/client";

import { Grid, Box, Typography } from "@mui/material";

import ClubViewHeatEventCard from "./ClubViewHeatEventCard";
import ClubViewHeatCompetitorCard from "./ClubViewHeatCompetitorCard";
import {
  GET_CLUBVIEW_HEATS,
  GET_CLUBVIEW_HEATS_REMAKE,
} from "../../../../../../utils/graphql/queries";
import QueryResult from "../../../../../../components/containers/QueryResult";
import { ScreenContext } from "../../../../../../context/ScreenContext";
import ReactGA from "react-ga4";
import { Loader } from "../../../../../../components/layout";

export default function ClubViewHeats({
  competitionId,
  setView,
  club,
  setSelectedCompetitor,
  showAge,
  clubViewSortOrder,
}) {
  const { screen } = useContext(ScreenContext);
  const [heats, setHeats] = useState([]);
  const [competitors, setCompetitors] = useState([]);
  useMemo(() => {
    ReactGA.set({ page_title: `${screen} - Clubs - Heats` });
  }, [screen]);

  const { loading, error, data } = useQuery(GET_CLUBVIEW_HEATS, {
    variables: {
      oid: club?.oid,
      competitionId: competitionId,
    },
  });

  useEffect(() => {
    if (data) {
      let newHeats = [];
      let newCompetitors = [];
      data.competitor?.forEach((competitor) => {
        newCompetitors.push({ ...competitor, lanes: [] });
      });

      data.heat.forEach((heat, i) => {
        newHeats.push({ ...heat, lanes: [] });
        heat?.lanes?.map((lane) => {
          let competitor = newCompetitors.find(
            (competitor) => competitor?.oid === lane?.competitor?.oid
          );
          let index = newCompetitors.findIndex(
            (competitor) => competitor?.oid === lane?.competitor?.oid
          );
          let newHeat = { ...lane, competitor: { ...competitor } };
          newHeats[i]?.lanes?.push({ ...newHeat });
          let competitorEntry = { ...lane, heat: heat, competitor: competitor };
          newCompetitors[index].lanes.push(competitorEntry);
        });
      });

      setHeats(newHeats);
      setCompetitors(newCompetitors);
    }
  }, [data]);

  return (
    <Grid container>
      <Grid container item height={loading ? 5000 : 'auto'}>
        {
          <QueryResult error={error} loading={loading} data={data}>
            {clubViewSortOrder === 1 && (
              <>
                {heats.length > 0 ? (
                  heats.map((heat) => (
                    <ClubViewHeatEventCard
                      key={heat?.id}
                      setView={setView}
                      heat={heat}
                      event={heat?.round?.event}
                      setSelectedCompetitor={setSelectedCompetitor}
                      showAge={showAge}
                    />
                  ))
                ) : (
                  <Box height={5000}>
                    <Loader />
                  </Box>
                )}
              </>
            )}
            {clubViewSortOrder === 0 && (
              <>
                {competitors.length > 0 ? (
                  competitors.map((competitor) => {
                    if (competitor?.lanes?.length > 0)
                      return (
                        <ClubViewHeatCompetitorCard
                          key={competitor?.id}
                          setView={setView}
                          competitor={competitor}
                          lanes={competitor?.lanes}
                          setSelectedCompetitor={setSelectedCompetitor}
                          showAge={showAge}
                        />
                      );
                  })
                ) : (
                  <Box height={5000}>
                    <Loader />
                  </Box>
                )}
              </>
            )}
          </QueryResult>
        }
      </Grid>
    </Grid>
  );
}
