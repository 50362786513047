import React from "react";

interface SuperliveLogoProps {
  width: number;
  height: number;
  fill: string;
  className?: string;
}

const SuperliveLogo: React.FC<SuperliveLogoProps> = ({
  width,
  height,
  fill,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 178.3 119.1"
      className={className}
      style={{ minWidth: `${width}px` }} // enforce minimum width
    >
      <path d="M163.59,0H15.29C6.88,0,0,6.88,0,15.29v70.46c0,7.68,6.28,13.96,13.96,13.96h67.31c2.03,0,3.7,1.66,3.7,3.7v6.45c0,2.12-1.74,3.86-3.86,3.86h-15.85c-1.82,0-3.3,1.48-3.3,3.3h0c0,1.89,1.53,3.42,3.42,3.42h47.98c1.64,0,2.98-1.34,2.98-2.98v-.44c0-1.82-1.48-3.3-3.3-3.3h-15.03c-2.21,0-4.02-1.81-4.02-4.02v-5.83c0-2.29,1.87-4.16,4.16-4.16h64.32c8.71,0,15.83-7.12,15.83-15.83V14.71c0-8.09-6.62-14.71-14.71-14.71ZM40.46,85.37c-2.96-.01-3.73-4.1-.98-5.18,15.97-6.27,40.85-16.5,42.94-20.17,3.75-6.59-13.73-25.54-18.28-28.36-2.48-1.54-18.64-.69-32.58.37-3.15.24-4.06-4.22-1.06-5.22,13.57-4.54,30.19-9.5,36.63-8.96,14.68,1.23,39.54,38.91,51.12,50.6,5.23,5.29,12.21,9.34,18.29,12.2,2.6,1.22,1.71,5.12-1.16,5.11l-94.92-.37ZM150.59,67.71c-11.9,6.07-24.87,2.94-28.97-6.99s2.22-22.9,14.11-28.98c11.9-6.07,24.87-2.94,28.97,6.99s-2.22,22.9-14.11,28.98Z" />
    </svg>
  );
};

export default SuperliveLogo;
