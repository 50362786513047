import React from "react";

import { Button, IconButton } from "@mui/material";

export default function ButtonComponent({
  title,
  icon,
  startIcon,
  endIcon,
  variant,
  color,
  onClick,
  disabled,
  sx,
}) {
  return (
    <>
      {icon ? (
        <IconButton onClick={onClick} sx={sx} disableRipple>
          {icon}
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          variant={variant}
          color={color}
          startIcon={startIcon}
          endIcon={endIcon}
          onClick={onClick}
          sx={sx}
        >
          {title}
        </Button>
      )}
    </>
  );
}
