import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { getEventLevel } from "../../../../../components/utils";
import { ScreenContext } from "../../../../../context/ScreenContext";
import { determineParaCode } from "../../../../../components/utils/determineParaCodes";

export default function SwimmerEntryCard({ entry, index, competitor }) {
  const { setScreen } = useContext(ScreenContext);

  const navigate = useNavigate();

  const params = useParams();

  const [eventLevel, setEventLevel] = useState(0);

  const eventType = entry?.event?.event_type;

  useEffect(() => {
    setEventLevel(getEventLevel(entry?.event?.event_competition_level));
  }, [entry]);
  
  return (
    <Grid
      container
      onClick={() => {
        navigate(
          `../competitions/${params.competitionName}/events/entries/${entry?.event?.rounds[0]?.time_program_entries[0]?.competition_session?.oid}/${entry?.event?.rounds[0]?.time_program_entries[0]?.oid}`
        );
        setScreen("events");
      }}
      sx={{
        borderRadius: 1,
        borderTop: index !== 0 && "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        bgcolor: "#E4E7EC",
        cursor: "pointer",
      }}
    >
      <Grid container item xs={12} sx={{ bgcolor: entry.reserve ? /* "lightgray" */ "whitesmoke" : "#FFF" }}>
        <Grid
          container
          item
          alignItems="center"
          py={0.5}
          sx={{ backgroundColor: "" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="32px"
            width="32px"
            ml="4px"
            mr="4px"
            sx={{
              bgcolor: entry.reserve ? "#E4E7EC" : "#F4C647",
              borderRadius: 2,
            }}
          >
            <Typography
              lineHeight={1}
              sx={{ display: eventLevel > 1 ? "none" : "inherit" }}
            >
              {entry.reserve ? "R" : entry?.ranking}
            </Typography>
          </Box>
          <Box
            display="flex"
            ml="4px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="32px"
            width="32px"
            sx={{
              bgcolor: "#374B5C",
              borderRadius: 2,
            }}
          >
            <Typography lineHeight={1} color="#FFF">
              {entry?.event?.number}
            </Typography>
          </Box>
          <Box
            display="flex"
            ml="8px"
            width="calc(100% - 248px)"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ bgcolor: "" }}
          >
            <Typography mb={-0.25} fontSize="0.9rem" lineHeight={1}>
              {entry?.event?.name}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={148}
            ml="8px"
            mr="8px"
            sx={{ bgcolor: "" }}
          >
            <Box display="flex" justifyContent="flex-start">
              {eventType &&
                competitor &&
                determineParaCode(eventType, competitor) && (
                  <Typography
                    lineHeight={1}
                    fontSize={"0.7em"}
                    mt={"2px"}
                    mr={"8px"}
                  >
                    {determineParaCode(eventType, competitor)}
                  </Typography>
                )}
            </Box>
            <Typography fontWeight={500}>{entry?.entry_time_text}</Typography>
            <Box display="flex" justifyContent="flex-start" width={42}>
              <Typography ml="8px" fontWeight={400} fontSize="0.8rem">
                {entry.reserve && "RES"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
