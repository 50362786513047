import React from "react";

import { useQuery } from "@apollo/client";

import { Typography, Box, Grid } from "@mui/material";

import CompetitionQualifiersCard from "./CompetitionQualifiersCard";
import QueryResult from "../../../../../components/containers/QueryResult";
import { GET_QUALIFIED_ENTRIES } from "../../../../../utils/graphql/queries";

export default function CompetitionQualifiers({
  competitionId,
  round,
  eventId,
  eventLevel,
  showAge,
  selectedListType,
}) {
  const sortOrder = round?.sort_order - 1;
  const orderVariable =
    eventLevel > 1
      ? { lane: { competitor: { full_name_reversed: "asc" } } }
      : { rank: "asc" };

  const { loading, error, data } = useQuery(GET_QUALIFIED_ENTRIES, {
    variables: {
      _competitionId: competitionId,
      _eventId: eventId,
      _sortOrder: sortOrder,
      order_by: orderVariable,
    },
    skip: !competitionId || !eventId || !sortOrder,
  });

  const qualifiedRanks = data?.round[0]?.summary_types
    .find((summary_type) => summary_type.id === selectedListType.id)
    ?.ranks.filter(
      (rank) =>
        rank?.lane?.qualification !== "" && rank?.lane?.qualification !== "-"
    );

  return (
    <QueryResult loading={loading} error={error} data={data}>
      {qualifiedRanks && qualifiedRanks?.length === 0 ? (
        <Typography
          fontWeight={500}
          fontSize={{ xs: "1rem", sm: "1.2rem" }}
          py={1}
          mt={1}
          mx={0.5}
        >
          No competitors in this list
        </Typography>
      ) : (
        <>
          <Grid
            container
            sx={{
              borderRadius: 1,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#D0D5DD",
              bgcolor: "#E4E7EC",
              boxShadow: 1,
            }}
          >
            <Grid
              container
              item
              xs={12}
              height={24}
              mt={0.25}
              alignItems="center"
              sx={{ bgcolor: "" }}
            >
              <Box
                display="flex"
                width={32}
                ml="8px"
                sx={{
                  bgcolor: "",
                  display: eventLevel > 1 ? "none" : "inherit",
                }}
              >
                <Typography fontSize="0.7rem" color="#667085">
                  Rank
                </Typography>
              </Box>
              <Box
                display="flex"
                width="calc(100% - 374px)"
                maxWidth="240px"
                ml="28px"
                sx={{ bgcolor: "" }}
              >
                <Typography fontSize="0.7rem" color="#667085">
                  Competitor
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                width={40}
                ml="8px"
                sx={{ bgcolor: "" }}
              >
                <Typography fontSize="0.7rem" color="#667085">
                  {showAge ? "Age" : "Year"}
                </Typography>
              </Box>
              <Box
                display="flex"
                minWidth="132px"
                width={
                  eventLevel > 1 ? "calc(100% - 442px)" : "calc(100% - 482px)"
                }
                ml="8px"
                sx={{ bgcolor: "" }}
              >
                <Typography fontSize="0.7rem" color="#667085">
                  Club
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                width={24}
                ml="8px"
                sx={{ bgcolor: "" }}
              >
                <Typography fontSize="0.7rem" color="#667085">
                  QA
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                width={72}
                ml="8px"
                mr="6px"
                sx={{ bgcolor: "" }}
              >
                <Typography fontSize="0.7rem" color="#667085">
                  Q Time
                </Typography>
              </Box>
            </Grid>
            {qualifiedRanks?.map((entry, index) => (
              <CompetitionQualifiersCard
                key={entry?.id}
                entry={entry}
                index={index}
                eventLevel={eventLevel}
                showAge={showAge}
              />
            ))}
          </Grid>
        </>
      )}
    </QueryResult>
  );
}
