import { useEffect, useMemo } from 'react';

import { ApolloError, DocumentNode, useLazyQuery } from '@apollo/client';
import parseData from '../../components/utils/parseData';

/**
 * This hook creates either a get request or a subscription to the graphql server.
 * @param documentNodes An object containing the initial document to fetch and the subscription document to be initialized.
 * @param variables An object of variables that may or may not be nesessary to run the queries.
 * @param condition Boolean representing if a subscription should be initiated.
 * @param refreshOn Key of the variables that is used to refresh the query. ( used in useEffect dependency array ).
 * @param onCompleted Function handler that handles what to to when a query is completed.
 * @param onError Function error handler.
 * @returns An object containing the data, loading state, refresh function and error.
 */

export default function useSubscribedFetch<T, V extends {}>(documentNodes: { initialDocument: DocumentNode, subscriptionDocument?: DocumentNode; }, variables: V, condition: boolean, refreshOn: keyof V, onCompleted?: (data: any) => void, onError?: (err: any) => void): { loading: boolean, error: ApolloError | undefined, data: T | undefined, refresh: () => void; } {

    const [refresh, { data, loading, error, subscribeToMore }] = useLazyQuery<Record<string, any | undefined>>(documentNodes.initialDocument, {
        variables,
        onCompleted: onCompleted,
        onError: onError,
    });

    //perform new fetch when refreshOn updates
    useMemo(() => {
        if (variables[refreshOn as keyof V]) {
            refresh();
        }
    }, [variables[refreshOn as keyof V]]);

    //trigger subscription if condition is true and subscription document exists
    useEffect(() => {
        if (condition && documentNodes.subscriptionDocument && objectToArray(variables).every(variable => variable !== undefined)) {
            subscribeToMore<Record<string, T | undefined>>({
                document: documentNodes.subscriptionDocument,
                variables,
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    return subscriptionData?.data;
                }
            });
        }
    }, [...objectToArray(variables), condition]);

    return { data: parseData<T>(data), loading, error, refresh };
}

const objectToArray = <T extends Record<string, any>>(obj: T): any[] => {
    let arr: any[] = [];
    Object.keys(obj).forEach(key => {
        arr.push(obj[key as keyof T]);
    });
    return arr;
};
