import { useState, useEffect, useContext } from "react";

import { DocumentNode } from "graphql";
import { OperationVariables } from "@apollo/client";

import client from "../../utils/apollo/apollo-client";
import parseData from "../../components/utils/parseData";
import { CompetitionDetailsContext } from "../../context/CompetitionDetailsContext";

/**
 * DOCUMENT TYPE MUST BE A SUBSCRIPTION!
 * @param documentNode
 * @param variables
 * @param condition
 * @param refreshOn
 * @returns
 */

export default function useSubscribeIf<
  T = any,
  TVariables extends OperationVariables = OperationVariables
>(
  documentNode: DocumentNode,
  variables: TVariables,
  condition: boolean,
  refreshOn: keyof TVariables
): {
  data: any;
  error: any;
  loading: boolean;
  unsub: () => void;
  restartSub: () => void;
} {
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [unsub, setUnsub] = useState<{ unsub: () => void }>({
    unsub: () => {},
  });
  const [restartSub, setRestartSub] = useState<boolean>(false);
  const { shouldSubscribe } = useContext(CompetitionDetailsContext);

  const startSubscription = () => {
    const observer = client.subscribe({
      query: documentNode,
      variables,
    });
    const subscription = observer.subscribe(({ data, errors }) => {
      setData(data);
      setError(errors);
    });

    return subscription;
  };

  useEffect(() => {
    if (
      (condition || restartSub) &&
      variables[refreshOn as keyof TVariables] &&
      shouldSubscribe
    ) {
      const subscription = startSubscription();

      const unsub = () => {
        subscription.unsubscribe();
      };

      setUnsub({ unsub });
      if (restartSub) setRestartSub(false);
    }
    setLoading(false);
    return () => unsub.unsub();
  }, [variables[refreshOn as keyof TVariables], condition, restartSub]);

  return {
    data: parseData<T>(data),
    error,
    loading,
    unsub: unsub.unsub,
    restartSub: () => setRestartSub(true),
  };
}
