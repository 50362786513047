export default function isCompetitionActive(timesData: Competition | undefined): boolean {
    
    if (!timesData) return false;
    const currentDate = Date.now();
    const startDateObj = timesData?.startDate?.split('-').map(n => Number(n));
    const endDateObj = timesData?.endDate?.split('-').map(n => Number(n));
    let startDate: number, endDate: number;
    if (startDateObj && endDateObj) {
        startDate = new Date(startDateObj[0], startDateObj[1] - 1, startDateObj[2]).getTime();
        endDate = new Date(endDateObj[0], endDateObj[1] - 1, endDateObj[2]).getTime() + (24*60*60*1000);
    } else {
        startDate = 0;
        endDate = 0;
    }
    //console.log(new Date(startDate), new Date(currentDate) , new Date(endDate));
    return timesData && (startDate <= currentDate && endDate >= currentDate);
}