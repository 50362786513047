import React, { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import ClubViewSummaryEventCard from './ClubViewSummaryEventCard';
import { GET_CLUBVIEW_SUMMARY } from '../../../../../../utils/graphql/queries';
import QueryResult from '../../../../../../components/containers/QueryResult';
import { ScreenContext } from '../../../../../../context/ScreenContext';
import ReactGA from "react-ga4";


export default function ClubViewSummary({ competitionId, setView, club, setSelectedCompetitor, showAge, clubViewSortOrder }) {

    const {screen} = useContext(ScreenContext);
    useMemo(()=>{
        ReactGA.set({ page_title: `${screen} - Clubs - Summary`});
    },[screen])

    const { loading, error, data } = useQuery(GET_CLUBVIEW_SUMMARY, {
        variables: {
            oid: club?.oid,
            competitionId: competitionId
        }
    });

    return (
        <Grid container>
            <Grid container item height={loading ? 5000 : 'auto'} sx={{ bgcolor: '' }}>
                <QueryResult error={error} loading={loading} data={data}>
                    {/* {clubViewSortOrder === 0 && */}
                    <>
                        {data?.round.map((round, index, arr) => {
                            return <ClubViewSummaryEventCard key={index} round={round} heats={round?.heats} event={round?.event} setView={setView} setSelectedCompetitor={setSelectedCompetitor} showAge={showAge} />;
                        })}
                    </>
                    {/* } */}
                    {/* {clubViewSortOrder === 1 &&
                        <>
                            {data?.competitor.map(competitor => (
                                competitor?.lanes?.length > 0 && <ClubViewSummaryCompetitorCard key={competitor?.id} competitor={competitor} lanes={competitor?.lanes} showAge={showAge} />
                            ))}
                        </>
                    } */}
                </QueryResult>
            </Grid>
        </Grid>
    );
}