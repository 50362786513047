export type Split = Record<string, number | '+' | '-' | '='>;

export const calculateSplits = (splitsAmount: number, sub_results: SubResult[], distance?: number, lane?: Lane,): Split => {
    const temp: string[] = [];
    const maxArrayLength= 1000;
    
    // Ensure splitsAmount does not exceed the maximum length
    const numSplits = Math.min(splitsAmount, maxArrayLength);

    for (let i = 0; i < numSplits; i++) {
        temp.push('');
    }
    
    let splits: Split = {}
    temp.forEach((_, i) => {
        const splitKey = 50*(2*(i)) /*//! test changed from 50*(2*(i+1)) */ //This creates dynamic times that doubles every distance, eg: 50, 100, 200, 400...
        //const splitKey = 50*(i+1); //This creates dynamic times every 50 meters
        let newSplit: Split = {};
        const initialResult = sub_results.find((result) => result.done_at === splitKey)?.split_diff || 0
        const currentResult = sub_results?.find(res => res.done_at === splitKey)?.result_value || 0;
        if(currentResult){
            newSplit = {
                current50: splitKey % 50 === 0 
                ? initialResult 
                : currentResult - getPreviousSplit(sub_results, splitKey, 50),
                prev50: getPreviousSplit(sub_results, splitKey, 50) 
                - getPreviousSplit(sub_results, splitKey - 50, 50),
            
                current100: splitKey % 100 === 0 
                ? currentResult - getPreviousSplit(sub_results, splitKey, 100)
                : newSplit.current100,
                prev100: getPreviousSplit(sub_results, splitKey, 100) 
                - getPreviousSplit(sub_results, splitKey - 100, 100),

                current200: splitKey % 200 === 0
                ? currentResult - getPreviousSplit(sub_results, splitKey, 200)
                : newSplit.current200,
                prev200: getPreviousSplit(sub_results, splitKey, 200) 
                - getPreviousSplit(sub_results, splitKey - 200, 200),

                /* Use this to dynamically create more keys of currents and prevs eg: current400, prev 400, current800, prev800... 
                [`currentResult${splitKey}`]:  currentResult - getPreviousSplit(sub_results, splitKey, splitKey),
                [`prev${splitKey}`]: getPreviousSplit(sub_results, splitKey, 50*i) 
                - getPreviousSplit(sub_results, splitKey, 50*i*2),*/
            }
            newSplit.change50 = Number(newSplit.current50) - Number(newSplit.prev50) === 0 || Number(newSplit.current200) - Number(newSplit.prev200) === newSplit.current50 ? '=' : Number(newSplit.current50) - Number(newSplit.prev50) > 0 ? '+' : '-';
            newSplit.change100 = Number(newSplit.current100) - Number(newSplit.prev100) === 0 || Number(newSplit.current200) - Number(newSplit.prev200) === newSplit.current100 ? '=' : Number(newSplit.current100) - Number(newSplit.prev100) > 0 ? '+' : '-';
            newSplit.change200 = Number(newSplit.current200) - Number(newSplit.prev200) === 0 || Number(newSplit.current200) - Number(newSplit.prev200) === newSplit.current200 ? '=' : Number(newSplit.current200) - Number(newSplit.prev200) > 0 ? '+' : '-';
        }
        splits = {...splits, ...newSplit}
    });
    return splits;
}

const getPreviousSplit = (sub_results: SubResult[], splitKey: number, splitsAmount: number): number => {
    const num = sub_results?.find(res => res.done_at === (splitKey - splitsAmount))?.result_value
    return num ? num : 0;
}
