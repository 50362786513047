import React from 'react';

import {
    Box,
    Typography,
    Grid,
} from '@mui/material';

import { BsArrowDownCircle } from "react-icons/bs";
import { GrDocumentDownload } from "react-icons/gr";
import { GrDocument } from "react-icons/gr";
import { GrDocumentTime } from "react-icons/gr";
import { GrDocumentCsv } from "react-icons/gr";
import { GrDocumentExcel } from "react-icons/gr";
import { GrDocumentImage } from "react-icons/gr";
import { GrDocumentPdf } from "react-icons/gr";
import { GrDocumentWord } from "react-icons/gr";
import { GrDocumentZip } from "react-icons/gr";
import { GrDocumentMissing } from "react-icons/gr";
import { getDateTime, openInNewTab } from '../../../../components/utils';

export default function FileList({ files } : {files: FileArchive[]}) {

    const getIcon = (linkText : any) => {
        const type = linkText.slice(-3);
        if (type === 'lef') return <GrDocumentTime size='1.2rem' />;
        if (type === 'pdf') return <GrDocumentPdf size='1.2rem' />;
        if (type === 'png' || type === 'jpg') return <GrDocumentImage size='1.2rem' />;
        if (type === '') return <GrDocumentMissing size='1.2rem' />
        else return <GrDocument size='1.2rem' />
    }

    return (
        <>
            <Grid container width='100%'>
                {files?.map((file, index) => (
                    <Grid container item alignItems='center' xs={12} key={file.id} py='4px'
                        //@ts-ignore
                        sx={{
                            borderTopStyle: index !== 0 && 'solid',
                            borderWidth: 1,
                            borderColor: '#D0D5DD'
                        }}>
                        <Grid item display='flex' alignItems='center' justifyContent='center' width={40} ml='4px'>
                            <Box display='flex'
                                justifyContent='center'
                                alignItems='center'
                                height='32px'
                                width='32px'
                                color='#FFF'
                                sx={{
                                    bgcolor: '#374B5C',
                                    borderRadius: 2,
                                    color: '#FFF',
                                    cursor: file.link && 'pointer'
                                }}
                                onClick={() => openInNewTab(file.link)}
                            >
                                {/* Workaround for GR icons coloring */}
                                <Box display='flex' justifyContent='center' alignItems='center' mr={0.25}>
                                    <style>
                                        {`svg path {stroke: #FFF}`}
                                    </style>
                                    {getIcon(file.link)}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item width='calc(50% - 174px)' ml='8px' sx={{ cursor: file.link && 'pointer' }} onClick={() => openInNewTab(file.link)}>
                            <Typography noWrap>{file.name}</Typography>
                        </Grid>
                        <Grid item width='calc(50% - 126px)' ml='8px'>
                            <Typography noWrap>{file.description}</Typography>
                        </Grid>
                        <Grid item display='flex' justifyContent='center' width={56} ml='8px' sx={{ bgcolor: '' }}>
                            {!file.link ?
                                null
                                :
                                <Box sx={{ cursor: 'pointer' }} onClick={() => openInNewTab(file.link)}>
                                    <BsArrowDownCircle color='#667085' size='1rem' />
                                </Box>
                            }
                        </Grid>
                        <Grid item width={160} ml='8px' mr='8px' display='flex' justifyContent='flex-end' sx={{ bgcolor: '' }}>
                            <Typography fontSize='0.8rem' noWrap>{getDateTime(file.created_at)}</Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
