import React from 'react';
import { useQuery } from '@apollo/client';
import {
    Box,
    Typography,
    Grid,
} from '@mui/material';
import { BsFillInboxesFill } from 'react-icons/bs';
import QueryResult from '../../../components/containers/QueryResult';
import { GET_COMPETITION_FILES } from '../../../utils/graphql/queries';
import EmptyCompetitionFileArchive from './components/EmptyCompetitionFileArchive';
import FileList from './components/FileList';


export default function FileArchive({ competitionId } : {competitionId: string}) {

    const { loading, error, data } = useQuery<Record<"file_archive", FileArchive[]>>(GET_COMPETITION_FILES, {
        variables: { _eq: competitionId }
    });
    
    return (
        <QueryResult loading={loading} error={error} data={data}>
            {data?.file_archive && data?.file_archive?.length > 0 ?
                <Box width='100%' sx={{ bgcolor: '' }}>
                    <Box sx={{ bgcolor: '' }}>
                        <Grid container display='flex'>
                            <Box display='flex' justifyContent='flex-start' alignItems='center' my='auto' width='100%' gap={1} py={1} sx={{ color: '#FFF', bgcolor: '#374B5C', borderRadius: '6px 6px 0px 0px', boxShadow: 2 }}>
                                <Box ml={1} display='flex' justifyContent='center' alignItems='center' sx={{ bgcolor: '' }}>
                                    <BsFillInboxesFill size='1rem' className='react-icons' />
                                </Box>
                                <Typography fontWeight={400} fontSize={{ xs: '1rem', sm: '1.1rem', md: '1.25rem' }}>File Archive</Typography>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} height={24} mx={0} alignItems='center' sx={{ bgcolor: '#E4E7EC' }}>
                            <Box display='flex' justifyContent='center' width={40} ml='4px' sx={{ bgcolor: '' }}>
                                <Typography fontSize='0.7rem' color='#667085'>Type</Typography>
                            </Box>
                            <Box display='flex' width='calc(50% - 174px)' ml='8px' sx={{ bgcolor: '' }}>
                                <Typography fontSize='0.7rem' color='#667085'>Name</Typography>
                            </Box>
                            <Box display='flex' width='calc(50% - 126px)' ml='8px' sx={{ bgcolor: '' }}>
                                <Typography fontSize='0.7rem' color='#667085'>Description</Typography>
                            </Box>
                            <Box display='flex' justifyContent='center' alignItems='center' width={56} ml='8px' gap={0.5} sx={{ bgcolor: '' }}>
                                <Typography fontSize='0.7rem' color='#667085'>Download</Typography>
                            </Box>
                            <Box display='flex' justifyContent='flex-end' width={160} ml='8px' mr='8px' sx={{ bgcolor: '' }}>
                                <Typography fontSize='0.7rem' color='#667085'>Uploaded</Typography>
                            </Box>
                        </Grid>
                        <FileList files={data?.file_archive} />
                    </Box>
                </Box>
                :
                <EmptyCompetitionFileArchive />
            }
        </QueryResult>
    )
}
