import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Box, Grid, Typography, Divider } from "@mui/material";

import { BsPeopleFill, BsPersonFill } from "react-icons/bs";
import ClubViewEntriesListCard from "./ClubViewEntriesListCard";
import { FlagComponent } from "../../../../../../components/layout";
import {
  competitorNavigationString,
  getBirthYear,
} from "../../../../../../components/utils";

export default function ClubViewEntriesList({
  setView,
  competitor,
  setSelectedCompetitor,
  showAge,
}) {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Grid
      container
      item
      mt={1}
      sx={{
        borderRadius: 1,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        bgcolor: "#E4E7EC",
        boxShadow: 1,
      }}
    >
      <Grid
        container
        item
        justifyContent="flex-start"
        m="4px"
        onClick={() => {
          setSelectedCompetitor(competitor);
          navigate(
            competitorNavigationString(
              params.competitionName,
              competitor?.club?.oid,
              competitor?.oid
            )
          );
          setView(3);
        }}
        sx={{ cursor: "pointer" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="auto"
          height="2.8rem"
          width="2.8rem"
          sx={{
            bgcolor: "#374B5C",
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            {competitor?.is_relay ? (
              <BsPeopleFill color="#FFF" size="2rem" />
            ) : (
              <BsPersonFill color="#FFF" size="2rem" />
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography mx={1} fontWeight={700} fontSize="1.1rem">
            {competitor?.full_name}
          </Typography>
          <Box display="flex" alignItems="center" mx={1} gap={0.5}>
            <Box
              width={16}
              height={12}
              display="flex"
              alignItems="center"
              mt="-0.85px"
              sx={{ boxShadow: 2, borderRadius: 1, bgcolor: "" }}
            >
              <FlagComponent size="s" countryCode={competitor?.country_code} />
            </Box>
            <Typography fontSize="0.8rem" lineHeight={1}>
              {competitor?.country_code}
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{ bgcolor: "#98A2B3" }}
            />
            <Typography fontSize="0.8rem" lineHeight={1}>
              {showAge ? competitor?.age : getBirthYear(competitor?.birthday)}
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{ bgcolor: "#98A2B3" }}
            />
            <Typography fontSize="0.8rem" lineHeight={1}>
              {competitor?.club?.name}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          container
          item
          xs={12}
          height={24}
          mt={0.25}
          alignItems="center"
          sx={{ bgcolor: "" }}
        >
          <Box
            display="flex"
            width="calc(100% - 362px)"
            maxWidth="282px"
            ml="6px"
            sx={{ bgcolor: "" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              Event
            </Typography>
          </Box>
          <Box
            display="flex"
            // flex="1"
            minWidth="202px"
            width="calc(100% - 400px)"
            ml="10px"
            sx={{ bgcolor: "" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              Club
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={42}
            ml="18px"
            mr="6px"
            sx={{ bgcolor: "" }}
          >
            <Typography fontSize="0.7rem" color="#667085">
              Time
            </Typography>
          </Box>
        </Grid>
        {competitor?.entries?.length !== 0 &&
          competitor?.entries.map(
            (entry, index) =>
              entry && (
                <ClubViewEntriesListCard
                  key={entry?.id}
                  index={index}
                  entry={entry}
                  competitor={competitor}
                />
              )
          )}
      </Grid>
    </Grid>
  );
}
