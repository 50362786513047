import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  TableRow,
  Box,
  Divider,
  Grid,
  useMediaQuery,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  MainTableLayout,
  ButtonComponent,
  Breadcrumbs,
} from "../../components/layout";
import MetaDecorator from "../../components/utils/MetaDecorator";
import { AUClient, EUClient } from "../../utils/apollo/apollo-client";
import QueryResult from "../../components/containers/QueryResult";
import {
  GET_ALL_COMPETITIONS,
  GET_FILTERED_COMPETITIONS,
} from "../../utils/graphql/queries";
import Header from "../../components/Header";
import NoFilteredResults from "../../components/NoFilteredResults";
import RegionSelection from "../../components/RegionSelection";
import DetailedCompetitionCard from "../../components/containers/DetailedCompetitionCard";
import { GET_ALL_COMPETITIONS_BY_DATE } from "../../utils/graphql/queries";

const getMonthName = (monthNumber) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[monthNumber];
};
const getYear = (year) => {
  const years = [
    /*2015, 2016, 2017, 2018, 2019, 2020, 2021,*/ 2022, 2023, 2024, 2025,
  ].reverse();
  return years[year];
};
export default function List() {
  const [selectedRegion, setSelectedRegion] = useState(() => {
    if (localStorage.getItem("region") === null) {
      return 1;
    } else {
      try {
        JSON.parse(localStorage.getItem("region"));
        //region previously named 0
        if (JSON.parse(localStorage.getItem("region")) === 0) {
          return 1;
        } else return JSON.parse(localStorage.getItem("region"));
      } catch (e) {
        localStorage.removeItem("region");
        return 1;
      }
    }
  });

  const [selectedCountry, setSelectedCountry] = useState(() => {
    if (localStorage.getItem("country") === null) {
      return "ALL";
    } else {
      try {
        JSON.parse(localStorage.getItem("country"));
        return JSON.parse(localStorage.getItem("country"));
      } catch (e) {
        localStorage.removeItem("country");
        return "ALL";
      }
    }
  });

  const [offset, setOffset] = useState(0);
  const [filterEuOffset, setFilterEuOffset] = useState(0);
  const [filterAuOffset, setFilterAuOffset] = useState(0);
  const [euCompetitions, setEuCompetitions] = useState([]);
  const [auCompetitions, setAuCompetitions] = useState([]);
  const [euListLength, setEuListLength] = useState(0);
  const [auListLength, setAuListLength] = useState(0);
  const [euPrevListLength, setEuPrevListLength] = useState(0);
  const [combinedCompetitions, setCombinedCompetitions] = useState([]);
  const [filteredCompetitions, setFilteredCompetitions] = useState([]);
  const [prevAu2, setPrevAu2] = useState([]);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [filteredListLength, setFilteredListLength] = useState(0);
  // Year and month filtering
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  // Picking start date in dropdown picker
  const startDateVariable = `${getYear(selectedYear - 1)}-${
    selectedMonth >= 10 ? selectedMonth : `0${selectedMonth}`
  }-01`;
  const endDateVariable = `${getYear(selectedYear - 1)}-${
    selectedMonth === 12
      ? `12-31`
      : selectedMonth < 9
      ? `0${selectedMonth + 1}-01`
      : `${selectedMonth + 1}-01`
  }`;

  const [euFetch, { loading: euLoading }] = useLazyQuery(GET_ALL_COMPETITIONS, {
    client: EUClient,
    fetchPolicy: "network-only",
    variables: {
      limit: 15,
      offset: offset,
    },
    onCompleted: (data) => {
      const euComps = data?.competitions.map((competition) => ({
        ...competition,
        database_region: 2,
      }));
      setEuCompetitions(euComps);
    },
  });

  const [auFetch, { loading: auLoading }] = useLazyQuery(GET_ALL_COMPETITIONS, {
    client: AUClient,
    fetchPolicy: "network-only",
    variables: {
      limit: 15,
      offset: offset,
    },
    onCompleted: (data) => {
      const auComps = data?.competitions.map((competition) => ({
        ...competition,
        database_region: 3,
      }));
      setAuCompetitions(auComps);
    },
  });

  const [filteredFetch, { loading: filteredLoading }] = useLazyQuery(
    GET_FILTERED_COMPETITIONS,
    {
      client: selectedRegion === 2 ? EUClient : AUClient,
      fetchPolicy: "network-only",
      variables: {
        limit: 15,
        offset: offset,
        nation: selectedCountry,
        startDate_gte:
          selectedYear === ""
            ? "2020-01-01"
            : selectedMonth === ""
            ? `${getYear(selectedYear - 1)}-01-01`
            : startDateVariable,
        startDate_lt:
          selectedYear === ""
            ? "2100-01-01"
            : selectedMonth === "" && selectedYear !== 1
            ? `${getYear(selectedYear - 2)}-01-01`
            : selectedMonth === "" && selectedYear === 1
            ? `${getYear(selectedYear - 1)}-12-31`
            : endDateVariable,
      },
      onCompleted: (data) => {
        const filteredComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: selectedRegion,
        }));
        setFilteredListLength(filteredComps.length);
        const groupedByMonth = filteredComps.reduce(
          (groupedByMonth, competition) => {
            const month =
              getMonthName(Number(competition.startDate.split("-")[1] - 1)) +
              " " +
              competition.startDate.split("-")[0];
            if (!groupedByMonth[month]) {
              groupedByMonth[month] = [];
            }
            groupedByMonth[month].push(competition);
            return groupedByMonth;
          },
          {}
        );
        const finalGroup = Object.keys(groupedByMonth).map((month) => {
          return {
            month,
            competitions: groupedByMonth[month],
          };
        });
        setFilteredCompetitions(finalGroup);
      },
    }
  );

  /* HANDLE REGION CHANGE */
  useEffect(() => {
    selectedRegion &&
      localStorage.setItem("region", JSON.stringify(selectedRegion));
    if (selectedRegion === 1) {
      euFetch();
      auFetch();
    } else {
      filteredFetch();
    }
  }, [selectedRegion]);

  /* HANDLE COUNTRY CHANGE */
  useEffect(() => {
    selectedCountry &&
      localStorage.setItem("country", JSON.stringify(selectedCountry));
  }, [selectedCountry]);

  /* HANDLE COMBINED FETCH RESULTS */
  useEffect(() => {
    if (!euDateLoading) {
      if (
        (euCompetitions?.length > 0 || auCompetitions?.length > 0) &&
        selectedYear === "" &&
        selectedMonth === ""
      ) {
        const combinedResults = [...euCompetitions, ...auCompetitions];

        const sortedCombinedResults = combinedResults.sort(
          (a, b) =>
            (new Date(b?.startDate).getTime() || -Infinity) -
            (new Date(a?.startDate).getTime() || -Infinity)
        );
        sortedCombinedResults?.length > 0 &&
          setCombinedCompetitions(sortedCombinedResults);
      } else if (
        euCompetitions?.length > 0 &&
        auCompetitions?.length > 0 &&
        (selectedYear !== "" || selectedMonth !== "")
      ) {
        const sortedEuComps = euCompetitions.sort(
          (a, b) =>
            (new Date(b?.startDate).getTime() || -Infinity) -
            (new Date(a?.startDate).getTime() || -Infinity)
        );
        const highestEuComp = sortedEuComps[0].startDate;
        const lowestEuComp = sortedEuComps[sortedEuComps.length - 1].endDate;

        const sortedAuComps = auCompetitions.filter((comp) =>
          euCompetitions?.length >= 30
            ? comp.startDate < highestEuComp && comp.startDate > lowestEuComp
            : comp
        );

        setEuListLength(euCompetitions.length);
        setAuListLength(sortedAuComps.length);

        const combinedResults = [...euCompetitions, ...sortedAuComps];

        const sortedCombinedResults = combinedResults.sort(
          (a, b) =>
            (new Date(b?.startDate).getTime() || -Infinity) -
            (new Date(a?.startDate).getTime() || -Infinity)
        );
        sortedCombinedResults?.length > 0 &&
          setCombinedCompetitions(sortedCombinedResults);
      } else if (euCompetitions?.length > 0 && auCompetitions?.length < 1) {
        setCombinedCompetitions(euCompetitions);
      } else if (euCompetitions?.length < 1 && auCompetitions?.length > 0) {
        setCombinedCompetitions(auCompetitions);
      }
    }
  }, [euCompetitions, auCompetitions]);

  const handleNext = () => {
    if (selectedRegion === 1 && combinedCompetitions?.length >= 15) {
      if (selectedYear === "" && selectedMonth === "") {
        setOffset(offset + 15);
      } else {
        setFilterEuOffset(filterEuOffset + euListLength);
        setEuPrevListLength(euListLength);
        setPrevAu2([...prevAu2, auListLength]);
        setFilterAuOffset(prevAu2.reduce((acc, curr) => acc + curr, 0));
      }
    } else if (selectedRegion !== 1 && filteredListLength >= 15) {
      if (selectedYear === "" && selectedMonth === "") {
        setOffset(offset + 15);
      } else {
        setFilterEuOffset(filterEuOffset + euListLength);
        setEuPrevListLength(euListLength);
        setPrevAu2([...prevAu2, auListLength]);
        setFilterAuOffset(prevAu2.reduce((acc, curr) => acc + curr, 0));
      }
    }
  };

  useEffect(() => {
    setFilterAuOffset(prevAu2.reduce((acc, curr) => acc + curr, 0));
  }, [prevAu2]);

  const handlePrevious = () => {
    if (offset !== 0) {
      setOffset(offset - 15);
    }
    if (filterEuOffset !== 0) {
      setFilterEuOffset(filterEuOffset - euPrevListLength);
    }
    if (filterAuOffset !== 0) {
      setPrevAu2(prevAu2.slice(0, -1));
      setFilterAuOffset(prevAu2.reduce((acc, curr) => acc + curr, 0));
    }
  };

  const handleDisableNextButton = () => {
    if (selectedRegion === 1) {
      if (selectedYear !== "" || selectedMonth !== "") {
        return combinedCompetitions?.length >= 30 ? false : true;
      } else {
        return combinedCompetitions?.length >= 15 ? false : true;
      }
    } else {
      if (selectedYear === "" || selectedMonth === "") {
        return filteredListLength === 15 ? false : true;
      } else {
        return filteredListLength === 30 ? false : true;
      }
    }
  };

  /* RESET OFFSET ON COUNTRY AND REGION CHANGE */
  useEffect(() => {
    setOffset(0);
    setFilterEuOffset(0);
    setFilterAuOffset(0);
  }, [selectedRegion, selectedCountry]);

  const loading =
    selectedRegion === 1
      ? (!combinedCompetitions && euLoading) || auLoading
      : filteredLoading;

  // Initialize startDate and endDate with empty strings
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Function to calculate the start date based on selected year and month
  const getStartDate = () => {
    if (selectedYear && selectedMonth) {
      return new Date(selectedYear, selectedMonth - 1, 1).toLocaleDateString(
        "sv-SE"
      );
    }
    return "";
  };

  // Function to calculate the end date based on selected year and month
  const getEndDate = () => {
    if (selectedYear && selectedMonth) {
      const nextMonth = selectedMonth === 12 ? 1 : selectedMonth + 1;
      const nextYear = selectedMonth === 12 ? selectedYear + 1 : selectedYear;
      return new Date(nextYear, nextMonth - 1, 1).toLocaleDateString("sv-SE");
    }
    return "";
  };

  // Update startDate and endDate whenever selectedYear or selectedMonth changes
  useEffect(() => {
    setStartDate(getStartDate());
    setEndDate(getEndDate());
  }, [selectedYear, selectedMonth]);

  const [euEmpty, setEuEmpty] = useState(false);
  const [auEmpty, setAuEmpty] = useState(false);

  // Handle competitions by date
  const [euDateFetch, { data: euData, loading: euDateLoading }] = useLazyQuery(
    GET_ALL_COMPETITIONS_BY_DATE,
    {
      client: EUClient,
      fetchPolicy: "network-only",
      variables: {
        offset: filterEuOffset,
        startDate_gte:
          selectedMonth === ""
            ? `${getYear(selectedYear - 1)}-01-01`
            : startDateVariable,
        startDate_lt:
          selectedMonth === "" && selectedYear !== 1
            ? `${getYear(selectedYear - 2)}-01-01`
            : selectedMonth === "" && selectedYear === 1
            ? `${getYear(selectedYear - 1)}-12-31`
            : endDateVariable,
      },

      onCompleted: (data) => {
        const euComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: 2,
        }));
        if (data.competitions.length === 0) {
          setEuEmpty(true);
        } else {
          setEuEmpty(false);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [auDateFetch, { data: auData, loading: auDateLoading }] = useLazyQuery(
    GET_ALL_COMPETITIONS_BY_DATE,
    {
      client: AUClient,
      fetchPolicy: "network-only",
      variables: {
        offset: filterAuOffset,
        startDate_gte:
          selectedMonth === ""
            ? `${getYear(selectedYear - 1)}-01-01`
            : startDateVariable,
        startDate_lt:
          selectedMonth === "" && selectedYear !== 1
            ? `${getYear(selectedYear - 2)}-01-01`
            : selectedMonth === "" && selectedYear === 1
            ? `${getYear(selectedYear - 1)}-12-31`
            : endDateVariable,
      },
      onCompleted: (data) => {
        setShowEmptyState(
          euData?.competitions.length === 0 && data.competitions.length === 0
        );
        const auComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: 2,
        }));
        if (euData?.competitions.length !== 0) {
          setEuCompetitions(euData.competitions);
        }
        if (data.competitions.length === 0) {
          setAuEmpty(true);
        } else {
          setAuEmpty(false);
          if (euData?.competitions.length === 0) {
            setEuCompetitions([]);
            setCombinedCompetitions(data.competitions);
          }
        }
        !(euEmpty && auEmpty) && setAuCompetitions(auComps);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    if (selectedYear) {
      if (selectedRegion === 1) {
        euDateFetch();
        auDateFetch();
      } else {
        filteredFetch();
      }
    }
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    /* setSelectedYear("");
    setSelectedMonth(""); */
  }, [selectedRegion, selectedCountry]);

  const [scrolled, setScrolled] = useState(false);
  const [select1Open, setSelect1Open] = useState(false);
  const [select2Open, setSelect2Open] = useState(false);

  return (
    <>
      <MetaDecorator
        title="Swimify - All Competitions"
        description="Swimify All Competitions Page"
      />
      <Header />
      <MainTableLayout
        title={<Breadcrumbs />}
        lowerButton1={
          <ButtonComponent
            disabled={offset || filterEuOffset || filterAuOffset ? false : true}
            variant={"grey"}
            icon={
              useMediaQuery("(max-width:599px)") && (
                <ArrowBackIosIcon sx={{ height: "1.2rem" }} />
              )
            }
            startIcon={<ArrowBackIosIcon sx={{ height: 12 }} />}
            title={useMediaQuery("(min-width:600px)") && "Previous"}
            onClick={() => handlePrevious()}
            sx={{ my: "auto", fontSize: "0.75rem", width: 100 }}
          />
        }
        countryFilter={
          <RegionSelection
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        }
        dropDown={
          /*  selectedRegion === 1 && */ <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              width: "100%",
              gap: 2,
              marginY: 1,
            }}
          >
            <Select
              displayEmpty
              MenuProps={{
                disableScrollLock: true,
              }}
              value={selectedYear}
              onChange={(e) => (
                setSelectedYear(e.target.value),
                setFilterEuOffset(0),
                setFilterAuOffset(0),
                setEuPrevListLength(0),
                setFilterAuOffset(0)
              )}
              sx={{
                height: 25,
                my: "auto",
                fontSize: "0.8rem",
                fontWeight: "500",
                letterSpacing: "0.4px",
              }}
              onOpen={() => (setScrolled(false), setSelect1Open(true))}
              onClose={() => setSelect1Open(false)}
              onWheelCapture={() =>
                !scrolled && (setScrolled(true), setSelect1Open(false))
              }
              open={!scrolled && select1Open}
            >
              <MenuItem value="" disabled>
                Select year
              </MenuItem>
              {Array.from({ length: 4 }, (_, i) => (
                <MenuItem key={i} value={i + 1} sx={{ fontSize: "1rem" }}>
                  {getYear(i)}
                </MenuItem>
              ))}
            </Select>

            <Select
              displayEmpty
              MenuProps={{
                disableScrollLock: true,
              }}
              value={selectedMonth}
              onChange={(e) => (
                setSelectedMonth(e.target.value),
                setFilterEuOffset(0),
                setFilterAuOffset(0),
                setEuPrevListLength(0),
                setFilterAuOffset(0)
              )}
              sx={{
                height: 25,
                my: "auto",
                fontSize: "0.8rem",
                fontWeight: "500",
                letterSpacing: "0.4px",
              }}
              onOpen={() => (setScrolled(false), setSelect2Open(true))}
              onClose={() => setSelect2Open(false)}
              onWheelCapture={() =>
                !scrolled && (setScrolled(true), setSelect2Open(false))
              }
              open={!scrolled && select2Open}
            >
              <MenuItem value="" disabled>
                Select month
              </MenuItem>
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i + 1} value={i + 1} sx={{ fontSize: "1rem" }}>
                  {getMonthName(i)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
        lowerButton4={
          <ButtonComponent
            disabled={handleDisableNextButton()}
            variant={"grey"}
            icon={
              useMediaQuery("(max-width:599px)") && (
                <ArrowForwardIosIcon sx={{ height: "1.2rem" }} />
              )
            }
            endIcon={<ArrowForwardIosIcon sx={{ height: 12 }} />}
            title={"Next"}
            onClick={() => handleNext()}
            sx={{ my: "auto", fontSize: "0.75rem", width: 100 }}
          />
        }
      >
        <Grid item xs={12} sx={{ bgcolor: "white" }}>
          <Box>
            <Box>
              <Divider />
              {showEmptyState ? (
                <Box
                  component="div"
                  sx={{
                    padding: 2,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  There are no competitions to display for the selected date
                  range.
                </Box>
              ) : (
                <QueryResult
                  loadingLarge={loading}
                  data={
                    selectedRegion === 1
                      ? combinedCompetitions
                      : filteredCompetitions
                  }
                >
                  {selectedRegion === 1 &&
                    combinedCompetitions?.length < 1 &&
                    euCompetitions?.length > 0 &&
                    euCompetitions?.map((competition, index) => (
                      <TableRow
                        component="div"
                        key={competition?.id}
                        sx={{
                          background:
                            index % 2
                              ? "#FFF"
                              : "linear-gradient(180deg, #F9FAFB, #FCFCFD, #F9FAFB)",
                        }}
                      >
                        <DetailedCompetitionCard
                          competition={competition}
                          index={index}
                        />
                      </TableRow>
                    ))}
                  {selectedRegion === 1 &&
                    combinedCompetitions?.length < 1 &&
                    auCompetitions?.length > 0 &&
                    auCompetitions?.map((competition, index) => (
                      <TableRow
                        component="div"
                        key={competition?.id}
                        sx={{
                          background:
                            index % 2
                              ? "#FFF"
                              : "linear-gradient(180deg, #F9FAFB, #FCFCFD, #F9FAFB)",
                        }}
                      >
                        <DetailedCompetitionCard
                          competition={competition}
                          index={index}
                        />
                      </TableRow>
                    ))}
                  {selectedRegion === 1 &&
                    combinedCompetitions?.length > 0 &&
                    combinedCompetitions?.map((competition, index) => (
                      <TableRow
                        component="div"
                        key={competition?.id}
                        sx={{
                          background:
                            index % 2
                              ? "#FFF"
                              : "linear-gradient(180deg, #F9FAFB, #FCFCFD, #F9FAFB)",
                        }}
                      >
                        <DetailedCompetitionCard
                          competition={competition}
                          index={index}
                        />
                      </TableRow>
                    ))}
                  {selectedRegion !== 1 && filteredCompetitions?.length > 0
                    ? filteredCompetitions?.map((month) => (
                        <>
                          {/* Month header */}
                          <Box
                            sx={{
                              mx: 0,
                              my: 0,
                              py: 1,
                              background: `${"linear-gradient(#40576B, #374B5C)"}`,
                            }}
                            role="banner"
                            aria-label="Competitions This Week"
                          >
                            <Typography
                              my="auto"
                              mx={1}
                              fontSize="1.2rem"
                              fontWeight={600}
                              sx={{ color: "#FFF" }}
                            >
                              {month.month}
                            </Typography>
                          </Box>
                          {month.competitions.map((competition, index) => (
                            <TableRow
                              component="div"
                              key={competition?.id}
                              sx={{
                                background:
                                  index % 2
                                    ? "#FFF"
                                    : "linear-gradient(180deg, #F9FAFB, #FCFCFD, #F9FAFB)",
                              }}
                            >
                              <DetailedCompetitionCard
                                competition={competition}
                                index={index}
                              />
                            </TableRow>
                          ))}
                        </>
                      ))
                    : selectedRegion !== 1 &&
                      filteredCompetitions?.length < 1 && (
                        <NoFilteredResults
                          setSelectedRegion={setSelectedRegion}
                          setSelectedCountry={setSelectedCountry}
                        />
                      )}
                </QueryResult>
              )}
            </Box>
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ my: 0.5, mx: 1, bgcolor: "" }}
            >
              <ButtonComponent
                disabled={
                  offset || filterEuOffset || filterAuOffset ? false : true
                }
                variant={"grey"}
                icon={
                  useMediaQuery("(max-width:599px)") && (
                    <ArrowBackIosIcon sx={{ height: "1.2rem" }} />
                  )
                }
                startIcon={<ArrowBackIosIcon sx={{ height: 12 }} />}
                title={useMediaQuery("(min-width:600px)") && "Previous"}
                onClick={() => handlePrevious()}
                sx={{ my: "auto", fontSize: "0.75rem", width: 100 }}
              />
              <ButtonComponent
                disabled={handleDisableNextButton()}
                variant={"grey"}
                icon={
                  useMediaQuery("(max-width:599px)") && (
                    <ArrowForwardIosIcon sx={{ height: "1.2rem" }} />
                  )
                }
                endIcon={<ArrowForwardIosIcon sx={{ height: 12 }} />}
                title={"Next"}
                onClick={() => handleNext()}
                sx={{ my: "auto", fontSize: "0.75rem", width: 100 }}
              />
            </Box>
          </Box>
        </Grid>
      </MainTableLayout>
    </>
  );
}
