import React from "react";

import { Typography, Box, Grid } from "@mui/material";

export default function HeatLaneHeaders({
  isRelay,
  isPara,
  showAge,
  eventLevel,
}) {
  return (
    <Grid container item xs={12} height={24} alignItems="center">
      <Box display="flex" width={36} ml="6px">
        <Typography fontSize="0.7rem" color="#667085">
          Lane
        </Typography>
      </Box>
      <Box
        display="flex"
        width={eventLevel > 1 ? "calc(100% - 434px)" : "calc(100% - 518px)"}
        maxWidth="240px"
        ml="26px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          Competitor
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={40}
        ml="8px"
        mr="4px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          {isRelay ? "Age" : showAge ? "Age" : "Year"}
        </Typography>
      </Box>
      <Box
        display="flex"
        minWidth="88px"
        width={eventLevel > 1 ? "calc(100% - 586px)" : "calc(100% - 670px)"}
        ml="8px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          Club
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" width={26} ml="8px">
        <Typography fontSize="0.7rem" color="#667085">
          RT
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={34}
        ml="8px"
        sx={{ display: eventLevel > 1 ? "none" : "flex" }}
      >
        <Typography fontSize="0.7rem" color="#667085">
          {isPara ? "WPS" : "PTS"}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={34}
        ml="8px"
        sx={{ display: eventLevel > 1 ? "none" : "flex" }}
      >
        <Typography fontSize="0.7rem" color="#667085">
          Rank
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" width={72} ml="8px">
        <Typography fontSize="0.7rem" color="#667085">
          Result
        </Typography>
      </Box>
    </Grid>
  );
}
