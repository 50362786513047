import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Typography,
    Box,
    Grid,
    Divider
} from '@mui/material';
import { BsPeopleFill, BsPersonFill } from 'react-icons/bs';
import ClubViewHeatCompetitorLane from './ClubViewHeatCompetitorLane';
import { competitorNavigationString, getBirthYear } from '../../../../../../components/utils';
import { FlagComponent } from '../../../../../../components/layout';

export default function ClubViewHeatCompetitorCard({ setView, competitor, lanes, setSelectedCompetitor, showAge }) {

    const params = useParams();
    const navigate = useNavigate();

    return (
        <Grid container mt={1} sx={{
            borderRadius: 1,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#D0D5DD',
            bgcolor: '#E4E7EC',
            boxShadow: 1
        }}>
            <Box display='flex' width='100%' mx={0.5} my={0.5}
                onClick={() => {
                    setSelectedCompetitor(competitor);
                    navigate(competitorNavigationString(params.competitionName, competitor?.club?.oid, competitor?.oid));
                    setView(3);
                }}
                sx={{ cursor: 'pointer' }}
            >
                <Box display='flex' justifyContent='flex-start' width='100%' sx={{ bgcolor: '' }}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        my='auto'
                        height='2.8rem'
                        width='2.8rem'
                        sx={{
                            bgcolor: '#374B5C',
                            borderRadius: 2,
                        }}>
                        <Box display='flex' justifyContent='center' alignItems='center'>
                            {competitor?.is_relay ?
                                <BsPeopleFill color='#FFF' size='2rem' />
                                :
                                <BsPersonFill color='#FFF' size='2rem' />
                            }
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column'>
                        <Typography mx={1} fontWeight='bold' fontSize='1.1rem'>{competitor?.full_name}</Typography>
                        <Box display='flex' alignItems='center' mx={1} gap={0.5}>
                            <Box width={16} height={12} display='flex' alignItems='center' mt='-0.85px' sx={{ boxShadow: 2, borderRadius: 1, bgcolor: '' }}>
                                <FlagComponent size='s' countryCode={competitor?.country_code} />
                            </Box>
                            <Typography fontSize='0.8rem' lineHeight={1}>{competitor?.country_code}</Typography>
                            <Divider orientation="vertical" variant="middle" sx={{ bgcolor: '#98A2B3' }} />
                            <Typography fontSize='0.8rem' lineHeight={1}>{showAge ? competitor?.age : getBirthYear(competitor?.birthday)}</Typography>
                            <Divider orientation="vertical" variant="middle" sx={{ bgcolor: '#98A2B3' }} />
                            <Typography fontSize='0.8rem' lineHeight={1}>{competitor?.club?.name}</Typography>
                        </Box>
                        {/* <Typography mx={1} fontWeight='300' fontSize='0.8rem'>Heats: {competitor?.lanes?.length}</Typography> */}
                    </Box>
                </Box>
            </Box>

            <Grid container>
                <Grid container item xs={12} height={24} mx={0} alignItems='center' sx={{ bgcolor: '' }}>
                    <Box display='flex' width='calc(100% - 518px)' maxWidth='200px' ml='6px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Event</Typography>
                    </Box>
                    <Box display='flex' minWidth='120px' width='calc(100% - 598px)' ml='50px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Heat</Typography>
                    </Box>
                    <Box display='flex' width={36} ml='6px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Lane</Typography>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' width={28} ml='6px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>RT</Typography>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' width={34} ml='8px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>FINA</Typography>
                    </Box>
                    <Box display='flex' justifyContent='center' width={44} ml='8px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Rank</Typography>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' width={62} ml='8px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Result</Typography>
                    </Box>
                </Grid>
                {lanes && lanes.map((lane, index) => (
                    <ClubViewHeatCompetitorLane key={lane?.id} lane={lane} event={lane?.heat?.round?.event} heatStatus={lane?.heat?.status} index={index} showAge={showAge} />
                ))}
            </Grid>
        </Grid>
    );
}
