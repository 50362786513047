/* if swimmer in team is dq'd, don't display their and following team swimmers times */
export default function relayResultText(time, index, dqSwimmer) {
    if (time === 0) return null;
    const testDate = new Date(time);
    //check for valid date
    if (testDate instanceof Date && !isNaN(testDate)) {
        if (dqSwimmer > 0) {
            if (index >= dqSwimmer - 1) {
                return null;
            } else if (time >= 60000) {
                return new Date(time).toISOString().slice(15, 22);
            } else return new Date(time).toISOString().slice(17, 22);
        } else if (time >= 60000) {
            return new Date(time).toISOString().slice(15, 22);
        } else {
            return new Date(time).toISOString().slice(17, 22);
        }
    } else return null;
}