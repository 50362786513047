export default function heatTimeDisplay(heatStatus: number, lane: Lane) {
  if (heatStatus === 0) return "";
  if (heatStatus === 3) {
    if (lane?.dsq) return "DSQ";
    if (lane?.dnf) return "DNF";
    return lane?.result_text;
  }
  if (lane?.dns) return "DNS";

  return lane?.result_text;
}
