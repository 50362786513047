import { DocumentNode } from "graphql";
import { useContext, useMemo, useState } from "react";
import { OperationVariables } from "@apollo/client";
import parseData from "../../components/utils/parseData";
import client from "../../utils/apollo/apollo-client";
import { CompetitionDetailsContext } from "../../context/CompetitionDetailsContext";

export default function useConditionalSub<T>(
  documentNode: DocumentNode,
  variables: OperationVariables,
  condition: boolean
) {
  const [endSub, setEndSub] = useState<boolean | undefined>(undefined);
  const [activeSub, setActiveSub] = useState(false);
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [unsub, setUnsub] = useState<{ unsub: () => void } | undefined>(
    undefined
  );
  const { shouldSubscribe } = useContext(CompetitionDetailsContext);

  const startSubscription = () => {
    const observer = client.subscribe({
      query: documentNode,
      variables: variables,
    });
    const subscription = observer.subscribe(({ data, errors }) => {
      setData(data);
      setError(errors);
      setActiveSub(true);
    });

    return subscription;
  };

  useMemo(() => {
    if (condition !== endSub) {
      setEndSub(condition);
    }
  }, [condition]);

  useMemo(() => {
    if (variables?.id !== undefined) {
      if (
        !varsOk(variables) &&
        endSub === false &&
        variables &&
        !unsub &&
        shouldSubscribe
      ) {
        let sub = startSubscription();
        const unsub = () => {
          setActiveSub(false);
          sub.unsubscribe();
        };

        setUnsub({
          unsub,
        });
      }
    }
  }, [variables, condition]);

  useMemo(() => {
    if (endSub && unsub) {
      unsub.unsub();
      setUnsub(undefined);
    }
  }, [endSub, unsub]);

  return {
    data: parseData<T>(data),
    error,
    loading,
    endSub: () => {
      setEndSub(true);
      if (unsub) {
        unsub.unsub();
        setUnsub(undefined);
      }
    },
    restartSub: () => setEndSub(false),
    isActive: activeSub,
  };
}

const varsOk = (vars: OperationVariables) => {
  let ok = false;
  Object.keys(vars).forEach((v) => {
    if (vars[v] === undefined) ok = true;
  });
  return ok;
};
