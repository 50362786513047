export default function statusGradientColor(id, currentHeatId, status, startTime) { 

    function getThreshold(value) {
        let testDate = new Date(value);
        if (testDate instanceof Date && !isNaN(testDate)) {
            //add 1 hour to startTime
            return new Date(value).getTime() + (1 * 60 * 60 * 1000);
        } else return;
    }

    let liveBadgeThreshold = getThreshold(startTime);
    let currentTime = Date.now();

    if (status === 5) {
        return `linear-gradient(#317A4D, #255C3A)`;
    } else if (id === currentHeatId && currentTime < liveBadgeThreshold) {
        return `linear-gradient(#E3393C, #C53133)`;
    } else return `linear-gradient(#49647A, #374B5C)`;
}