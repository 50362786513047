import React from 'react';

import {
    Box,
    Grid,
    Typography,
} from '@mui/material';

export default function CompetitorHeaders({ showAge }) {
    return (
        <Grid container item xs={12} height={24} mx={0} alignItems='center' sx={{ bgcolor: '#E4E7EC' }}>
            <Box display='flex' width='calc(100% - 362px)' ml='48px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Name</Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' width={40} ml='8px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>{showAge ? 'Age' : 'Year'}</Typography>
            </Box>
            <Box display='flex' justifyContent='flex-start' width={250} ml='8px' mr='8px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Club</Typography>
            </Box>
        </Grid>
    )
}
