import { useState, useMemo, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { SMALL_CURRENT_HEAT_SUBSCRIPTION } from '../utils/graphql/subscriptions';
import { CURRENT_HEAT_QUERY } from '../utils/graphql/queries';
import useStrictSubscription from './graphql/useStrictSubscription';
import useFetch from './graphql/useFetch';

interface CurrentHeatProperties {
    currentHeatObj: CurrentHeat | undefined,
    currentTpeId: number | undefined,
    currentHeatUpdateThreshold: number | undefined,
    liveSessionId: number | undefined,
}

/**
 * Fetches the CurrentHeat object LIVE with the use of a competitions competitionId
 * @param competitionId 
 * @returns 
 */

export default function useLiveCurrentHeat(competitionId: string | undefined): { data: CurrentHeatProperties, error: ApolloError | undefined, loading: boolean; } {

    const [ret, setRet] = useState<CurrentHeatProperties>({ liveSessionId: undefined, currentHeatObj: undefined, currentHeatUpdateThreshold: undefined, currentTpeId: undefined });
    const { data: subData, loading: subDataLoading, error: subDataError } = useStrictSubscription<CurrentHeat>(SMALL_CURRENT_HEAT_SUBSCRIPTION, { id: competitionId }, !competitionId);
    const { data, loading, error, refresh } = useFetch<CurrentHeat>(CURRENT_HEAT_QUERY, { id: competitionId }, "id", 'network-only');

    useMemo(() => {
        let liveSessionId, currentTpeId, currentHeatUpdateThreshold, currentHeatObj;
        if (data) {
            currentHeatObj = data;
            liveSessionId = data?.time_program_entry?.competition_session?.id;
            currentTpeId = data.time_program_entry?.id;
            currentHeatUpdateThreshold = data.updated_at ? new Date(data.updated_at).getTime() + (1 * 60 * 60 * 1000) : undefined;
        }
        setRet({ liveSessionId, currentHeatObj, currentTpeId, currentHeatUpdateThreshold });
    }, [data]);

    useMemo(() => {
        if (subData) {         
            refresh();
        }
    }, [subData]);

    return { data: ret, error: error || subDataError, loading: loading || subDataLoading };
}