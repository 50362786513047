import { useState, useContext, useEffect } from "react";

import { Box, Typography, Grid, Button } from "@mui/material";

import { FaSwimmer } from "react-icons/fa";

import QueryResult from "../../../components/containers/QueryResult";
import EventInfo from "./components/EventInfo";
import LiveHeat from "./components/LiveHeat";
import LiveTable from "./components/LiveTable";

import { ScoreboardFullscreen } from "./fullscreen/ScoreboardFullscreen";
import { BsArrowsFullscreen } from "react-icons/bs";
import { ScoreboardContext } from "../../../context/ScoreboardContext";
import { useLocation } from "react-router-dom";

export interface Splits {
  "50": number;
  "100": number;
  "200": number;
}

export type CurrentHeatSelection = "Current" | "Next" | "Prev";

export default function ScoreboardView() {
  const [showFullscreen, setShowFullscreen] = useState<boolean>(false);
  const {
    displayHeat,
    aboutToUpdate,
    selectedCurrentHeat,
    useCurrent_HeatLoading,
    showAge,
    isActive,
    selectedCurrentHeatChanged,
    lowestSplits,
    trySplits,
  } = useContext(ScoreboardContext);
  const location = useLocation();
  useEffect(() => {
    location.pathname.split("/").includes("fullscreen") &&
      setShowFullscreen(true);
  }, []);
  return (
    <>
      {showFullscreen ? (
        <ScoreboardFullscreen close={() => setShowFullscreen(false)} />
      ) : (
        <Box width="100%" sx={{ bgcolor: "" }}>
          <Box mx={0} my={0} sx={{ bgcolor: "" }}>
            <Grid container my={0} display="flex">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                my="auto"
                width="100%"
                gap={1}
                py={1}
                pr={1}
                sx={{
                  color: "#FFF",
                  bgcolor: "#374B5C",
                  borderRadius: "6px 6px 0px 0px",
                  boxShadow: 2,
                }}
              >
                <Box
                  ml={1}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={1}
                >
                  <Box
                    ml={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FaSwimmer size="1.5rem" />
                  </Box>
                  <Typography
                    fontWeight={400}
                    fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.25rem" }}
                  >
                    {selectedCurrentHeat === "Current"
                      ? "Current Heat"
                      : selectedCurrentHeat === "Next"
                      ? "Upcoming Heat"
                      : "Previous Heat"}
                  </Typography>
                </Box>
                <Button
                  sx={{ fontSize: "1rem", gap: 1 }}
                  onClick={() => setShowFullscreen(!showFullscreen)}
                  //@ts-ignore
                  variant="grey"
                >
                  <BsArrowsFullscreen />
                  Open fullscreen
                </Button>
              </Box>
            </Grid>
            <QueryResult
              onlyLoadInitially
              loading={useCurrent_HeatLoading}
              data={displayHeat}
            >
              {displayHeat ? (
                <div id="scoreboard">
                  <EventInfo liveHeat={displayHeat} />
                  <LiveHeat
                    eventType={
                      displayHeat?.time_program_entry?.round?.event?.event_type
                    }
                    heat={displayHeat}
                    showAge={showAge}
                    isActive={isActive}
                  />
                  <LiveTable
                    selectedCurrentHeatChanged={selectedCurrentHeatChanged}
                    aboutToUpdate={aboutToUpdate}
                    heat={displayHeat}
                    lowestSplits={lowestSplits}
                    trySplits={trySplits}
                  />
                </div>
              ) : (
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="auto"
                  xs={12}
                >
                  <Typography p={1} variant="h4">
                    {selectedCurrentHeat === "Current"
                      ? " No ongoing heat"
                      : selectedCurrentHeat === "Next"
                      ? "No upcoming Heat"
                      : "No previous Heat"}
                  </Typography>
                </Grid>
              )}
            </QueryResult>
          </Box>
        </Box>
      )}
    </>
  );
}
