import { BrowserRouter, Routes, Route } from "react-router-dom";

import ReactGA from "react-ga4";

import "./app.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../utils/constants/mui-theme";
import Search from "../pages/Search";
import Footer from "../components/Footer";
import Home, { CountryName } from "../pages/Home";
import About from "../pages/About";
import Error from "../pages/Error";
import List from "../pages/competitions/List";
import Page from "../pages/competitions/Page";

import ScreenContextProvider from "../context/ScreenContext";
import LiveContextProvider from "../context/LiveContext";
import { SeoContextProvider } from "../context/SeoContext";
import CurrentHeatContextProvider from "../context/CurrentHeatContext";
import CompetitionDetailsContextProvider from "../context/CompetitionDetailsContext";

import { useEffect, useState } from "react";
import AndroidAppBanner from "../components/layout/AndroidAppBanner";
import { countries } from "../components/RegionSelection";

function App() {
  const competitionName = document.location.pathname.split("/")[2];
  const gaConfig = {
    trackingId: process.env.REACT_APP_GOOGLE_MEASUREMENT_ID || "",
    gaOptions: {
      competition: competitionName || "undefined",
    },
    gtagOptions: {},
  };
  ReactGA.initialize([gaConfig]);
  const [isAndroid, setIsAndroid] = useState(false);

  // Check if the user is using an Android device
  useEffect(() => {
    setIsAndroid(/Android/i.test(navigator.userAgent));
    let path = document.location.pathname;
    if (path === "/region") {
      let region = document.location.search.split("=")[1];
      localStorage.setItem(
        "region",
        JSON.stringify(
          countries.filter((c) => c.name === region.toLocaleUpperCase())[0].id
        )
      );
      localStorage.setItem(
        "country",
        JSON.stringify(region.toLocaleUpperCase() as CountryName)
      );
      document.location.href = "/";
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SeoContextProvider>
        <ScreenContextProvider>
          <CompetitionDetailsContextProvider>
            <LiveContextProvider>
              <CurrentHeatContextProvider>
                <BrowserRouter>
                  {isAndroid && <AndroidAppBanner />}
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/region" />
                    <Route path="/competitions" element={<List />} />
                    <Route
                      path="/competitions/:competitionName"
                      element={<Page />}
                    />

                    <Route
                      path="/competitions/:competitionName/:competitionView"
                      element={<Page />}
                    />
                    <Route
                      path="/competitions/:competitionName/:competitionView/fullscreen"
                      element={<Page />}
                    />
                    <Route
                      path="/competitions/:competitionName/events"
                      element={<Page />}
                    >
                      <Route path=":eventView" element={<Page />}>
                        <Route path=":session" element={<Page />}>
                          <Route path=":tpe" element={<Page />}>
                            <Route path=":liveTpe" element={<Page />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      path="/competitions/:competitionName/swimmers/:competitorView"
                      element={<Page />}
                    />
                    <Route
                      path="/competitions/:competitionName/swimmers/clubs"
                      element={<Page />}
                    >
                      <Route path=":clubId" element={<Page />}>
                        <Route path=":clubView" element={<Page />}>
                          <Route path=":clubViewSortOrder" element={<Page />} />
                        </Route>
                        <Route path="competitor" element={<Page />}>
                          <Route path=":competitorId" element={<Page />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      path="/competitions/:competitionName/superlive"
                      element={<Page />}
                    >
                      <Route path=":session" element={<Page />}>
                        <Route path=":session" element={<Page />} />
                      </Route>
                    </Route>
                    <Route path="/search" element={<Search />} />
                    <Route path="/search/:query" element={<Search />} />
                    <Route path="*" element={<Error />} />
                  </Routes>
                  <Footer />
                </BrowserRouter>
              </CurrentHeatContextProvider>
            </LiveContextProvider>
          </CompetitionDetailsContextProvider>
        </ScreenContextProvider>
      </SeoContextProvider>
    </ThemeProvider>
  );
}

export default App;
