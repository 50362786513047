export default function getBirthYear(date) {
    const testDate = new Date(date);
    if (testDate instanceof Date && !isNaN(testDate)) {
        try {
            return date.slice(0, 4);
        } catch (error) {
            console.log(error);
            return null;
        }
    } else return null;
}