import React, { useState, useEffect, useContext, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, Paper, AppBar, Typography, Grid } from "@mui/material";

import { FaSwimmer } from "react-icons/fa";
import {
  BsFillInboxesFill,
  BsFillPlayBtnFill,
  BsPersonFill,
  BsCalendar2EventFill,
} from "react-icons/bs";
import { MdLeaderboard } from "react-icons/md";
import ReactGA from "react-ga4";

import {
  GET_COMPETITION,
  GET_TPE_STATUS_UPDATE,
} from "../../utils/graphql/queries";
import { TPE_ROUND_STATUS_SUBSCRIPTION } from "../../utils/graphql/subscriptions";
import QueryResult from "../../components/containers/QueryResult";
import useSubscribeIf from "../../hooks/graphql/useSubscribeIf";
import useFetch from "../../hooks/graphql/useFetch";
import useCompetitionId from "../../hooks/useCompetitionId";

import MainTableLayout from "../../components/layout/MainTableLayout";
import Header from "../../components/Header";
import {
  Breadcrumbs,
  StyledMainTab,
  StyledTabs,
  Image,
} from "../../components/layout";
import {
  MetaDecorator,
  isCompetitionActive,
  storeRegion,
} from "../../components/utils";

import EventView from "../../views/competition/event/EventView";
import PointsView from "../../views/competition/points/PointsView";
import CompetitorView from "../../views/competition/competitor/CompetitorView";
import FileArchive from "../../views/competition/filearchive/FileArchive";
import ScoreboardView from "../../views/competition/scoreboard/ScoreboardView";
import SuperliveView from "../../views/competition/superlive/SuperliveView";

import SessionTabs from "./components/SessionTabs";
import EmptyCompetitionSessions from "../../views/competition/event/components/EmptyCompetitionSessions";
import DetailPageCompetitionCard from "../../components/containers/DetailPageCompetitionCard";
import Error from "../Error";
import HeatChanger from "../../views/competition/scoreboard/components/HeatChanger";

import { CurrentHeatSelection } from "../../hooks/useScoreboardCurrentHeat";

import { ScreenContext } from "../../context/ScreenContext";
import { LiveContext } from "../../context/LiveContext";
import { SeoContext } from "../../context/SeoContext";
import { CompetitionDetailsContext } from "../../context/CompetitionDetailsContext";
import SuperliveLogo from "../../components/svg/SuperliveLogo";
import ScoreboardContextWrapper from "../../context/ScoreboardContext";

const screenType = {
  ToNumber: (screenType: ScreenType): number => {
    switch (screenType) {
      case "events":
        return 0;
      case "points":
        return 1;
      case "swimmers":
        return 2;
      case "files":
        return 3;
      case "scoreboard":
        return 4;
      case "superlive":
        return 5;
    }
  },
  NumberToType: (number: number): ScreenType => {
    switch (number) {
      case 0:
        return "events";
      case 1:
        return "points";
      case 2:
        return "swimmers";
      case 3:
        return "files";
      case 4:
        return "scoreboard";
      case 5:
        return "superlive";
      default:
        return "events";
    }
  },
  StringToType: (string: string): ScreenType => {
    switch (string) {
      case "events":
        return "events";
      case "points":
        return "points";
      case "swimmers":
        return "swimmers";
      case "files":
        return "files";
      case "scoreboard":
        return "scoreboard";
      case "superlive":
        return "superlive";
      default:
        return "events";
    }
  },
};

export default function Page() {
  const params = useParams();

  const { competitionSEO, setCompetitionSEO } = useContext(SeoContext);
  const {
    currentHeatObj,
    currentTpeId,
    currentHeatUpdateThreshold,
    liveSessionId,
  } = useContext(LiveContext);
  const {
    setIsBeta,
    updateShouldSubscribe,
    setShowDisqualificationReasons,
    setCompetitionId,
  } = useContext(CompetitionDetailsContext);

  const currentDate = new Date().toLocaleDateString("sv-SE");

  const { screen, selectedScreenTabIndex, setScreen } =
    useContext(ScreenContext);
  useEffect(() => {
    setCompetitionSEO(params?.competitionName);
  }, [params]);

  useMemo(() => {
    ReactGA.set({ page_title: screen });
  }, [screen]);

  const location = useLocation();
  const navigate = useNavigate();

  storeRegion(location?.state?.region);

  const [newTpe, setNewTpe] = useState(false);

  const [selectedSession, setSelectedSession] = useState<
    CompetitionSession | undefined
  >(undefined);
  const [sessionTabIndex, setSessionTabIndex] = useState<number>(0);
  const [timeProgramEntryId, setTimeProgramEntryId] = useState<
    number | undefined
  >(undefined);
  const [eventView, setEventView] = useState<View>(
    (params?.eventView as View) ?? "entries"
  );
  const [updatedCurrentHeatSelection, setUpdatedCurrentHeatSelection] =
    useState<CurrentHeatSelection>("Current");

  const { competitionId, loading: loadingCompetitionId } =
    useCompetitionId(competitionSEO);

  const paths = location.pathname.split("/").filter((x) => x);
  useEffect(() => {
    const decodedPaths = paths.map((path) => decodeURI(path));
    setScreen(decodedPaths && screenType.StringToType(decodedPaths[2]));
    if (
      screenType.StringToType(decodedPaths[2]) === "events" &&
      screen !== "events"
    ) {
      setSelectedSession(undefined);
      setSessionTabIndex(
        (decodedPaths &&
          sessions?.findIndex((s) => s.oid === Number(decodedPaths[4]))) ||
          0
      );

      setEventView(decodedPaths[3] as View);
      setTimeProgramEntryId(Number(decodedPaths[5]));
    }
  }, [location]);

  useEffect(() => {
    if (params?.eventView) {
      setEventView(params.eventView as View);
    }
  }, [params]);

  const {
    loading,
    error,
    data,
    refresh: refetch,
  } = useFetch<Competition>(
    GET_COMPETITION,
    { competitionId },
    "competitionId",
    "cache-and-network"
  );

  useMemo(() => {
    const handleData = (data: Competition) => {
      if ((screen === "events" || screen === "superlive") && !selectedSession) {
        if (params.session) {
          const parsedSession = Number(params.session);
          const foundSession = data?.competition_sessions?.find(
            (session) => session?.oid === parsedSession
          );
          if (foundSession) {
            setSelectedSession(foundSession);
            const foundSessionIndex = data?.competition_sessions?.findIndex(
              (session) => session?.oid === parsedSession
            );
            foundSessionIndex && setSessionTabIndex(foundSessionIndex);
          } else {
            data?.competition_sessions &&
              setSelectedSession(data?.competition_sessions[0]);
            setSessionTabIndex(0);
          }
        } else if (sessionTabIndex) {
          const sessions = data?.competition_sessions;
          if (sessions) {
            const session = sessions.at(sessionTabIndex);
            session && setSelectedSession(session);
          }
        } else {
          data?.competition_sessions &&
            setSelectedSession(data?.competition_sessions[0]);
          setSessionTabIndex(0);
        }
      } else return;
    };
    if (data) {
      handleData(data);
    }
  }, [data, screen, sessionTabIndex]);

  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  useMemo(() => {
    if (params.session && !firstFetch) {
      refetch();
      setFirstFetch(false);
    }
  }, [params?.session]);

  /* </ FETCH COMPETITION > */

  const competitionName = location?.state?.name ?? data?.name;
  const competition = data;
  const timesData = { startDate: data?.startDate, endDate: data?.endDate };
  const showAge = data?.show_age;
  const superlive = data?.superlive;
  const [sessions, setSessions] = useState<CompetitionSession[] | undefined>(
    data?.competition_sessions
  );

  const competitionIsActive = isCompetitionActive(timesData);

  const { data: tpeStatusUpdate, unsub }: { data: any; unsub: () => void } =
    useSubscribeIf(
      TPE_ROUND_STATUS_SUBSCRIPTION,
      { sessionId: selectedSession?.id },
      selectedSession
        ? true
        : false && competitionIsActive && screen === "events",
      "sessionId"
    );
  const { data: newTpeData, refresh } = useFetch(
    GET_TPE_STATUS_UPDATE,
    { sessionId: selectedSession?.id },
    "sessionId",
    "cache-and-network"
  );

  //fetch updated tpe-data on updates in subscription data (TPE_ROUND_STATUS_SUBSCRIPTION)
  useMemo(() => {
    if (tpeStatusUpdate) refresh();
  }, [tpeStatusUpdate]);

  //kill TPE_ROUND_STATUS_SUBSCRIPTION if navigating to a screen other than 'events'
  useEffect(() => {
    if (screen !== "events") return unsub();
  }, [screen]);
  useEffect(() => {
    sessions && setSelectedSession(sessions[sessionTabIndex]);
  }, [sessionTabIndex]);
  useEffect(() => {
    if (data) {
      setSessions(data?.competition_sessions);
    }
  }, [data]);
  /* CHECK AND SET COMPETITION LEVEL (SHOW/DON'T SHOW RANK) IN LOCAL STORAGE */
  useEffect(() => {
    if (data) {
      localStorage.removeItem("competition-level");
      localStorage.setItem(
        "competition-level",
        JSON.stringify(data?.competition_level)
      );
      setIsBeta(data?.beta ? data.beta : false);
      setCompetitionId(data?.id);
      if (
        data?.startDate &&
        data?.endDate &&
        data?.startDate <= currentDate &&
        data?.endDate >= currentDate
      ) {
        updateShouldSubscribe(true);
      }
      setShowDisqualificationReasons(data?.show_dq_code || false);
    }
  }, [data, competitionId]);

  /* HANDLE CHANGES BETWEEN EVENTS, POINTS, SWIMMERS, FILES & SCOREBOARD */
  const handleViewChange = (e: React.ChangeEvent, newValue: number) => {
    setScreen(screenType.NumberToType(newValue));
    if (newValue === 0 && selectedSession) {
      selectedSession.oid &&
        navigate(
          `/competitions/${params?.competitionName}/events/${eventView}/${selectedSession?.oid}`
        );
    } else
      navigate(
        `/competitions/${params?.competitionName}/${screenType.NumberToType(
          newValue
        )}`
      );
  };

  const handleSessionTabIndex = (
    newValue: number,
    screen2?: ScreenType | undefined
  ) => {
    setSessionTabIndex(newValue);
    if (screen === "events" || screen2 === "events") {
      /* CHECK IF CHOSEN SESSION IS LIVE AND IF SO NAVIGATE TO CURRENT TPE WITHIN SAME SESSION */
      if (
        sessions &&
        sessions[newValue]?.id ===
          currentHeatObj?.time_program_entry?.competition_session?.id
      ) {
        const foundLiveTpe = sessions
          ? sessions[newValue]?.time_program_entries?.find(
              (tpe) => tpe.id === currentTpeId
            )
          : undefined;
        if (foundLiveTpe) {
          setTimeProgramEntryId(foundLiveTpe?.id);
          sessions &&
            navigate(
              `/competitions/${params?.competitionName}/events/${eventView}/${sessions[newValue]?.oid}/${foundLiveTpe?.oid}`,
              { state: { sessionTab: true } }
            );
        }
      } else {
        const tpe = sessions
          ? sessions[newValue]?.time_program_entries?.at(0)
          : undefined;
        tpe && setTimeProgramEntryId(tpe.id);
        sessions &&
          navigate(
            `/competitions/${params?.competitionName}/events/${eventView}/${
              sessions[newValue]?.oid
            }${tpe && "/" + tpe.oid}`,
            { state: { sessionTab: true } }
          );
      }
      setNewTpe(true);
      /* setNewSession(true) */
    } else if (screen === "superlive") {
      sessions &&
        navigate(
          `/competitions/${params?.competitionName}/superlive/${sessions[newValue]?.oid}`,
          { state: { sessionTab: true } }
        );
    }
  };

  return (
    <>
      {competitionId ? (
        <>
          <MetaDecorator
            title={competitionName ? `Swimify - ${competitionName}` : "Swimify"}
            description="Swimify Competition Detail Page"
          />
          <Header />
          <MainTableLayout
            title={<Breadcrumbs competitionName={competitionName} />}
          >
            <Grid container sx={{ bgcolor: "" }}>
              <Grid item xs={12} sx={{ bgcolor: "" }}>
                {/* @ts-ignore */}
                <Paper
                  elevation={3}
                  /* @ts-ignore */
                  sx={{
                    paddingBottom:
                      screen !== "events" &&
                      screen !== "superlive" &&
                      screen !== "scoreboard" &&
                      5,
                    marginBottom: 2,
                    bgcolor: "",
                  }}
                >
                  <>
                    <Grid container>
                      {data?.large_image && (
                        <Image
                          src={data?.large_image}
                          placeholder={"./img/img_placeholder.png"}
                          width={1600}
                          height={280}
                          alt={`${competitionName || "Competition"} banner`}
                        />
                      )}
                      <Box sx={{}}>
                        {competition && (
                          <DetailPageCompetitionCard
                            competition={competition}
                            currentHeatObj={currentHeatObj}
                            currentHeatUpdateThreshold={
                              currentHeatUpdateThreshold
                            }
                            setSessionTabIndex={setSessionTabIndex}
                          />
                        )}
                      </Box>
                    </Grid>
                    {/* @ts-ignore */}
                    <AppBar
                      position="static"
                      elevation={2}
                      /* @ts-ignore */
                      color="logoBlue"
                      sx={{ position: "relative", zIndex: 1 }}
                    >
                      {
                        <StyledTabs
                          value={selectedScreenTabIndex}
                          onChange={handleViewChange}
                          centered
                        >
                          <StyledMainTab
                            label={tabLabel("Events", 0, "1px")}
                            sx={{
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.9rem",
                                md: "1.1rem",
                              },
                              color: "#CCC",
                            }}
                          />
                          <StyledMainTab
                            label={tabLabel("Points", 1, "1px")}
                            sx={{
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.9rem",
                                md: "1.1rem",
                              },
                              color: "#CCC",
                            }}
                          />
                          <StyledMainTab
                            label={tabLabel("Swimmers", 2, "1px")}
                            sx={{
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.9rem",
                                md: "1.1rem",
                              },
                              color: "#CCC",
                            }}
                          />
                          <StyledMainTab
                            label={tabLabel("Files", 3, "2px")}
                            sx={{
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.9rem",
                                md: "1.1rem",
                              },
                              color: "#CCC",
                            }}
                          />
                          <StyledMainTab
                            label={tabLabel("Scoreboard", 4, "-0.25px")}
                            sx={{
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.9rem",
                                md: "1.1rem",
                              },
                              color:
                                "#CCC" /* display: !currentTpeId ? 'none' : 'inherit' */,
                            }}
                          />
                          <StyledMainTab
                            label={tabLabel("Superlive", 5, "-0.25px")}
                            sx={{
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.9rem",
                                md: "1.1rem",
                              },
                              color: "#CCC",
                              display: superlive ? "inherit" : "none",
                            }}
                          />
                        </StyledTabs>
                      }
                    </AppBar>
                    {(screen === "events" || screen === "superlive") &&
                      sessions &&
                      sessions?.length > 0 && (
                        <SessionTabs
                          sessionTabIndex={sessionTabIndex}
                          handleSessionChange={handleSessionTabIndex}
                          sessions={sessions}
                          liveSessionId={liveSessionId}
                          currentHeatUpdateThreshold={
                            currentHeatUpdateThreshold
                          }
                        />
                      )}
                    {screen === "scoreboard" && (
                      <HeatChanger
                        selectedHeat={updatedCurrentHeatSelection}
                        setSelectedCurrentHeat={setUpdatedCurrentHeatSelection}
                      />
                    )}
                  </>
                </Paper>
              </Grid>
            </Grid>

            <QueryResult error={error} loading={loading} data={data}>
              <>
                {screen === "events" &&
                competitionId &&
                sessions &&
                sessions?.length > 0 ? (
                  <EventView
                    competition={competition}
                    session={selectedSession}
                    timeProgramEntryId={timeProgramEntryId}
                    setTimeProgramEntryId={setTimeProgramEntryId}
                    eventView={eventView}
                    setEventView={setEventView}
                    isCompetitionActive={competitionIsActive}
                    newTpe={newTpe}
                    setNewTpe={setNewTpe}
                  />
                ) : (
                  screen === "events" && <EmptyCompetitionSessions />
                )}
                {screen === "points" && (
                  <PointsView competitionId={competitionId} showAge={showAge} />
                )}
                {screen === "swimmers" && competitionId && (
                  <CompetitorView
                    competitionId={competitionId}
                    showAge={showAge}
                  />
                )}
                {screen === "files" && competitionId && (
                  <FileArchive competitionId={competitionId} />
                )}
                {screen === "scoreboard" && competitionId && (
                  <ScoreboardContextWrapper
                    selectedCurrentHeat={updatedCurrentHeatSelection}
                    updateCurrentHeatSelection={setUpdatedCurrentHeatSelection}
                    showAge={showAge}
                  >
                    <ScoreboardView />
                  </ScoreboardContextWrapper>
                )}
                {screen === "superlive" && (
                  <SuperliveView selectedSession={selectedSession} />
                )}
              </>
            </QueryResult>
          </MainTableLayout>
        </>
      ) : loadingCompetitionId ? (
        ""
      ) : (
        <Error />
      )}
    </>
  );
}

function convertRemToPixels(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const tabLabel = (label: string, icon: number, alignment: string) => {
  const iconArr = [
    <BsCalendar2EventFill size="1rem" className="react-icons" />,
    <MdLeaderboard size="1.2rem" className="react-icons" />,
    <BsPersonFill size="1.25rem" className="react-icons" />,
    <BsFillInboxesFill size="1.05rem" className="react-icons" />,
    <FaSwimmer size="1.4rem" className="react-icons" />,
    /* <BsFillPlayBtnFill size="1.2rem" className="react-icons" /> */
    <SuperliveLogo
      height={convertRemToPixels(1.5)}
      width={convertRemToPixels(1.5)}
      fill={"#CCC"}
      className="react-icons super-live-logo"
    />,
  ];

  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
      <Box display="flex" sx={{ bottom: alignment, position: "relative" }}>
        {iconArr[icon]}
      </Box>
      <Typography
        sx={{ fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1.1rem" } }}
      >
        {label}
      </Typography>
    </Box>
  );
};
