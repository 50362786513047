import React from "react";

import { Box, Typography } from "@mui/material";

import { CountryName } from "../pages/Home";

interface Country {
  id: number;
  name: CountryName;
  src: string;
  region: Number;
}
export const countries: Country[] = [
  {
    id: 1,
    name: "ALL",
    src: "/icons/planet-earth-24.png",
    region: 1,
  },
  {
    id: 2,
    name: "SWE",
    src: "/icons/flags/l/SE.png",
    region: 2,
  },
  {
    id: 3,
    name: "FIN",
    src: "/icons/flags/l/FI.png",
    region: 2,
  },
  {
    id: 4,
    name: "DEN",
    src: "/icons/flags/l/DK.png",
    region: 2,
  },
  {
    id: 5,
    name: "NOR",
    src: "/icons/flags/l/NO.png",
    region: 2,
  },
  {
    id: 6,
    name: "FRO",
    src: "/icons/flags/l/FO.png",
    region: 2,
  },
  {
    id: 7,
    name: "SLO",
    src: "/icons/flags/l/SI.png",
    region: 2,
  },
  {
    id: 8,
    name: "AUS",
    src: "/icons/flags/l/AU.png",
    region: 3,
  },
  {
    id: 9,
    name: "NZL",
    src: "/icons/flags/l/NZ.png",
    region: 3,
  },
  {
    id: 10,
    name: "ROU",
    src: "/icons/flags/l/RO.png",
    region: 2,
  },
  {
    id: 11,
    name: "CAN",
    src: "/icons/flags/l/CAN.png",
    region: 2,
  },
  {
    id: 12,
    name: "ISL",
    src: "/icons/flags/l/IS.png",
    region: 2,
  },
  {
    id: 13,
    name: "SUI",
    src: "/icons/flags/l/SUI.png",
    region: 2,
  },
];

export default function RegionSelection({
  selectedRegion,
  setSelectedRegion,
  selectedCountry,
  setSelectedCountry,
}: {
  selectedRegion: number;
  setSelectedRegion: React.Dispatch<React.SetStateAction<number>>;
  selectedCountry: any;
  setSelectedCountry: React.Dispatch<React.SetStateAction<CountryName>>;
}) {
  return (
    <Box display="flex" alignItems="center" sx={{ bgcolor: "" }}>
      {countries.map((country, index) => (
        <Box
          key={country.id}
          display="flex"
          height={48}
          alignItems="center"
          ml="12px"
          onClick={() => {
            setSelectedRegion(country.region as number);
            setSelectedCountry(country.name);
          }}
          sx={{
            cursor: "pointer",
            opacity: country.name === selectedCountry ? 1 : 0.65,
            "&:hover": { opacity: 1 },
          }}
          role="button"
          aria-pressed={country.name === selectedCountry}
          aria-label={`Select ${country.name}`}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setSelectedRegion(country.region as number);
              setSelectedCountry(country.name);
            }
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height={24}
            sx={{
              boxShadow: 3,
              borderRadius: index === 0 ? "16px" : "4px",
            }}
          >
            <Box
              component="img"
              src={country.src}
              sx={{ borderRadius: "4px" }}
              alt={`${country.name} flag`}
            />
            {/* @ts-ignore */}
            <Typography
              lineHeight={1}
              mt={0.4}
              fontSize="0.75rem"
              sx={{
                boxShadow:
                  country.name === selectedCountry ? "0 1px #000" : "none",
              }}
            >
              {country.name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
