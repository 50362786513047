export default function getDateTime(dateTime) {
    const testDate = new Date(dateTime);
    if (testDate instanceof Date && !isNaN(testDate)) {
        if (dateTime === '0001-01-01T00:00:00+00:00') {
            return '--:--'
        } else {
            const time = new Date(dateTime);
            return time.toLocaleString([], { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
        }
    } else return '--:--';
}