import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Grid
} from '@mui/material';
import { ButtonComponent } from './layout';

export default function NoFilteredResults({ setSelectedRegion, setSelectedCountry }) {

	const navigate = useNavigate();

	return (
		<Grid container width='100%' display='flex' justifyContent='center'>
			<Grid container item flexDirection='column' mx={1} my={2} mb={2} width='fit-content'>
				<Typography fontWeight='500' fontSize={{ xs: '0.9rem', sm: '1.2rem' }}>No competitions available with applied filter</Typography>
				<Grid container item alignItems='center' gap={1}>
					<ButtonComponent
						title='Home'
						variant='contained'
						color='logoBlue'
						onClick={() => navigate('/')}
					/>
					<ButtonComponent
						title={'Reset Filter'}
						variant={'grey'}
						onClick={() => {
							setSelectedCountry('ALL');
							setSelectedRegion(1);
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}
