export default function calculateStartEndTimeDiff(estimatedStart: string, actualStart: string): string | undefined {
    const est = new Date(estimatedStart);
    const start = new Date(actualStart);
    est.setFullYear(0, 0, 1);
    start.setFullYear(0, 0, 1);

    const secondsDiff = (start.getTime() - est.getTime()) / 1000;
    const sec = secondsDiff % 60;
    const min = sec > 30 ? 1 + Math.floor(secondsDiff / 60) : (secondsDiff / 60).toFixed(0);
    const testNumber = Number(min);
    const digit = Math.abs(Number(min));
    if ( testNumber < 0 ) return `(- ${digit} min)`;
    if ( testNumber > 0 ) return `(+ ${min} min)`;
    return undefined;
};