import React from 'react';

import {
    Box,
    Typography,
    Grid,
} from '@mui/material';

export default function PointsHeadersSwimmers({ showAge }) {

    return (
        <Grid container item xs={12} height={24} mx={0} alignItems='center' sx={{ bgcolor: '' }}>
            <Box display='flex' width={54} ml='8px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Rank</Typography>
            </Box>
            <Box display='flex' ml='8px' width='calc(100% - 464px)' maxWidth='200px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Name</Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' width={40} ml='8px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>{showAge ? 'Age' : 'Year'}</Typography>
            </Box>
            <Box display='flex' minWidth='250px' width='calc(100% - 414px)' ml='10px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Club</Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' width={68} ml='8px' mr='10px' sx={{ bgcolor: '' }}>
                <Typography fontSize='0.7rem' color='#667085'>Points</Typography>
            </Box>
        </Grid>
    )
}
