import styled from '@emotion/styled';
import { Tab } from '@mui/material';

export const BlankTabs = styled((props: any) => (
    <Tab
        {...props}
        sx={{
            color: '#666',
            '&:hover': {
                //@ts-ignore
                color: props.hovercolor ?? '#000',
                opacity: 1,
            },
            '&.Mui-selected': {
                //@ts-ignore
                color: props.selectedcolor ?? '#000',
            },
            '&.Mui-focusVisible': {
                //@ts-ignore
                backgroundColor: props.focuscolor ?? '#D0D5DD',
            },
        }}
    />
))(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        minHeight: 0,
        height: '2rem',
        [theme.breakpoints.up('md')]: {
            width: 140,
        },
    }),
);