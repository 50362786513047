import React from "react";

import { Typography, Box, Grid } from "@mui/material";

export default function SummaryLaneHeaders({ isPara, eventLevel, showAge }) {
  return (
    <Grid
      container
      item
      xs={12}
      height={24}
      alignItems="center"
      sx={{ backgroundColor: "#E4E7EC" }}
    >
      <Box
        display="flex"
        width={36}
        ml="6px"
        sx={{ display: eventLevel > 1 ? "none" : "inherit" }}
      >
        <Typography fontSize="0.7rem" color="#667085">
          Rank
        </Typography>
      </Box>
      <Box
        display="flex"
        width={eventLevel > 1 ? "calc(100% - 438px)" : "calc(100% - 518px)"}
        maxWidth="240px"
        ml="28px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          Competitor
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" width={40} ml="8px">
        <Typography fontSize="0.7rem" color="#667085">
          {showAge ? "Age" : "Year"}
        </Typography>
      </Box>
      <Box
        display="flex"
        minWidth="128px"
        width={eventLevel > 1 ? "calc(100% - 550px)" : "calc(100% - 630px)"}
        ml="10px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          Club
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" width={28} ml="8px">
        <Typography fontSize="0.7rem" color="#667085">
          RT
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={34}
        ml="8px"
        sx={{ display: eventLevel > 1 ? "none" : "inherit" }}
      >
        <Typography fontSize="0.7rem" color="#667085">
          {isPara ? "WPS" : "PTS"}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={eventLevel > 1 ? 74 : 70}
        ml="8px"
        mr="4px"
      >
        <Typography fontSize="0.7rem" color="#667085">
          Result
        </Typography>
      </Box>
    </Grid>
  );
}
