import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import { getEventLevel } from "../../../../../../components/utils";
import { SummaryLaneHeaders } from "../../../../../../components/layout";
import SummaryCard from "../../../../event/components/summary/SummaryCard";

export default function ClubViewSummaryEventCard({
  round,
  heats,
  event,
  setView,
  setSelectedCompetitor,
  showAge,
}) {
  const params = useParams();
  const navigate = useNavigate();

  const [eventLevel, setEventLevel] = useState(0);

  useEffect(() => {
    if (event) {
      setEventLevel(getEventLevel(event?.event_competition_level));
    }
  }, [event]);

  return (
    <Grid
      container
      mt={1}
      sx={{
        borderRadius: 1,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        bgcolor: "#E4E7EC",
        boxShadow: 1,
      }}
    >
      <Box
        display="flex"
        width="100%"
        mx={0.5}
        my={0.5}
        //TODO: navigation to event
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          sx={{ bgcolor: "" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="auto"
            height="2.8rem"
            width="2.8rem"
            sx={{
              bgcolor: "#374B5C",
              borderRadius: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography fontSize="1rem" color="#FFF">
                {event?.number}
              </Typography>
              <Typography fontSize="0.75rem" lineHeight={1} color="#FFF">
                event
              </Typography>
            </Box>
          </Box>
          {/* <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        ml='8px'
                        my='auto'
                        height='2.8rem'
                        width='2.8rem'
                        sx={{
                            bgcolor: '#F9FAFB',
                            borderRadius: 2,
                        }}>
                        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <Typography fontSize='1rem'>{heat?.number}</Typography>
                            <Typography fontSize='0.75rem' lineHeight={1}>heat</Typography>
                        </Box>
                    </Box> */}
          <Box display="flex" flexDirection="column">
            <Typography mx={1} fontWeight={700} fontSize="1.1rem">
              {event?.name} - {round?.name}
            </Typography>
            <Typography
              mx={1}
              fontWeight={300}
              fontSize="0.8rem"
              lineHeight={1}
            >
              {round?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <SummaryLaneHeaders
        event={event}
        eventLevel={eventLevel}
        showAge={showAge}
      />
      {heats?.map((heat, index) => (
        <Box
          key={index}
          width={"100%"}
          sx={{
            borderTopStyle: index !== 0 && "solid",
            borderWidth: 1,
            borderColor: "#D0D5DD",
          }}
        >
          {heat.lanes.map((lane, index) => (
            <SummaryCard
              key={lane?.id}
              index={index}
              rank={lane?.total_rank}
              lane={lane}
              competitor={lane?.competitor}
              event={event}
              eventLevel={eventLevel}
              setView={setView}
              setSelectedCompetitor={setSelectedCompetitor}
              showAge={showAge}
            />
          ))}
        </Box>
      ))}
    </Grid>
  );
}
