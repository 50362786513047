export default function splitTimeColumns(distance: number): number[] {
    switch(distance){
        case 100:
            return [50];
        case 200:
            return [50, 100, 150];
        case 400:
            return [100, 200, 300];
        case 800:
            return [200, 400, 600];
        case 1500:
            return [400, 800, 1200];
        default:
            return [];
    }
}
