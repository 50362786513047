import React, { useState } from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import styled from 'styled-components';

const PlcHolder = styled.img`
  background: linear-gradient(
    to right,
    rgb(246, 247, 248) 0%,
    rgb(237, 238, 241) 20%,
    rgb(246, 247, 248) 40%,
    rgb(246, 247, 248) 100%
  );
  @keyframes placeHolderShimmer {
    0% {
      background-position: top right;
    }
    100% {
      background-position: top left;
    }
  }
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  background-size: 2250px 2250px !important;
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  animation: placeHolderShimmer 1.5s ease-in-out infinite;
`;
const ImageParent = styled.div`
  position: relative;
  width: 100%;
`;
const StyledImage = styled.img`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
`;

export default function Image ({
    width,
    height,
    srcSetData,
    src,
    alternateSrc,
    placeholder,
    alt = 'Image',
} : {width: number, height: number, srcSetData?: any, src: string, alternateSrc?: string, placeholder: string, alt?: string}) {

    const [imgOnError, setImgOnError] = useState(false);

    //if alternate image not provided, hide entire element
    const errorHandler = (e: any) => {
        if (!alternateSrc) {
            setImgOnError(true);
        } else e.target.src = alternateSrc;
    }

    return (
        <ImageParent
            style={{
                paddingBottom: (height / width) * 100 + '%', display: imgOnError ? 'none' : 'inline'
            }}
        >
            <ProgressiveImage
                /* srcSetData={{
                    srcSet: srcSetData?.srcSet,
                    srcSet: srcSetData?.sizes,
                }} */
                src={src}
               
                placeholder={placeholder}
                onError={errorHandler}
            >
                {(src: string, loading: boolean, srcSetData: any) => {
                    return !placeholder ? (
                        loading ? (
                            <PlcHolder
                                src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width%3D'200' height%3D'150' viewBox%3D'0 0 200 150'%2F%3E"
                                width={width}
                                height={height}
                            />
                        ) : (
                            <StyledImage
                                srcSet={srcSetData.srcSet}
                                sizes={srcSetData.sizes}
                                src={src}
                                alt={alt}
                            />
                        )
                    ) : (
                        <StyledImage
                            srcSet={srcSetData.srcSet}
                            sizes={srcSetData.sizes}
                            //@ts-ignore
                            style={{ filter: loading ? 'blur(8px)' : 'blur(0px)', clipPath: 'inset(0)', transition: !loading && 'filter 0.5s linear' }}
                            src={src}
                            alt={alt}
                        />
                    );
                }}
            </ProgressiveImage>
        </ImageParent>
    );
};