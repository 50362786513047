import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Typography,
    Box,
} from '@mui/material';

import ButtonComponent from '../../components/layout/ButtonComponent';

export default function NoSearchResults({ query }) {

    const navigate = useNavigate();

    return (
        <Box mx={2} my={2}>
            <Box mb={2} display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <Typography fontWeight='500' fontSize={{xs: '0.9rem', sm: '1.2rem'}}>No search results for "{query}"</Typography>
            </Box>
            <Box display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap={2} maxWidth={'50%'}>
                    <ButtonComponent
                        title='Home'
                        variant='contained'
                        color='logoBlue'
                        onClick={() => navigate('/')}
                    />
                    <ButtonComponent
                        title={'All Competitions'}
                        variant={'grey'}
                        onClick={() => navigate('/competitions')}
                    />
                </Box>
            </Box>
        </Box>
    )
}
