import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Box,
    Grid,
    Typography,
    Divider,
} from '@mui/material';

import { BsPeopleFill, BsPersonFill } from 'react-icons/bs';

export default function ClubListCompetitorCard({ competitor, club, selectedCompetitor, setSelectedCompetitor, setView }) {

    const params = useParams();
    const navigate = useNavigate();

    return (
        <>
            <Grid container item
                alignItems='center'
                py={0.5}
                gap={1}
                onClick={() => {
                    navigate(`../competitions/${params.competitionName}/swimmers/clubs/${club?.oid}/competitor/${competitor?.oid}`);
                    setSelectedCompetitor(competitor);
                    setView(3);
                }}
                sx={{
                    bgcolor: competitor?.id === selectedCompetitor?.id ? '#FFF' : '#EBEEF2',
                    cursor: 'pointer',
                    borderTopStyle: 'solid',
                    borderWidth: 1,
                    borderColor: '#D0D5DD'
                }}>
                <Box display='flex' justifyContent='center' width={40} ml='36px' sx={{ bgcolor: '' }}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        my='auto'
                        height='32px'
                        width='32px'
                        sx={{
                            bgcolor: '#F4C647',
                            borderRadius: 2,
                        }}>
                        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            {competitor?.is_relay ?
                                <BsPeopleFill />
                                :
                                <BsPersonFill />
                            }
                        </Box>
                    </Box>
                </Box>
                <Divider orientation='vertical' variant='middle' flexItem />
                <Box display='flex' width='calc(100% - 110px)' sx={{ bgcolor: '' }}>
                    <Typography fontWeight={500} noWrap>{competitor?.full_name}</Typography>
                </Box>
            </Grid>
        </>
    )
}
