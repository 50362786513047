import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';

import CombinedHeaderRow from './CombinedHeaderRow';
import { StatusBadge } from '../../../../../components/layout';
import { Typography } from '@mui/material';

export default function CombinedResultsHeader({ competitions }: { competitions: CombinedCompetition[] | undefined; }) {

    const [roundsStatus, setRoundsStatus] = useState<{ finished: number | undefined, count: number | undefined, allComplete: boolean; }>({ finished: undefined, count: undefined, allComplete: false });
    const [filteredComps, setFilteredComps] = useState<CombinedCompetition[] | undefined>();

    useMemo(() => {
        if (competitions) {
            setFilteredComps(competitions.filter(comp => comp.competition))
        }
    }, [competitions]);

    useEffect(() => {
        if (filteredComps) {
            let finished: number = 0;
            let count: number = 0;
            let allComplete: boolean = true;
            filteredComps?.forEach(competition => {
                competition?.competition?.events && competition?.competition?.events[0]?.rounds && competition?.competition?.events[0]?.rounds.forEach(round => {
                    if (round?.status !== 5) allComplete = false;
                    round?.heats?.forEach((heat, index) => {
                        if (heat?.status === 3) finished++;
                        count++;
                    });
                });
            });
            /* if (count && finished === count) {
                allComplete = true
            } */
            setRoundsStatus({ finished, count, allComplete });
        }
    }, [filteredComps]);

    return (
        <Box width='100%' sx={{ bgcolor: '' }}>
            <Box width='100%' mb={1} display='flex' justifyContent='end'>
                {roundsStatus && (
                    (roundsStatus.count === undefined && roundsStatus.finished === undefined) ?
                        <Box display='flex' justifyContent='center' alignItems='center' mr={{ xs: 0.5, sm: 1 }} width={{ xs: 80, sm: 90 }} sx={{ /* background: `linear-gradient(#49647A, #374B5C)`, borderRadius: 1 */ }}>
                            <Typography noWrap display='flex' alignItems='center' justifyContent='center' fontWeight={500} fontSize={{ xs: '0.5rem', sm: '0.65rem', md: '0.8rem' }} color={'#FFF'} px={{ xs: 1, md: 2 }} height={{ xs: '20px', md: '24px' }} width='fit-content' sx={{}}>

                            </Typography>
                        </Box> :
                        roundsStatus.allComplete
                            ?
                            <Box mr={{ xs: 0.5, sm: 1 }}>
                                <StatusBadge status='All Official' width={{ xs: 80, sm: 90 }} />
                            </Box>
                            :
                            <Box display='flex' justifyContent='center' alignItems='center' mr={{ xs: 0.5, sm: 1 }} width={{ xs: 80, sm: 90 }} sx={{ background: `linear-gradient(#49647A, #374B5C)`, borderRadius: 1 }}>
                                <Typography noWrap display='flex' alignItems='center' justifyContent='center' fontWeight={500} fontSize={{ xs: '0.5rem', sm: '0.65rem', md: '0.8rem' }} color={'#FFF'} px={{ xs: 1, md: 2 }} height={{ xs: '20px', md: '24px' }} width='fit-content' sx={{}}>
                                    {roundsStatus.finished} / {roundsStatus.count} heats
                                </Typography>
                            </Box>)
                }
            </Box>
            {filteredComps?.map(comp => (
                <CombinedHeaderRow key={comp?.competition?.id} competition={comp?.competition} />
            ))}
        </Box>
    );
}
