import { ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

const headers = {
	'x-hasura-public-secret-key': `${process.env.REACT_APP_API_KEY}`,
	'Hasura-Client-Name': 'Swimify Web'
};

const httpLink = new HttpLink({
	uri: `${process.env.REACT_APP_HASURA_URL}`,
	headers: headers,
});

/* using SubscriptionClient to listen to events */
const wsClient = new SubscriptionClient(`${process.env.REACT_APP_HASURA_WS_URL}`, {
	reconnect: true,
	connectionParams: {
		headers: headers,
	},
});

const wsLink = new WebSocketLink(wsClient);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a 'truthy' value
// * The Link to use for an operation if the function returns a 'falsy' value
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httpLink,
);

// Initiate client
const client = new ApolloClient({
	link: splitLink,
	cache: new InMemoryCache()
});

export const EUClient = new ApolloClient({
	uri: `${process.env.REACT_APP_HASURA_URL_EU}`,
	headers: {
		'x-hasura-public-secret-key': `${process.env.REACT_APP_API_KEY_EU}`,
		'Hasura-Client-Name': 'Swimify Web EU Client'
	},
	cache: new InMemoryCache()
});

export const AUClient = new ApolloClient({
	uri: `${process.env.REACT_APP_HASURA_URL_AU}`,
	headers: {
		'x-hasura-public-secret-key': `${process.env.REACT_APP_API_KEY_AU}`,
		'Hasura-Client-Name': 'Swimify Web AU Client'
	},
	cache: new InMemoryCache()
});

export default client;
