import {
    Box,
    Typography,
    Grid,
} from '@mui/material';
import SwimmerEntryCard from './SwimmerEntryCard';

export default function SwimmerEntryList({ entries, competitor }) {

    return (
        <Grid container sx={{
            borderRadius: 1,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#D0D5DD',
            bgcolor: '#E4E7EC',
            boxShadow: 1,
        }}>
            <Grid container item xs={12}>
                <Grid container item xs={12} alignItems='center' py={0.5} gap={1}>
                    <Typography ml='6px' fontWeight={500} fontSize='1rem'>Entries</Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Box display='flex' width={32} ml='6px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Rank</Typography>
                    </Box>
                    <Box display='flex' width={32} ml='6px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Event</Typography>
                    </Box>
                    <Box display='flex' width='calc(100% - 226px)' ml='8px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Event Name</Typography>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' width={84} ml='8px' mr='50px' sx={{ bgcolor: '' }}>
                        <Typography fontSize='0.7rem' color='#667085'>Entry Time</Typography>
                    </Box>
                </Grid>
                {entries?.map((entry, index) => <SwimmerEntryCard key={entry?.id} entry={entry} index={index} competitor={competitor} />)}
            </Grid>
        </Grid>
    );
}
