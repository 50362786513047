import React, { useContext, useEffect } from "react";

import { Paper, Box, Typography, Grid, AppBar } from "@mui/material";
import { VscChecklist } from "react-icons/vsc";
import { GoListOrdered } from "react-icons/go";
import { GiPodiumWinner } from "react-icons/gi";

import {
  HandleEventStatus,
  getDateTime,
  openInNewTab,
  statusGradientColor,
} from "../../../../components/utils";
import {
  StyledSmallTab,
  StyledTabs,
  TimeProgramEntryIcon,
} from "../../../../components/layout";
import { LiveContext } from "../../../../context/LiveContext";
import useFetch from "../../../../hooks/graphql/useFetch";
import { GET_EVENT_RECORD } from "../../../../utils/graphql/queries";
import RecordBox from "../../../../components/layout/RecordBox";
import { BsFileEarmarkText, BsGlobe } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface props {
  combinedGroup: number | undefined;
  sponsorImg?: string;
  sponsorText?: string;
  roundStatus?: number;
  selectedTpe?: TimeProgramEntry;
  showOfficialTimeStamp?: boolean;
  setShowOfficialTimeStamp: React.Dispatch<React.SetStateAction<boolean>>;
  sponsorLink?: string;
  tpeFinal?: boolean;
  handleStatusClick: () => void;
  setEventView: (view: View) => void;
  handleEventViewChange: (e: React.ChangeEvent, newValue: number) => void;
  selectedEventViewTab: number | undefined;
  eventId: number | undefined;
  eventView: View;
  combinedCompetitions: CombinedCompetition[] | undefined;
  isActive: boolean;
}

export default function EventHeader({
  combinedGroup,
  eventView,
  eventId,
  selectedEventViewTab,
  handleEventViewChange,
  setEventView,
  handleStatusClick,
  sponsorImg,
  sponsorText,
  roundStatus,
  selectedTpe,
  showOfficialTimeStamp,
  setShowOfficialTimeStamp,
  sponsorLink,
  tpeFinal,
  isActive,
}: props) {
  const { currentTpeId, currentHeatObj } = useContext(LiveContext);
  const {
    data: eventRecords,
    loading,
    error,
  } = useFetch(GET_EVENT_RECORD, { id: eventId }, "id");

  const handleSponsorClick = (link: string) => {
    link && openInNewTab(link);
  };

  useEffect(() => {
    setShowOfficialTimeStamp(false);
  }, [selectedTpe]);
  const location = window.location.pathname;
  const navigate = useNavigate();

  return (
    <Paper elevation={3} sx={{ position: "sticky", top: "0px", zIndex: 100 }}>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems={"start"}
        py={0}
        mb={1}
        sx={{ bgcolor: "" }}
      >
        <Grid
          container
          item
          width={sponsorImg || sponsorText ? "calc(100% - 224px)" : "100%"}
          py={1}
          sx={{ bgcolor: "" }}
        >
          <Grid
            container
            item
            height="32px"
            width={
              sponsorImg || sponsorText
                ? "calc(100% - 98px)"
                : "calc(100% - 98px)"
            }
            justifyContent={{ xs: "center", md: "flex-start" }}
            alignItems="start"
            sx={{ bgcolor: "" }}
          >
            <Box display="flex" alignItems="start" width="100%">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="32px"
                minWidth="32px"
                ml={1}
                sx={{
                  borderRadius: 2,
                  background: statusGradientColor(
                    selectedTpe?.id,
                    currentTpeId,
                    selectedTpe?.round?.status,
                    selectedTpe?.start_time
                  ),
                }}
              >
                <Typography
                  color="#FFF"
                  fontSize="1rem"
                  lineHeight={1}
                  sx={{ mb: -0.25 }}
                >
                  <TimeProgramEntryIcon entry={selectedTpe} />
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                width="calc(100% - 32px)"
                height="32px"
              >
                <Box>
                  <Typography
                    noWrap
                    mx={1}
                    mb={-0.25}
                    fontWeight={600}
                    fontSize={{ xs: "1rem", sm: "1.2rem", md: "1.8rem" }}
                  >
                    {selectedTpe?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            width="90px"
            height="32px"
            mb={-0.25}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mr={1}
            onClick={() =>
              selectedTpe?.round?.status === 5 && handleStatusClick()
            }
            sx={{
              bgcolor: "",
              cursor: selectedTpe?.round?.status === 5 ? "pointer" : "inherit",
            }}
          >
            {eventView !== "combined" &&
            selectedTpe?.round?.status &&
            selectedTpe?.round?.status >= 0 &&
            HandleEventStatus(
              selectedTpe?.round?.status,
              isActive,
              currentHeatObj?.heat?.round?.id === selectedTpe?.round?.id
            )
              ? HandleEventStatus(
                  selectedTpe?.round?.status,
                  isActive,
                  currentHeatObj?.heat?.round?.id === selectedTpe?.round?.id
                )
              : HandleEventStatus(0)}
          </Grid>

          {roundStatus === 5 && showOfficialTimeStamp && (
            <Box
              display="flex"
              width="100%"
              mx={1}
              justifyContent="flex-end"
              alignItems="start"
              sx={{ bgcolor: "" }}
            >
              <Typography fontSize="0.8rem">
                Event official timestamp:{" "}
                {getDateTime(selectedTpe?.round?.updated_at)}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                px={0.5}
                ml="8px"
                onClick={() => handleStatusClick()}
                sx={{
                  borderRadius: 1,
                  border: "solid 1px",
                  height: "1rem",
                  cursor: "pointer",
                }}
              >
                <Typography fontSize="0.8rem">Close</Typography>
              </Box>
            </Box>
          )}
          <Box width="100%" pt="1em" sx={{ bgcolor: "" }}>
            {!loading &&
              eventRecords &&
              eventRecords.event_records.length > 0 && (
                <RecordBox eventRecords={eventRecords.event_records} />
              )}
          </Box>
        </Grid>
        <Grid
          item
          maxWidth={224}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "center", md: "flex-end" }}
          sx={{ bgcolor: "" }}
        >
          {sponsorImg && (
            <Box
              width="auto"
              maxWidth={224}
              maxHeight={120}
              mx={1}
              mt={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ bgcolor: "" }}
            >
              <Box
                component="img"
                width="auto"
                maxWidth={216}
                maxHeight={120}
                src={sponsorImg}
                onClick={() => sponsorLink && handleSponsorClick(sponsorLink)}
                sx={{
                  borderRadius: 1,
                  boxShadow: 3,
                  cursor: sponsorLink && "pointer",
                }}
                alt="Sponsor"
              />
            </Box>
          )}
          {!sponsorImg && sponsorText && (
            <Box
              maxWidth={224}
              maxHeight={120}
              display="flex"
              flexDirection="column"
              alignItems="center"
              mx={1}
              onClick={() => sponsorLink && handleSponsorClick(sponsorLink)}
              sx={{ cursor: sponsorLink && "pointer", bgcolor: "" }}
            >
              <Typography
                mx={2}
                fontWeight={300}
                fontSize={{ xs: "0.6rem", sm: "0.8rem", md: "1rem" }}
                sx={{ bgcolor: "" }}
              >
                Sponsor
              </Typography>
              <Typography
                mx={2}
                fontWeight={500}
                fontSize={{ xs: "0.6rem", sm: "0.8rem", md: "1rem" }}
                sx={{ bgcolor: "" }}
              >
                {sponsorText}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        mx={0}
        mt={0}
        display="flex"
        justifyContent="space-between"
        sx={{ bgcolor: "" }}
      >
        {/* @ts-ignore */}
        <AppBar
          position="static"
          /* @ts-ignore */
          color="logoBlue"
          sx={{
            borderRadius: "0px 0px 4px 4px",
            bgcolor: "",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div style={{ marginRight: "auto", marginLeft: "auto" }}>
            {selectedTpe?.type && selectedTpe?.type === 1 ? (
              <StyledTabs
                value={selectedEventViewTab}
                onChange={handleEventViewChange}
                centered
                sx={{}}
              >
                <StyledSmallTab
                  label={tabLabel(tpeFinal ? "Qualified" : "Entries")}
                  onClick={() => setEventView("entries")}
                  sx={{ height: "2.4rem", color: "#CCC" }}
                />
                <StyledSmallTab
                  label={tabLabel("Heats")}
                  onClick={() => setEventView("heats")}
                  sx={{ height: "2.4rem", color: "#CCC" }}
                />
                <StyledSmallTab
                  label={tabLabel("Summary")}
                  onClick={() => setEventView("summary")}
                  sx={{ height: "2.4rem", color: "#CCC" }}
                />
                {combinedGroup && (
                  <StyledSmallTab
                    label={tabLabel("Combined")}
                    onClick={() => setEventView("combined")}
                    sx={{ height: "2.4rem", color: "#CCC" }}
                  />
                )}
              </StyledTabs>
            ) : (
              <Box width="100%" height="2.4rem" />
            )}
          </div>
          <div style={{ position: "absolute", right: 10, top: 6 }}>
            <BsFileEarmarkText
              size={26}
              className="hoverIcon"
              onClick={() => {
                const decodedPaths = location
                  .split("/")
                  .filter((x) => x)
                  .map((path) => decodeURI(path));

                const replacePath = decodedPaths[3];
                const newPath = location.replace(replacePath, "text");
                navigate(newPath);
              }}
            />
          </div>
        </AppBar>
      </Grid>
    </Paper>
  );
}

const tabLabel = (string: string) => {
  if (string === "Entries") {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Box sx={{ bgcolor: "", top: "2px", position: "relative" }}>
          <VscChecklist size="1.2rem" className="react-icons" />
        </Box>
        <Typography sx={{ fontSize: { xs: "0.6rem", sm: "1rem" } }}>
          Entries
        </Typography>
      </Box>
    );
  }
  if (string === "Qualified") {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Box sx={{ bgcolor: "", top: "2px", position: "relative" }}>
          <VscChecklist size="1.2rem" className="react-icons" />
        </Box>
        <Typography sx={{ fontSize: { xs: "0.6rem", sm: "1rem" } }}>
          Qualified
        </Typography>
      </Box>
    );
  }
  if (string === "Heats") {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Box sx={{ bgcolor: "", top: "2px", position: "relative" }}>
          <GoListOrdered size="1.2rem" className="react-icons" />
        </Box>
        <Typography sx={{ fontSize: { xs: "0.6rem", sm: "1rem" } }}>
          Heats
        </Typography>
      </Box>
    );
  }
  if (string === "Summary") {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Box sx={{ bgcolor: "", top: "2px", position: "relative" }}>
          <GiPodiumWinner size="1.2rem" className="react-icons" />
        </Box>
        <Typography sx={{ fontSize: { xs: "0.6rem", sm: "1rem" } }}>
          Summary
        </Typography>
      </Box>
    );
  }
  if (string === "Combined") {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Box sx={{ bgcolor: "", top: "2px", position: "relative" }}>
          <BsGlobe size="1.2rem" className="react-icons" />
        </Box>
        <Typography sx={{ fontSize: { xs: "0.6rem", sm: "1rem" } }}>
          Combined
        </Typography>
      </Box>
    );
  }
};
