import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";

import { BsPeopleFill } from "react-icons/bs";
import { FlagComponent, Medals } from "../../../../components/layout";
import { competitorNavigationString } from "../../../../components/utils";

export default function ResultsMedalCard({ row, rank, club, index }) {
  const navigate = useNavigate();
  const params = useParams();

  const [imgLoaded, setImgLoaded] = useState(true);

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        mt={0}
        py={0.5}
        onClick={() => {
          navigate(
            competitorNavigationString(params.competitionName, club?.oid)
          );
        }}
        sx={{
          bgcolor: "#FFF",
          cursor: "pointer",
          borderTopStyle: index !== 0 && "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
        }}
      >
        <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="auto"
            height="32px"
            width="32px"
            sx={{
              bgcolor: "#F4C647",
              borderRadius: 2,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography lineHeight={1} fontSize="1rem">
                {rank}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
          <Box
            component="img"
            height="32px"
            width="32px"
            src={`${
              process.env.REACT_APP_CDN_URL
            }club_logos/${club?.country_code.toLowerCase()}/32/${club?.short_name.toLowerCase()}.png`}
            onError={() => setImgLoaded(false)}
            sx={{ display: imgLoaded ? "inline" : "none" }}
            alt={`${club?.name} logo`}
          />
          {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
          {!imgLoaded && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="32px"
              width="32px"
              sx={{ bgcolor: "#374B5C", borderRadius: 2 }}
            >
              <BsPeopleFill color="#FFF" size="28px" />
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          width={16}
          height={12}
          ml="6px"
          mb="2px"
          sx={{ boxShadow: 2, borderRadius: 1, bgcolor: "" }}
        >
          <FlagComponent countryCode={club?.country_code} size="s" />
        </Box>
        <Box display="flex" width="calc(100% - 284px)" ml="8px">
          <Typography noWrap lineHeight={1} fontWeight={500}>
            {club?.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          <Medals
            bronze={row?.medal_bronze}
            silver={row?.medal_silver}
            gold={row?.medal_gold}
            total={row?.medal_total}
          />
        </Box>
      </Grid>
    </>
  );
}
