import FlagComponent, { CountryCode } from './FlagComponent';
import { Box, Typography } from '@mui/material';

export default function RecordListItem({ record }: { record: EventRecord; }) {

    return (
        <Box sx={{ width: '100%', display: 'flex', gap: 0.5, borderBottom: '1px solid #D0D5DD', bgcolor: '', '&:last-child' : {borderBottom: 'none'} }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 1,
                py: 0.5,
                minWidth: '50%',
                bgcolor: ''
            }}>
                <Box sx={{ display: 'flex', width: '80%', maxWidth: '80%', gap: 1, bgcolor: '' }}>
                    <Box sx={{ height: '2.2rem', width: '3.2rem', minWidth: '3.2rem', backgroundColor: '#374b5c', display: "grid", alignContent: "center", textAlign: "center", borderRadius: '5px', padding: '3px' }}>
                        <Typography sx={{
                            color: 'white', fontSize: '0.8rem'
                        }}>
                            {record.record_abbreviation}
                        </Typography>
                    </Box>
                    <Typography my='auto' fontWeight={700} sx={{
                        fontSize: '0.85rem',
                        alignItems: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                    }}>
                        {record?.record_name}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    minWidth: '20%',
                    maxWidth: '20%',
                    bgcolor: ''
                }}>
                    <Typography noWrap fontSize='0.9rem' fontWeight={700}>
                        {record.time_text}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '50%',
                maxWidth: '50%',
                mr: 1,
                bgcolor: ''
            }}>
                <Box sx={{
                    display: 'flex',
                    my: 'auto',
                    minWidth: '30%',
                    justifyContent: 'center',
                    bgcolor: ''
                }}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        maxWidth: 'calc(100% - 16px)',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        bgcolor: ''
                    }}>
                        {record?.record_date && record.record_date !== '0001-01-01' &&
                            <Typography noWrap fontSize='0.8rem' sx={{ bgcolor: '' }}>
                                {record?.record_date}
                            </Typography>
                        }
                        <Box sx={{}}>
                            {record.meet_nation ?
                                <Box sx={{ display: 'flex', gap: 0.5, bgcolor: '' }}>
                                    <Box>
                                        <FlagComponent size='s' countryCode={record?.meet_nation as CountryCode} />
                                    </Box>
                                    <Typography noWrap fontSize='0.8rem' sx={{ bgcolor: '' }}>
                                        {record?.meet_location}
                                    </Typography>
                                </Box>
                                :
                                <Box sx={{ display: 'flex' }}>
                                    <Typography noWrap fontSize='0.8rem' sx={{ bgcolor: '' }}>
                                        {record?.meet_location}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    width: '70%',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                    bgcolor: ''
                }}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        bgcolor: ''
                    }}>
                        <Typography noWrap width='100%' fontWeight={500} fontSize='0.9rem' sx={{ bgcolor: '' }}>
                            {record?.first_name} {record?.last_name}
                        </Typography>
                        <Box sx={{ display: 'flex', width: '100%', bgcolor: '' }}>
                            {record.swimmer_nation &&
                                <Box sx={{ mr: 0.5 }}>
                                    <FlagComponent size='s' countryCode={record?.swimmer_nation as CountryCode} />
                                </Box>
                            }
                            <Typography noWrap width='100%' fontSize='0.8rem'>
                                {record.club}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
