import React, { useContext, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";

import { Grid } from "@mui/material";

import ClubViewEntriesList from "./ClubViewEntriesList";
import ClubViewEventList from "./ClubViewEventList";
import { GET_CLUBVIEW_ENTRIES } from "../../../../../../utils/graphql/queries";
import QueryResult from "../../../../../../components/containers/QueryResult";
import { ScreenContext } from "../../../../../../context/ScreenContext";
import ReactGA from "react-ga4";

export default function ClubViewEntries({
  competitionId,
  setView,
  club,
  setSelectedCompetitor,
  showAge,
  clubViewSortOrder,
}) {
  const [events, setEvents] = useState([]);
  const [competitors, setCompetitors] = useState([]);

  const { screen } = useContext(ScreenContext);
  useMemo(() => {
    ReactGA.set({ page_title: `${screen} - Clubs - Entries` });
  }, [screen]);

  const { loading, error, data } = useQuery(GET_CLUBVIEW_ENTRIES, {
    variables: {
      oid: club?.oid,
      competitionId: competitionId,
    },
  });

  useMemo(() => {
    if (data) {
      let newEvents = [];
      let newCompetitors = [];
      data.competitor.forEach((competitor) => {
        newCompetitors.push({ ...competitor, entries: [] });
      });

      data.events.forEach((event, i) => {
        newEvents.push({ ...event, entries: [] });
        event?.entries?.map((entry) => {
          let competitor = newCompetitors.find(
            (competitor) => competitor?.oid === entry?.competitor?.oid
          );
          let index = newCompetitors.findIndex(
            (competitor) => competitor?.oid === entry?.competitor?.oid
          );
          let newEntry = { ...entry, competitor: { ...competitor } };
          newEvents[i]?.entries?.push({ ...newEntry });
          let competitorEntry = { ...entry, event: event };
          newCompetitors[index].entries.push(competitorEntry);
        });
      });
      setEvents(newEvents);
      setCompetitors(newCompetitors);
    }
  }, [data]);

  return (
    <Grid container>
      <Grid container item height={loading ? 5000 : 'auto'}>
        <QueryResult error={error} loading={loading} data={data}>
          {clubViewSortOrder === 1 && (
            <>
              {events.length > 0 &&
                events.map((event) => (
                  <ClubViewEventList
                    key={event?.id}
                    setView={setView}
                    event={event}
                    setSelectedCompetitor={setSelectedCompetitor}
                    showAge={showAge}
                  />
                ))}
            </>
          )}
          {clubViewSortOrder === 0 && (
            <>
              {competitors.length > 0 &&
                competitors.map(
                  (competitor) =>
                    competitor?.entries?.length > 0 && (
                      <ClubViewEntriesList
                        key={competitor?.id}
                        setView={setView}
                        competitor={competitor}
                        setSelectedCompetitor={setSelectedCompetitor}
                        showAge={showAge}
                      />
                    )
                )}
            </>
          )}
        </QueryResult>
      </Grid>
    </Grid>
  );
}
