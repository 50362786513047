import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { HeatLaneHeaders } from "../../../../../../components/layout";
import {
  HandleHeatStatus,
  getStartTime,
  getEventLevel,
} from "../../../../../../components/utils";
import HeatLane from "../../../../event/components/heats/HeatLane";

export default function ClubViewHeatEventCard({
  setView,
  event,
  heat,
  currentHeatId,
  isRelay,
  setSelectedCompetitor,
  showAge,
}) {
  const [eventLevel, setEventLevel] = useState(0);

  useEffect(() => {
    if (event) {
      setEventLevel(getEventLevel(event?.event_competition_level));
    }
  }, [event]);

  return (
    <Grid
      container
      item
      mt={1}
      sx={{
        borderRadius: 1,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
        bgcolor: "#E4E7EC",
        boxShadow: 1,
      }}
    >
      <Box
        display="flex"
        width="100%"
        mx={0.5}
        my={0.5}
        //TODO: navigation to event
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          sx={{ bgcolor: "" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="auto"
            height="2.8rem"
            width="2.8rem"
            sx={{
              bgcolor: "#374B5C",
              borderRadius: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography fontSize="1rem" color="#FFF">
                {event?.number}
              </Typography>
              <Typography fontSize="0.75rem" lineHeight={1} color="#FFF">
                event
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            ml="8px"
            my="auto"
            height="2.8rem"
            width="2.8rem"
            sx={{
              bgcolor: "#F9FAFB",
              borderRadius: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography fontSize="1rem">{heat?.number}</Typography>
              <Typography fontSize="0.75rem" lineHeight={1}>
                heat
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography mx={1} fontWeight="bold" fontSize="1.1rem">
              {heat?.time_program_entry?.name}
            </Typography>
            {heat?.status > 1 ? (
              <Typography mx={1} fontWeight="300" fontSize="0.8rem">
                Started at: {getStartTime(heat?.start_time)}
              </Typography>
            ) : (
              <Typography mx={1} fontWeight="300" fontSize="0.8rem">
                Estimated start time: {getStartTime(heat?.estimated_start_time)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mr="4px"
          sx={{ bgcolor: "" }}
        >
          {<HandleHeatStatus heat={heat} currentHeatId={currentHeatId} />}
        </Box>
      </Box>

      {/* HEAT LANES START */}
      <Grid container>
        <HeatLaneHeaders
          isRelay={isRelay}
          showAge={showAge}
          eventLevel={eventLevel}
        />
        {heat?.lanes.map(
          (lane, index) =>
            lane?.competitor && (
              <HeatLane
                lane={lane}
                key={lane?.id}
                index={index}
                heatStatus={heat?.status}
                event={event}
                eventLevel={eventLevel}
                setView={setView}
                setSelectedCompetitor={setSelectedCompetitor}
                showAge={showAge}
                eventType={event?.event_type}
              />
            )
        )}
      </Grid>
    </Grid>
  );
}
