import React, {useState} from "react";
export const SeoContext = React.createContext<{ competitionSEO: string | undefined, setCompetitionSEO: (string: string | undefined) => void}>({competitionSEO: undefined, setCompetitionSEO: () => {}});

export function SeoContextProvider({children}: {children: React.ReactNode}){

    const [seoStr, setSeoStr] = useState<string | undefined>(undefined);

    const setCompetitionSEO = (str: string | undefined) => {
        setSeoStr(str);
    }

    return (
        <SeoContext.Provider value={{competitionSEO: seoStr, setCompetitionSEO: setCompetitionSEO}}>
            {children}
        </SeoContext.Provider>
    )
}

