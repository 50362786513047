import React, { useContext } from 'react';

import useLiveCurrentHeat from '../hooks/useLiveCurrentHeat';
import useCompetitionId from '../hooks/useCompetitionId';
import { SeoContext } from './SeoContext';

interface ExtendedCurrentHeatProperties extends CurrentHeatProperties { loading: boolean; };

export const LiveContext = React.createContext<ExtendedCurrentHeatProperties>({ loading: true, currentHeatObj: undefined, currentTpeId: undefined, currentHeatUpdateThreshold: undefined, liveSessionId: undefined });

export default function LiveContextProvider({ children }: { children: React.ReactNode; }) {
    const { competitionSEO } = useContext(SeoContext);
    const { competitionId } = useCompetitionId(competitionSEO);

    const { data: { currentHeatObj, currentTpeId, currentHeatUpdateThreshold, liveSessionId }, error, loading } = useLiveCurrentHeat(competitionId);

    if (error) {
        console.error('Error occured in LiveContext, error:', error);
    }

    return (
        <LiveContext.Provider value={{ loading, currentHeatObj, currentTpeId, currentHeatUpdateThreshold, liveSessionId }}>
            {children}
        </LiveContext.Provider>
    );
} 