import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
    Table,
    TableBody,
    TableRow,
    Divider,
    Grid,
} from '@mui/material';
import { MainTableLayout, Breadcrumbs } from '../components/layout';
import NoSearchResults from './components/NoSearchResults';
import MetaDecorator from '../components/utils/MetaDecorator';
import { AUClient, EUClient } from '../utils/apollo/apollo-client';
import QueryResult from '../components/containers/QueryResult';
import { SEARCH_COMPETITIONS } from '../utils/graphql/queries';
import Header from '../components/Header';
import DetailedCompetitionCard from '../components/containers/DetailedCompetitionCard';


export default function Search() {

    const location = useLocation();
    const query = location.state?.query;

    const [euCompetitions, setEuCompetitions] = useState([]);
    const [auCompetitions, setAuCompetitions] = useState([]);
    const [combinedCompetitions, setCombinedCompetitions] = useState([]);

    const [euFetchSearch, { loading: euLoading }] = useLazyQuery(SEARCH_COMPETITIONS, {
        client: EUClient,
        fetchPolicy: 'network-only',
        variables: { _ilike: `%${query}%` },
        onCompleted: data => {
            const euComps = data?.competitions.map((competition) => ({
                ...competition, database_region: 1
            }));
            setEuCompetitions(euComps);
        }
    });

    const [auFetchSearch, { loading: auLoading }] = useLazyQuery(SEARCH_COMPETITIONS, {
        client: AUClient,
        fetchPolicy: 'network-only',
        variables: { _ilike: `%${query}%` },
        onCompleted: data => {
            const auComps = data?.competitions.map((competition) => ({
                ...competition, database_region: 2
            }));
            setAuCompetitions(auComps);
        }
    });

    useEffect(() => {
        euFetchSearch();
        auFetchSearch();
    }, [query]);

    /* HANDLE COMBINED FETCH RESULTS */
    useEffect(() => {
        if (euCompetitions?.length > 0 && auCompetitions?.length > 0) {
            const combinedResults = [...euCompetitions, ...auCompetitions];
            const sortedCombinedResults = combinedResults.sort((a, b) => (new Date(a?.startDate).getTime() || -Infinity) - (new Date(b?.startDate).getTime() || -Infinity));
            sortedCombinedResults?.length > 0 && setCombinedCompetitions(sortedCombinedResults);
        } else if (euCompetitions?.length > 0 && auCompetitions?.length < 1) {
            setCombinedCompetitions(euCompetitions);
        } else if (euCompetitions?.length < 1 && auCompetitions?.length > 0) {
            setCombinedCompetitions(auCompetitions);
        }
    }, [euCompetitions, auCompetitions]);

    const loading = !combinedCompetitions && euLoading || auLoading;

    return (
        <>
            <MetaDecorator title='Swimify - Search' description='Swimify Search Results Page' />
            <Header />
            <MainTableLayout
                title={<Breadcrumbs />}
            >
                <Grid item xs={12}>
                    <Table component='div' size='small'>
                        <TableBody component='div'>
                            <Divider />
                            <QueryResult loadingLarge={loading} data={combinedCompetitions}>
                                {/* if competition array is empty */}
                                {combinedCompetitions?.length === 0 &&
                                    <NoSearchResults query={query} />
                                }
                                {/* if competition array is filled */}
                                {combinedCompetitions?.map((competition, index) => (
                                    <TableRow component='div' key={competition?.id} sx={{ background: index % 2 ? 'white' : 'linear-gradient(180deg, #F9FAFB, #FCFCFD, #F9FAFB)' }}>
                                        <DetailedCompetitionCard competition={competition} index={index} />
                                    </TableRow>
                                ))}
                            </QueryResult>
                        </TableBody>
                    </Table>
                </Grid>
            </MainTableLayout>
        </>
    )
}
