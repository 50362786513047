import { Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { LiveContext } from "../context/LiveContext";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { CompetitionDetailsContext } from "../context/CompetitionDetailsContext";

export default function StopWatch({
  heat,
  currentHeatId,
  showZero,
  distance,
}: {
  heat: Heat | undefined;
  currentHeatId: number | undefined;
  showZero: boolean;
  distance: number | undefined;
}) {
  const { currentHeatUpdateThreshold } = useContext(LiveContext);
  const { serverTimeDiff } = useContext(CompetitionDetailsContext);

  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isLive, setIsLive] = useState(false);

  const [started, setStarted] = useState<string | undefined>(heat?.start_time);

  const startTime = useRef(0);
  const isFinished = useRef(false);

  const getCorrectedCurrentTime = () => {
    const corrected = Date.now() - serverTimeDiff;
    return corrected;
  };

  useEffect(() => {
    if (
      heat?.status === 2 &&
      heat?.id === currentHeatId &&
      currentHeatUpdateThreshold &&
      getCorrectedCurrentTime() < currentHeatUpdateThreshold
    ) {
      setStarted(heat?.start_time);
      setIsLive(true);
    } else {
      setIsLive(false);
      isRunning && setIsRunning(false);
    }
  }, [heat, currentHeatId, currentHeatUpdateThreshold]);

  // stops clock when all swimmers have swum the full distance or dsq/dns/dnf
  useEffect(() => {
    if (isLive && isRunning) {
      const activeLanes = heat?.lanes?.filter((lane) => lane.competitor);

      const heatIsFinished =
        activeLanes?.filter(
          (lane) =>
            lane.swimmed_distance === distance ||
            lane.dnf ||
            lane.dns ||
            lane.dsq
        ).length === activeLanes?.length;

      if (heatIsFinished) {
        isFinished.current = true;
        setIsRunning(false);
      }
    }
  }, [heat?.lanes, isRunning]);

  // starts clock
  useEffect(() => {
    isRunning && setIsRunning(false);
    if (isLive) {
      if (
        serverTimeDiff &&
        started &&
        started !== "0001-01-01T00:00:00+00:00"
      ) {
        const startTimeMs = new Date(started).getTime();
        if (!isFinished.current) {
          startTime.current = startTimeMs;
          if (startTimeMs <= getCorrectedCurrentTime()) {
            setIsRunning(true);
          } else {
            setTimeout(
              () => setIsRunning(true),
              startTimeMs - getCorrectedCurrentTime()
            );
          }
        }
      }
    }
  }, [started, serverTimeDiff, isLive]);

  // Stopwatch / running time clock
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    const newTime = getCorrectedCurrentTime() - startTime.current;

    if (isRunning) {
      if (newTime / 60000 >= 40) {
        //console.log("Clock exceeded 40 minutes. Shutting off.");
        setIsRunning(false);
      }

      intervalId = setInterval(() => {
        setTime(newTime);
      }, 25);
    } else {
      time > 0 && setTime(0);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  const minutes = Math.floor(time / 60000)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((time / 1000) % 60)
    .toString()
    .padStart(2, "0");
  const deciseconds = Math.floor(((time / 10) % 100) / 10).toString();

  // TEST CLOCK

  /* useEffect(() => {
    if (serverTimeDiff) {
      setIsLive(true)
      if (heat?.number === 1) {
        // setInterval(() => {
        //   startTime.current = (getCorrectedCurrentTime())
        // }, 10000)
        startTime.current = (getCorrectedCurrentTime() - 2388000)
        setTimeout(() => {

          setIsRunning(true)
        }, 3000)
      }
    }
  }, [serverTimeDiff]) */

  // TEST CLOCK

  return (
    <>
      {isLive && isRunning && time > 0 && (
        <Typography
          fontSize="1rem"
          fontWeight={500}
          mx={1}
          paddingTop="2px"
          paddingX="10px"
          color={"white"}
          borderRadius={"6px"}
          sx={{ bgcolor: "#374b5c" }}
        >
          {isRunning && time > 0
            ? `${minutes}:${seconds}.${deciseconds}`
            : showZero
            ? "00:00.0"
            : ""}
        </Typography>
      )}
    </>
  );
}
