import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Typography,
} from '@mui/material';

export default function EmptyCompetitionSessions() {

    const navigate = useNavigate();

    return (
        <Box mx={2} my={2} width='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ bgcolor: '' }}>
            <Typography fontWeight='bold' fontSize={{xs: '1.5rem', sm: '2rem'}}>No Competition Sessions</Typography>
            <Typography fontWeight='300' fontSize='1rem' fontStyle='italic' onClick={() => navigate('/competitions')} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Back to All Competitions</Typography>
        </Box>
    )
}