import React, { useState } from 'react';

interface ScreenContext {
    screen: ScreenType,
    selectedScreenTabIndex: number;
    setScreen: (screenType: ScreenType) => void,
}

const ScreenTypeToNumber = (screenType: ScreenType): number => {
    switch (screenType) {
        case 'events':
            return 0;
        case 'points':
            return 1;
        case 'swimmers':
            return 2;
        case 'files':
            return 3;
        case 'scoreboard':
            return 4;
        case 'superlive':
            return 5;
    }
}

export const ScreenContext = React.createContext<ScreenContext>({screen: 'events', selectedScreenTabIndex: 0, setScreen: ()=>{}});

export default function ScreenContextProvider({children}: {children: React.ReactNode}) {
    const [screen, setScreenState] = useState<ScreenType>('events');
    const [selectedScreenTabIndex, setSelectedScreenTabIndex] = useState<number>(0)
    const setScreen = (screenType: ScreenType) => {
        setScreenState(screenType);
        setSelectedScreenTabIndex(ScreenTypeToNumber(screenType));
    }
    return (
        <ScreenContext.Provider value={{screen, selectedScreenTabIndex, setScreen}}>
            {children}
        </ScreenContext.Provider>
    )
} 