import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { ImArrowUpRight2, ImArrowDownRight2 } from "react-icons/im";

import {
  Split,
  calculateSplits,
} from "../../../../components/utils/calculateSplits";

import styled from "styled-components";

export default function LiveLane({
  lane,
  distance,
  lanesQuantity,
  lowestSplits,
  trySplits,
}: {
  lane: Lane;
  distance: number;
  lanesQuantity: number;
  lowestSplits: { "50": number; "100": number; "200": number };
  trySplits: (splits: { "50": number; "100": number; "200": number }) => void;
}) {
  const [sub_results, setSubResults] = useState<SubResult[]>(
    lane?.sub_results ? lane.sub_results : []
  );
  const [splits, setSplits] = useState<Split>({});
  const splitsAmount = distance / 50;

  //console.log(splits);
  //console.log(lane.swimmed_distance);

  useEffect(() => {
    if (distance && sub_results) {
      setSplits(calculateSplits(splitsAmount, sub_results));
    }
  }, [sub_results]);

  useEffect(() => {
    setSubResults(lane?.sub_results ? lane.sub_results : []);
  }, [lane]);

  useEffect(() => {
    splits &&
      trySplits({
        "50": Number(splits.current50),
        "100": Number(splits.current100),
        "200": Number(splits.current200),
      });
  }, [splits]);

  const convertNumberToTimeString = (value: number) => {
    if (!isNaN(value)) {
      let time = new Date(value).toISOString().slice(15, 22);
      if (time[0] === "0") time = time.slice(2);
      return time;
    } else return "";
  };

  return (
    <Animation
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: `calc((100% - (2.5em)) / ${lanesQuantity})`,
      }}
    >
      <Typography
        borderBottom={"1px solid rgb(208, 213, 221)"}
        bgcolor={"#e4e7ec"}
        color={"rgb(102, 112, 133)"}
        display={"flex"}
        alignItems={"center"}
        height={"24px"}
        fontSize={"0.7rem"}
        textAlign={"left"}
        pl={"10px"}
      >
        Lane {lane.number}
      </Typography>
      {lane.competitor ? (
        <Box
          sx={{
            borderRight: "1px solid rgb(208, 213, 221)",
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <Box
            bgcolor={"#FFF"}
            height={"28px"}
            sx={{
              borderTop: "1px solid rgb(208, 213, 221)",
              position: "relative",
              display: "flex",
              justifyContent: "start",
              px: "7px",
              gap: "4px",
              alignItems: "center",
            }}
            fontSize="0.9rem"
          >
            <Typography
              bgcolor={"#FFF"}
              textAlign={"left"}
              pl={"5px"}
              fontSize={"0.9rem"}
              fontWeight={"600"}
              noWrap
            >
              {lane.competitor.full_name}
            </Typography>
          </Box>
          <Box
            bgcolor={"#FFF"}
            height={"28px"}
            sx={{
              borderTop: "1px solid rgb(208, 213, 221)",
              position: "relative",
              display: "flex",
              justifyContent: "start",
              pl: "7px",
              gap: "4px",
              alignItems: "center",
            }}
            fontSize="0.9rem"
          >
            <Typography
              bgcolor={"#FFF"}
              textAlign={"left"}
              pl={"5px"}
              fontSize={"0.9rem"}
            >
              {lane.swimmed_distance && lane.swimmed_distance > 0
                ? lane.swimmed_distance + "m"
                : ""}
            </Typography>
          </Box>

          <Box
            bgcolor={"#FFF"}
            height={"28px"}
            sx={{
              borderTop: "1px solid rgb(208, 213, 221)",
              position: "relative",
              display: "flex",
              justifyContent: "start",
              pl: "7px",
              gap: "4px",
              alignItems: "center",
            }}
            fontSize="0.9rem"
          >
            <Typography
              className={
                lowestSplits[50] === splits.current50
                  ? "time highlight"
                  : "time"
              }
              /* TODO:is it supposed to be my={'52px'}? */ my={"52x"}
              fontSize={"0.9rem"}
              display={"flex"}
              alignItems={"center"}
              marginBottom={"-2px"}
              px={"5px"}
              justifyContent={"start"}
              borderRadius={"4px"}
            >
              {convertNumberToTimeString(splits.current50 as number)}
            </Typography>
            {typeof splits.current50 !== "string" &&
            splits.current50 > 0 &&
            splits.prev50 !== 0 ? (
              <Typography
                position={"absolute"}
                right={".5em"}
                top={"8%"}
                height={"100%"}
                my={"5px"}
              >
                <SymbolIcon symbol={splits.change50} />
              </Typography>
            ) : (
              ""
            )}
          </Box>

          {distance >= 100 && (
            <Box
              bgcolor={"#FFF"}
              height={"28px"}
              sx={{
                borderTop: "1px solid rgb(208, 213, 221)",
                position: "relative",
                display: "flex",
                justifyContent: "start",
                pl: "7px",
                gap: "4px",
                alignItems: "center",
              }}
              fontSize="0.9rem"
            >
              <Typography
                className={
                  lowestSplits[100] === splits.current100
                    ? "time highlight"
                    : "time"
                }
                /* TODO:is it supposed to be my={'52px'}? */ my={"52x"}
                fontSize={"0.9rem"}
                display={"flex"}
                alignItems={"center"}
                marginBottom={"-2px"}
                px={"5px"}
                justifyContent={"start"}
                borderRadius={"4px"}
              >
                {convertNumberToTimeString(splits.current100 as number)}
              </Typography>
              {typeof splits.current100 !== "string" &&
              splits.current100 > 0 ? (
                <Typography
                  position={"absolute"}
                  right={".5em"}
                  top={"8%"}
                  height={"100%"}
                  my={"5px"}
                >
                  <SymbolIcon symbol={splits.change100} />
                </Typography>
              ) : (
                ""
              )}
            </Box>
          )}

          {distance >= 200 && (
            <Box
              bgcolor={"#FFF"}
              height={"28px"}
              sx={{
                borderTop: "1px solid rgb(208, 213, 221)",
                position: "relative",
                display: "flex",
                justifyContent: "start",
                pl: "7px",
                gap: "4px",
                alignItems: "center",
              }}
              fontSize="0.9rem"
            >
              <Typography
                className={
                  lowestSplits[200] === splits.current200
                    ? "time highlight"
                    : "time"
                }
                /* TODO:is it supposed to be my={'52px'}? */ my={"52x"}
                fontSize={"0.9rem"}
                display={"flex"}
                alignItems={"center"}
                marginBottom={"-2px"}
                px={"5px"}
                justifyContent={"start"}
                borderRadius={"4px"}
              >
                {convertNumberToTimeString(splits.current200 as number)}
              </Typography>
              {typeof splits.current200 !== "string" &&
              splits.current200 > 0 ? (
                <Typography
                  position={"absolute"}
                  right={".5em"}
                  top={"8%"}
                  height={"100%"}
                  my={"5px"}
                >
                  <SymbolIcon symbol={splits.change200} />
                </Typography>
              ) : (
                ""
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            borderRight: "1px solid rgb(208, 213, 221)",
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            userSelect: "none",
          }}
        >
          <Box
            bgcolor={"#FFF"}
            height={"28px"}
            sx={{
              borderTop: "1px solid rgb(208, 213, 221)",
              display: "flex",
              justifyContent: "center",
              gap: "4px",
              alignItems: "center",
            }}
            fontSize="0.9rem"
          >
            <Typography
              className={
                lowestSplits[50] === splits.current50
                  ? "time highlight"
                  : "time"
              }
              my={"2px"}
              fontSize={"0.9rem"}
              display={"flex"}
              alignItems={"center"}
              minWidth={"54px"}
              justifyContent={"center"}
              borderRadius={"4px"}
            >
              &nbsp;
            </Typography>
          </Box>
          <Box
            bgcolor={"#FFF"}
            height={"28px"}
            sx={{
              borderTop: "1px solid rgb(208, 213, 221)",
              display: "flex",
              justifyContent: "center",
              gap: "4px",
              alignItems: "center",
            }}
            fontSize="0.9rem"
          >
            <Typography
              className={
                lowestSplits[50] === splits.current50
                  ? "time highlight"
                  : "time"
              }
              my={"2px"}
              fontSize={"0.9rem"}
              display={"flex"}
              alignItems={"center"}
              minWidth={"54px"}
              justifyContent={"center"}
              borderRadius={"4px"}
            >
              &nbsp;
            </Typography>
          </Box>
          <Box
            bgcolor={"#FFF"}
            height={"28px"}
            sx={{
              borderTop: "1px solid rgb(208, 213, 221)",
              display: "flex",
              justifyContent: "center",
              gap: "4px",
              alignItems: "center",
            }}
            fontSize="0.9rem"
          >
            <Typography
              className={
                lowestSplits[50] === splits.current50
                  ? "time highlight"
                  : "time"
              }
              my={"2px"}
              fontSize={"0.9rem"}
              display={"flex"}
              alignItems={"center"}
              minWidth={"54px"}
              justifyContent={"center"}
              borderRadius={"4px"}
            >
              &nbsp;
            </Typography>
          </Box>

          {distance >= 100 && (
            <Box
              bgcolor={"#FFF"}
              height={"28px"}
              sx={{
                borderTop: "1px solid rgb(208, 213, 221)",
                display: "flex",
                justifyContent: "center",
                gap: "4px",
                alignItems: "center",
              }}
              fontSize="0.9rem"
            >
              <Typography
                className={
                  lowestSplits[50] === splits.current50
                    ? "time highlight"
                    : "time"
                }
                my={"2px"}
                fontSize={"0.9rem"}
                display={"flex"}
                alignItems={"center"}
                minWidth={"54px"}
                justifyContent={"center"}
                borderRadius={"4px"}
              >
                &nbsp;
              </Typography>
            </Box>
          )}

          {distance >= 200 && (
            <Box
              bgcolor={"#FFF"}
              height={"28px"}
              sx={{
                borderTop: "1px solid rgb(208, 213, 221)",
                display: "flex",
                justifyContent: "center",
                gap: "4px",
                alignItems: "center",
              }}
              fontSize="0.9rem"
            >
              <Typography
                className={
                  lowestSplits[50] === splits.current50
                    ? "time highlight"
                    : "time"
                }
                my={"2px"}
                fontSize={"0.9rem"}
                display={"flex"}
                alignItems={"center"}
                minWidth={"54px"}
                justifyContent={"center"}
                borderRadius={"4px"}
              >
                &nbsp;
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Animation>
  );
}

const SymbolIcon = ({
  symbol,
}: {
  symbol: "+" | "-" | "=" | number;
}): JSX.Element => {
  return (
    <>
      {symbol === "=" ? (
        ""
      ) : symbol === "+" ? (
        <ImArrowUpRight2 color="red" />
      ) : symbol === "-" ? (
        <ImArrowDownRight2 color="green" />
      ) : (
        ""
      )}
    </>
  );
};

const Animation = styled(Box)`
    .time{
        transition: all .2s  .5s ease-in-out;
        &.highlight{
            color: white;
            background-color: #374b5c;
        }
    }
`;
