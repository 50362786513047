import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import { CurrentHeatSelection } from "../ScoreboardView";
import { StyledTabs } from "../../../../components/layout";
import { AppBar } from "@mui/material";
import { BlankTabs } from "../../../../components/layout/BlankTabs";
import { CurrentHeatContext } from "../../../../context/CurrentHeatContext";

export default function HeatChanger({
  selectedHeat,
  setSelectedCurrentHeat,
  mode = "normal",
}: {
  selectedHeat: CurrentHeatSelection;
  setSelectedCurrentHeat: React.Dispatch<
    React.SetStateAction<CurrentHeatSelection>
  >;
  mode?: "normal" | "fullscreen";
}) {
  const { currentHeats, disableBtns, aboutToUpdate } =
    useContext(CurrentHeatContext);
  const thereIsNext =
    currentHeats &&
    currentHeats?.length > 1 &&
    currentHeats?.find((heat) => heat.heat_type === 3);
  const thereIsPrev =
    currentHeats &&
    currentHeats?.length > 1 &&
    currentHeats?.find((heat) => heat.heat_type === 2);

  const [buttonIndex, setButtonIndex] = useState<number>(1);

  const handleTabListChange = (e: React.ChangeEvent, newValue: number) => {
    if (!disableBtns) {
      setButtonIndex(newValue);
      if (thereIsPrev && newValue === 0) setSelectedCurrentHeat("Prev");
      if (newValue === 1) setSelectedCurrentHeat("Current");
      if (thereIsNext && newValue === 2) setSelectedCurrentHeat("Next");
    }
  };

  useEffect(() => {
    if (thereIsPrev && selectedHeat === "Prev") setButtonIndex(0);
    if (selectedHeat === "Current") setButtonIndex(1);
    if (thereIsNext && selectedHeat === "Next") setButtonIndex(2);
  }, []);

  useEffect(() => {
    if (aboutToUpdate === false) {
      setTimeout(() => {
        document
          .getElementById("live-heat-box")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [buttonIndex]);

  return (
    <LoadingBar $offset={(mode == "fullscreen" && 300) || undefined}>
      <AppBar
        className={aboutToUpdate ? "bar" : ""}
        color="transparent"
        sx={
          mode === "normal"
            ? {
                boxShadow: 0,
                width: "100%",
                height: "100%",
                mb: 1,
                position: "static",
              }
            : {
                boxShadow: 0,
                height: "58px",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 0,
              }
        }
      >
        <StyledTabs
          //@ts-ignore
          value={buttonIndex}
          onChange={handleTabListChange}
          centered
          sx={
            mode === "normal"
              ? {
                  width: "100%",
                  bgcolor: "transparent",
                  position: "absolute",
                  bottom: "3px",
                }
              : { width: "100%", bgcolor: "transparent" }
          }
        >
          {["Previous Heat", "Current Heat", "Next Heat"].map((list) => (
            //@ts-ignore
            <BlankTabs key={list} label={list} />
          ))}
        </StyledTabs>
      </AppBar>
    </LoadingBar>
  );
}

const LoadingBar = styled.div<{ $offset?: number | undefined }>`
    min-height: 2.4em;
    overflow: hidden;
    .bar::after{
        position: absolute;
        content: 'Loading next heat...';
        top:${(props) => (props.$offset ? "35%" : "25%")};
        left:${(props) => (props.$offset ? props.$offset : "5")}px;
        animation: slide-in-text 8s ease-in-out, pulse 1.5s 1s linear infinite;
        color: #374b5ca4;
    }

    .MuiTabs-indicatorSpan{
        background-color:black;
        opacity: 1;
        transition: opacity .3s ease-in-out;
    }

    button{
        opacity: 1;
        transition: opacity .3s ease-in-out;
    }

    .bar button, .bar .MuiTabs-indicatorSpan{
        opacity: .4;
    }

    .bar button{
        cursor: default; 
    }

    .bar .Mui-selected{
        color: #666;
    }

    .bar button:hover{
        color: #666;
    }

    .bar::before{
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        height: 3px;
        width: 100%;
        background: #374b5c/* linear-gradient(35deg, transparent 40%, #374b5c) */;
        animation: slide 8s ease-in-out;
    }  

    @keyframes pulse {
        0%{
            opacity: 1;
        }
        50%{
            opacity: 0.7;
        }
        100%{
            opacity: 1;
        }
    }

    @keyframes slide {
        0%{
            opacity: 1;
            transform: translateX(-100%)
        }
        98%{
            opacity:1;
        }
        100%{
            transform: translateX(0%);
            opacity:0;
        }
    }

    @keyframes slide-in-text {
        0%{
            opacity: 0;
            transform: translate(-40px);
        }
        10%{
            opacity: 1;
            transform: translate(0px);
        }
        92%{
            transform: translate(0px);
            opacity: 1;
        }
        100%{
            opacity: 1;
            transform: translate(0px,${(props) =>
              props.$offset ? "0px" : "40px"});
        }
    }
`;
