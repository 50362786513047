import { useContext, useEffect, useMemo, useState } from "react";

import useFetch from "./graphql/useFetch";
import { GET_COMPETITION_SUMMARY } from "../utils/graphql/queries";
import { COMPETITION_SUMMARY_FRAME_SUBSCRIPTION } from "../utils/graphql/subscriptions";
import { LiveContext } from "../context/LiveContext";
import useSubscribeIf from "./graphql/useSubscribeIf";
import query from "../components/utils/query";
import useConditionalSub from "./graphql/useConditionalSub";
import { SummaryHideCondition } from "../views/competition/event/components/summary/SummaryCard";
import { useLazyQuery, useQuery } from "@apollo/client";

/**
 * This hook is used for getting the frame of the Competition Summary, where the 'frame' is the SummaryType object but with only
 * the id and updated_at properties, and additionally the rank property to correctly sort the ranks.
 * @param timeProgramId
 * @param summaryTypes
 * @param eventLevel
 * @returns Object, ( summaryData, loading, error, sortedRanks, changeSummaryType, summaryTypeIndex, summaryTypeId)
 */

export default function useCompetitionSummary(
  eventView: View,
  isCompetitionActive: boolean,
  timeProgramId: number | undefined,
  summaryTypes: SummaryType[] | undefined,
  eventLevel: number | undefined,
  status: number | undefined,
  roundHeatSubData: Round | undefined
) {
  const [summaryTypeIndex, setSummaryTypeIndex] = useState<number>(0);
  const [summary_typeId, setSummary_typeId] = useState<number | undefined>(
    undefined
  );
  const [sortedRanks, setSortedRanks] = useState<Rank[]>([]);
  const { currentTpeId } = useContext(LiveContext);

  /* SET INITIAL SUMMARY_TYPE_ID */
  useEffect(() => {
    if (summaryTypes) {
      summaryTypes.length >= summaryTypeIndex + 1
        ? setSummary_typeId(summaryTypes[summaryTypeIndex]?.id)
        : setSummary_typeId(summaryTypes[0]?.id);

      summaryTypes.length >= summaryTypeIndex + 1 && setSummaryTypeIndex(0);
    }
  }, [summaryTypes]);

  const orderVariable =
    (eventLevel && eventLevel > 1) ||
    summaryTypes?.[summaryTypeIndex]?.sort_by_name
      ? { lane: { competitor: { full_name_reversed: "asc" } } }
      : { rank: "asc" };

  const {
    data: subSummaryData,
    endSub: unsub,
    restartSub,
  } = useConditionalSub(
    COMPETITION_SUMMARY_FRAME_SUBSCRIPTION,
    { id: summary_typeId, order_by: orderVariable },
    isCompetitionActive &&
      timeProgramId === currentTpeId &&
      status !== 5 &&
      eventView === "summary"
  );

  const [fetchSummaryData, { data: summaryData, loading, refetch }] =
    useLazyQuery(GET_COMPETITION_SUMMARY, {
      variables: {
        id: summary_typeId,
        order_by: orderVariable,
      },
    });

  useEffect(() => {
    summary_typeId && fetchSummaryData();
  }, [summary_typeId]);

  /*  const {
    data: summaryData,
    loading,
    refresh,
  } = useFetch<SummaryType>(
    GET_COMPETITION_SUMMARY,
    { id: summary_typeId, order_by: orderVariable },
    "id"
  ); */

  useEffect(() => {
    if (eventView === "summary") restartSub();
    summary_typeId && refetch();
    return () => unsub();
  }, []);

  useEffect(() => {
    if (timeProgramId !== currentTpeId) {
      unsub();
    }
  }, [timeProgramId, currentTpeId]);

  useEffect(() => {
    if (
      summary_typeId &&
      summaryTypes &&
      summaryTypes.length >= summaryTypeIndex + 1
    ) {
      /* refetch(); */
      // removed because it caused a double fetch of summary data when switching between events/tpe's -> got the previous events/tpe's data
    } else {
      setSummaryTypeIndex(0);
      summary_typeId && refetch();
    }
  }, [timeProgramId, summaryTypes]);

  useMemo(() => {
    if (summaryData) {
      if (summaryData?.round?.status === 5) {
        unsub();
      }
      const filteredRanks = summaryData?.summary_type_by_pk?.ranks?.filter(
        (rank: Rank) => rank?.lane?.competitor !== null
      );
      const rankedRanks = filteredRanks?.filter(
        (rank: Rank) => rank.rank && rank?.rank > 0
      );
      const unrankedRanks = filteredRanks?.filter(
        (rank: Rank) => rank?.rank === 0
      );
      rankedRanks &&
        unrankedRanks &&
        setSortedRanks(rankedRanks?.concat(unrankedRanks));
    }
  }, [summaryData]);

  useMemo(() => {
    summary_typeId && refetch();
  }, [roundHeatSubData, subSummaryData, summaryTypeIndex]);

  const changeSummaryType = (newValue: number) => {
    setSummaryTypeIndex(newValue);
    summaryTypes && setSummary_typeId(summaryTypes[newValue]?.id);
  };

  const summaryHideCondition: SummaryHideCondition = {
    name: summaryData?.name,
    hide_q: summaryData?.hide_q,
    sort_by_name: summaryTypes?.[summaryTypeIndex]?.sort_by_name,
  };

  return {
    summaryData,
    sortedRanks,
    changeSummaryType,
    summaryTypeIndex,
    summaryTypeId: summaryTypes && summaryTypes[0]?.id,
    error: undefined,
    loading,
    summaryHideCondition,
  };
}
