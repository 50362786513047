import React from 'react';

import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan"
            />
        }}
    />
))(
    ({ theme }) => ({
        minHeight: 0,
        '& .MuiTabs-indicator': {
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                bottom: 4,
            },
            '& .MuiTabs-indicatorSpan': {
                maxWidth: 50,
                [theme.breakpoints.up('sm')]: {
                    maxWidth: 60,
                },
                width: '100%',
                backgroundColor: '#fff',
            },
    })
);

export const StyledMainTab = styled((props) => (
    <Tab
        {...props}
        sx={{
            //color: props.color ?? '#000',
            '&:hover': {
                color: props.hovercolor ?? '#FFF',
                opacity: 1,
                '& .super-live-logo': {
                    fill: props.hovercolor ?? '#FFF'
                }
            },
            '&.Mui-selected': {
                color: props.selectedcolor ?? '#fff',
            },
            '&.Mui-focusVisible': {
                backgroundColor: props.focuscolor ?? '#D0D5DD',
            }, 
        }}
    />
))(
    ({ theme }) => ({
        textTransform: 'none',
        /* fontWeight: theme.typography.fontWeightRegular, */
        /* fontSize: theme.typography.pxToRem(15), */
        /* marginRight: theme.spacing(1), */
        minWidth: 0,
        /* minHeight: 0, */
        /* height: '8rem', */
        [theme.breakpoints.up('md')]: {
            width: 180,
        },
    }),
);

export const StyledSmallTab = styled((props) => (
    //console.log(props.selectedcolor),
    <Tab
        {...props}
        sx={{
            //color: props.color ?? '#000',
            '&:hover': {
                color: props.hovercolor ?? '#FFF',
                opacity: 1,
            },
            '&.Mui-selected': {
                color: props.selectedcolor ?? '#ffffff',
            },
            '&.Mui-focusVisible': {
                backgroundColor: props.focuscolor ?? '#D0D5DD',
            }, 
        }}
    />
))(
    ({ theme }) => ({
        textTransform: 'none',
        /* fontWeight: theme.typography.fontWeightRegular, */
        /* fontSize: theme.typography.pxToRem(15), */
        /* marginRight: theme.spacing(1), */
        minWidth: 0,
        minHeight: 0,
        height: '2rem',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
    }),
);