import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Box, Typography, Divider } from "@mui/material";

import { TimeProgramEntryIcon } from "../../../../components/layout";
import {
  getStartTime,
  statusGradientColor,
} from "../../../../components/utils";
import LiveBadge from "../../../../components/layout/LiveBadge";
import { LiveContext } from "../../../../context/LiveContext";

export default function TimeProgramEntries({
  session,
  entries,
  selectedTpe,
  setSelectedTpe,
  setEventId,
  time_programId,
  setSummaryTypes,
  setNewTpe,
  setRefreshTpeView
}) {
  const { currentTpeId } = useContext(LiveContext);

  const params = useParams();
  const navigate = useNavigate();

  const sponsorText = "";

  const scrollPosition = window.innerWidth < 1000 ? 420 : 590;

  return (
    <>
      {entries?.map((entry, index) => (
        <Link
          key={entry?.id}
          display="flex"
          width="100%"
          py={0.5}
          to={
            entry?.id === currentTpeId && entry?.round?.status !== 5
              ? `../competitions/${params?.competitionName}/events/${params?.eventView}/${session?.oid}/${entry?.oid}/live`
              : `../competitions/${params?.competitionName}/events/${params?.eventView}/${session?.oid}/${entry?.oid}`
          }
          onClick={() => {
            if(window.scrollY > 50) { // quick fix to show from rank 1 if changing event after scrolled down on previous event to low rankings
              window.scrollTo(0, scrollPosition)
            }
            selectedTpe.id !== entry.id && setSelectedTpe(entry);
            selectedTpe.id === entry.id ? setRefreshTpeView(true) : setNewTpe(true)
            if (entry?.type === 1) {
              setSummaryTypes(entry?.round?.summary_types);
              setEventId(entry?.round?.event?.id ?? null);
            }
          }}
          style={{
            cursor: "pointer",
            borderStyle:
              index === entries?.length - 1 ? "none" : "none none dashed none",
            borderTopStyle: index === 0 && "solid",
            borderWidth: 1,
            borderColor: "#D0D5DD",
            backgroundColor: entry?.id === time_programId ? "#FFF" : "#EBEEF2",
            display: "flex",
            justifyContent: "space-between",
            textDecoration: "none",
            color: "black",
            paddingBlock: "5px",
          }}
        >
          <Box
            display="flex"
            gap={1}
            sx={{
              width:
                entry?.id === currentTpeId && entry?.round?.status !== 5
                  ? "calc(100% - 64px)"
                  : "100%",
              bgcolor: "",
            }}
          >
            <Box
              width="48px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ bgcolor: "" }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="32px"
                width="32px"
                ml={1}
                sx={{
                  borderRadius: 2,
                  background: statusGradientColor(
                    entry?.id,
                    currentTpeId,
                    entry?.round?.status,
                    entry?.start_time
                  ),
                }}
              >
                <Typography
                  color="#FFF"
                  fontSize="1rem"
                  lineHeight={1}
                  sx={{ mb: -0.25 }}
                >
                  <TimeProgramEntryIcon entry={entry} />
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my="auto"
              py={1}
              sx={{ bgcolor: "" }}
            >
              <Typography
                my="auto"
                fontWeight={500}
                fontSize="1rem"
                mb={-0.25}
                sx={{ bgcolor: "", width: "auto" }}
              >
                {getStartTime(entry?.start_time)}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={0.25}
              sx={{ bgcolor: "" /*  width: "calc(100% - 128px)"  */ }}
            >
              <Typography
                fontWeight={500}
                mb="-1px"
                fontSize="0.8rem"
                noWrap
                sx={{ bgcolor: "" }}
              >
                {entry?.name}
              </Typography>
              {sponsorText && (
                <Typography fontSize="0.75rem" my="auto" lineHeight={1} sx={{}}>
                  {sponsorText}
                </Typography>
              )}
            </Box>
          </Box>
          {entry?.id === currentTpeId && entry?.round?.status !== 5 && (
            <LiveBadge entry={entry} session={session} params={params} />
          )}
        </Link>
      ))}
    </>
  );
}
