import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Box,
    Divider,
    Typography,
} from '@mui/material';

import { BsPeopleFill } from 'react-icons/bs';

export default function AllClubsButton({ club, setClub, setCompetitors, setSelectedCompetitor, setView, clearSearchInput }) {

    const params = useParams();
    const navigate = useNavigate();

    return (
        <Box
            display='flex'
            my={0}
            py={0.5}
            px={0}
            mr={0}
            onClick={() => {
                navigate(`../competitions/${params.competitionName}/swimmers/clubs`);
                setCompetitors(null);
                setClub(null);
                setSelectedCompetitor(null);
                clearSearchInput();
                setView(0);
            }}
            sx={{
                borderStyle: 'none none solid none',
                borderWidth: 1,
                borderColor: '#D0D5DD',
                bgcolor: club === null ? '#FFF' : '#EBEEF2',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
            }}
        >
            <Box display='flex' width='100%' mx={0} gap={1} sx={{ bgcolor: '' }}>
                <Box width='48px' display='flex' justifyContent='center' alignItems='center' sx={{ bgcolor: '' }}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='32px'
                        width='32px'
                        ml={1}
                        sx={{ bgcolor: '#374B5C', borderRadius: 2, }}>
                        <BsPeopleFill color='#FFF' />
                    </Box>
                </Box>
                <Divider orientation='vertical' variant='middle' flexItem />
                <Box display='flex' flexDirection='column' justifyContent='center' gap={0.25} sx={{ bgcolor: '', width: 'calc(100% - 124px)' }}>
                    <Typography fontWeight={500} fontSize='0.9rem' noWrap sx={{ bgcolor: '' }}>
                        All Clubs
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
